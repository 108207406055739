import { Record as ImmutableRecord } from 'immutable';

import { removeEmptyValueParams } from 'helpers/utils';

export interface YahooPlaceSNSLinkJSON {
  facebookUrl?: string;
  instagramAccount?: string;
  tiktokAccount?: string;
  twitterAccount?: string;
  lineOfficialAccount?: string;
  youtubeUrl?: string;
}

export const getAccountFromUrl = (url: string, pattern: RegExp) => {
  const match = url.match(pattern);
  return match && match[1] ? match[1] : '';
};

export const getUrlFromAccount = (account: string, prefix: string) => {
  return account ? `${prefix}${account}` : '';
};

export const INSTAGRAM_URL_PATTERN = /https:\/\/(?:www\.)?instagram\.com\/([^/]+)/;
export const TIKTOK_URL_PATTERN = /https:\/\/(?:www\.)?tiktok\.com\/@(.+)/;
export const TWITTER_URL_PATTERN = /https:\/\/(?:www\.)?(?:twitter|x)\.com\/(.+)/;
export const LINE_URL_PATTERN = /https:\/\/line\.me\/R\/ti\/p\/@(.+)/;

export const INSTAGRAM_URL_PREFIX = 'https://www.instagram.com/';
export const TIKTOK_URL_PREFIX = 'https://www.tiktok.com/@';
export const TWITTER_URL_PREFIX = 'https://www.twitter.com/';
export const LINE_URL_PREFIX = 'https://line.me/R/ti/p/@';

export class YahooPlaceSNSLink extends ImmutableRecord<{
  facebook: string;
  instagram: string;
  tiktok: string;
  twitter: string;
  line: string;
  youtube: string;
}>({
  facebook: '',
  instagram: '',
  tiktok: '',
  twitter: '',
  line: '',
  youtube: '',
}) {
  static fromJSON(data: YahooPlaceSNSLinkJSON) {
    return new YahooPlaceSNSLink({
      facebook: data.facebookUrl ?? '',

      instagram: getUrlFromAccount(data.instagramAccount ?? '', INSTAGRAM_URL_PREFIX),
      tiktok: getUrlFromAccount(data.tiktokAccount ?? '', TIKTOK_URL_PREFIX),
      twitter: getUrlFromAccount(data.twitterAccount ?? '', TWITTER_URL_PREFIX),
      line: getUrlFromAccount(data.lineOfficialAccount ?? '', LINE_URL_PREFIX),
      youtube: data.youtubeUrl ?? '',
    });
  }

  /**
   * InstagramのURLからアカウント名を取得
   */
  get instagramAccount() {
    return getAccountFromUrl(this.instagram, INSTAGRAM_URL_PATTERN);
  }

  /**
   * TikTokのURLからアカウント名を取得
   */
  get tiktokAccount() {
    return getAccountFromUrl(this.tiktok, TIKTOK_URL_PATTERN);
  }

  /**
   * TwitterのURLからアカウント名を取得
   */
  get twitterAccount() {
    return getAccountFromUrl(this.twitter, TWITTER_URL_PATTERN);
  }

  /**
   * LineのURLからアカウント名を取得
   */
  get lineOfficialAccount() {
    return getAccountFromUrl(this.line, LINE_URL_PATTERN);
  }

  updateParams() {
    return removeEmptyValueParams<YahooPlaceSNSLinkJSON>({
      facebookUrl: this.facebook,
      instagramAccount: this.instagramAccount,
      tiktokAccount: this.tiktokAccount,
      twitterAccount: this.twitterAccount,
      lineOfficialAccount: this.lineOfficialAccount,
      youtubeUrl: this.youtube,
    });
  }
}
