import { Record as ImmutableRecord } from 'immutable';

import { removeEmptyValueParams } from 'helpers/utils';

export type YahooPlacePaymentRecord = {
  // 現金
  cashOnly?: boolean;
  // クレジットカード
  isVisaAvailable?: boolean;
  isMastercardAvailable?: boolean;
  isJcbAvailable?: boolean;
  isAmericanExpressAvailable?: boolean;
  isDinersClubAvailable?: boolean;
  isGinrenAvailable?: boolean;
  // QRコード決済
  isPayPayAvailable?: boolean;
  isLinePayAvailable?: boolean;
  isMerPayAvailable?: boolean;
  isRakutenPayAvailable?: boolean;
  isAuPayAvailable?: boolean;
  isDPayAvailable?: boolean;
  isAliPayAvailable?: boolean;
  isWeChatPayAvailable?: boolean;
  // 電子マネー
  isIdAvailable?: boolean;
  isQuicPayAvailable?: boolean;
  isRakutenEdyAvailable?: boolean;
  isSuicaAvailable?: boolean;
  isIcocaAvailable?: boolean;
  isToicaAvailable?: boolean;
  isPasmoAvailable?: boolean;
  isSugocaAvailable?: boolean;
  isKitacaAvailable?: boolean;
  isPitapaAvailable?: boolean;
  isManacaAvailable?: boolean;
  isNimocaAvailable?: boolean;
  isHayakakenAvailable?: boolean;
  isNanacoAvailable?: boolean;
  isWaonAvailable?: boolean;
};

export type YahooPlacePaymentJSON = YahooPlacePaymentRecord;

export type YahooPlacePaymentKey = keyof YahooPlacePaymentRecord;

const creditCardKeys = [
  'isVisaAvailable',
  'isMastercardAvailable',
  'isJcbAvailable',
  'isAmericanExpressAvailable',
  'isDinersClubAvailable',
  'isGinrenAvailable',
] as const satisfies YahooPlacePaymentKey[];

const qrCodePaymentKeys = [
  'isPayPayAvailable',
  'isLinePayAvailable',
  'isMerPayAvailable',
  'isRakutenPayAvailable',
  'isAuPayAvailable',
  'isDPayAvailable',
  'isAliPayAvailable',
  'isWeChatPayAvailable',
] as const satisfies YahooPlacePaymentKey[];

const eMoneyKeys = [
  'isIdAvailable',
  'isQuicPayAvailable',
  'isRakutenEdyAvailable',
  'isSuicaAvailable',
  'isIcocaAvailable',
  'isToicaAvailable',
  'isPasmoAvailable',
  'isSugocaAvailable',
  'isKitacaAvailable',
  'isPitapaAvailable',
  'isManacaAvailable',
  'isNimocaAvailable',
  'isHayakakenAvailable',
  'isNanacoAvailable',
  'isWaonAvailable',
] as const satisfies YahooPlacePaymentKey[];

const cashKeys = ['cashOnly'] as const satisfies YahooPlacePaymentKey[];

export const yahooPlacePaymentKeys = {
  cash: cashKeys,
  creditCard: creditCardKeys,
  qrCodePayment: qrCodePaymentKeys,
  eMoney: eMoneyKeys,
};

export const getPaymentKeyLabel = (key: YahooPlacePaymentKey) => {
  switch (key) {
    case 'cashOnly':
      return '現金のみ';
    case 'isVisaAvailable':
      return 'VISA';
    case 'isMastercardAvailable':
      return 'MasterCard';
    case 'isJcbAvailable':
      return 'JCB';
    case 'isAmericanExpressAvailable':
      return 'American Express';
    case 'isDinersClubAvailable':
      return 'Diners Club';
    case 'isGinrenAvailable':
      return '銀聯';
    case 'isPayPayAvailable':
      return 'PayPay';
    case 'isLinePayAvailable':
      return 'LINE Pay';
    case 'isMerPayAvailable':
      return 'メルペイ';
    case 'isRakutenPayAvailable':
      return '楽天ペイ';
    case 'isAuPayAvailable':
      return 'au PAY';
    case 'isDPayAvailable':
      return 'd払い';
    case 'isAliPayAvailable':
      return 'Alipay';
    case 'isWeChatPayAvailable':
      return 'WeChat Pay';
    case 'isIdAvailable':
      return 'iD';
    case 'isQuicPayAvailable':
      return 'QUICPay';
    case 'isRakutenEdyAvailable':
      return '楽天Edy';
    case 'isSuicaAvailable':
      return 'Suica';
    case 'isIcocaAvailable':
      return 'ICOCA';
    case 'isToicaAvailable':
      return 'TOICA';
    case 'isPasmoAvailable':
      return 'PASMO';
    case 'isSugocaAvailable':
      return 'SUGOCA';
    case 'isKitacaAvailable':
      return 'KITACA';
    case 'isPitapaAvailable':
      return 'PiTaPa';
    case 'isManacaAvailable':
      return 'manaca';
    case 'isNimocaAvailable':
      return 'nimoca';
    case 'isHayakakenAvailable':
      return 'はやかけん';
    case 'isNanacoAvailable':
      return 'nanaco';
    case 'isWaonAvailable':
      return 'WAON';
    default:
      return key satisfies never;
  }
};

export class YahooPlacePayment extends ImmutableRecord<YahooPlacePaymentRecord>({
  cashOnly: undefined,
  isVisaAvailable: undefined,
  isMastercardAvailable: undefined,
  isJcbAvailable: undefined,
  isAmericanExpressAvailable: undefined,
  isDinersClubAvailable: undefined,
  isGinrenAvailable: undefined,
  isPayPayAvailable: undefined,
  isLinePayAvailable: undefined,
  isMerPayAvailable: undefined,
  isRakutenPayAvailable: undefined,
  isAuPayAvailable: undefined,
  isDPayAvailable: undefined,
  isAliPayAvailable: undefined,
  isWeChatPayAvailable: undefined,
  isIdAvailable: undefined,
  isQuicPayAvailable: undefined,
  isRakutenEdyAvailable: undefined,
  isSuicaAvailable: undefined,
  isIcocaAvailable: undefined,
  isToicaAvailable: undefined,
  isPasmoAvailable: undefined,
  isSugocaAvailable: undefined,
  isKitacaAvailable: undefined,
  isPitapaAvailable: undefined,
  isManacaAvailable: undefined,
  isNimocaAvailable: undefined,
  isHayakakenAvailable: undefined,
  isNanacoAvailable: undefined,
  isWaonAvailable: undefined,
}) {
  static fromJSON(data: YahooPlacePaymentJSON) {
    return new YahooPlacePayment(data);
  }

  /**
   * 現金のみに変更する
   */
  changeToCashOnly() {
    // 他の項目をすべて削除→現金のみtrueにして新規作成
    return new YahooPlacePayment({ cashOnly: true });
  }

  /**
   * 指定したキーの値を変更する
   * @param key
   * @param value
   */
  changeValue(key: YahooPlacePaymentKey, value: boolean | undefined) {
    return this.set(key, value);
  }

  get isExistsCreditCardValue() {
    return creditCardKeys.some((key) => this.get(key) != null);
  }

  get isExistsEMoneyValue() {
    return eMoneyKeys.some((key) => this.get(key) != null);
  }

  get isExistsQrCodePaymentValue() {
    return qrCodePaymentKeys.some((key) => this.get(key) != null);
  }

  get isExistsCashValue() {
    return cashKeys.some((key) => this.get(key) != null);
  }

  get hasValue() {
    return (
      this.isExistsCreditCardValue ||
      this.isExistsEMoneyValue ||
      this.isExistsQrCodePaymentValue ||
      this.isExistsCashValue
    );
  }

  isValid() {
    // 現金のみが選択されている場合は他の項目が選択されていないこと
    if (this.cashOnly) {
      return !this.isExistsCreditCardValue && !this.isExistsEMoneyValue && !this.isExistsQrCodePaymentValue;
    }
    return true;
  }

  updateParams(): YahooPlacePaymentJSON {
    // 現金のみが選択されている場合は他の項目を含めない
    if (this.cashOnly) {
      return { cashOnly: this.cashOnly };
    }
    // 値のない項目は含めない
    return removeEmptyValueParams<YahooPlacePaymentJSON>(this.toJSON());
  }
}
