import { List, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import YahooPlaceAccount from 'models/Domain/YahooPlace/Account';
import YahooPlaceAuth from 'models/Domain/YahooPlace/Auth';
import { YahooPlaceCategories } from 'models/Domain/YahooPlace/Category';
import YahooPlaceSummary from 'models/Domain/YahooPlace/Place';
import { JSObject } from 'types/Common';

import { YahooPlaceActions } from './actions';

export interface StateRecord {
  accounts: List<YahooPlaceAccount>;
  registerYahooPlaceAccount: YahooPlaceAccount;
  yahooPlaceAuth: YahooPlaceAuth;
  placeSummaries: List<YahooPlaceSummary>;
  categories: YahooPlaceCategories;
}

export class YahooPlaceState extends Record<StateRecord>({
  accounts: List(),
  registerYahooPlaceAccount: new YahooPlaceAccount(),
  yahooPlaceAuth: new YahooPlaceAuth(),
  placeSummaries: List(),
  categories: new YahooPlaceCategories(),
}) {}

export const yahooPlaceReducer = reducerWithInitialState(new YahooPlaceState())
  .case(YahooPlaceActions.setAccounts, (state, data) => {
    const accounts: List<YahooPlaceAccount> = List(data.accounts.map((d: JSObject) => new YahooPlaceAccount(d)));
    return state.set('accounts', accounts);
  })
  .case(YahooPlaceActions.setPlaces, (state, data) => {
    const yahooPlaceSummaries: List<YahooPlaceSummary> = List(
      data.data.map((d: JSObject) => YahooPlaceSummary.fromJson(d)),
    );
    return state.set('placeSummaries', yahooPlaceSummaries);
  })
  .case(YahooPlaceActions.changeRegisterYahooPlaceAccountName, (state, account_name) => {
    const registerYahooPlaceAccount: YahooPlaceAccount = state.registerYahooPlaceAccount.set('name', account_name);
    return state.set('registerYahooPlaceAccount', registerYahooPlaceAccount);
  })
  .case(YahooPlaceActions.clearRegisterYahooPlaceAccount, (state) => {
    return state.set('registerYahooPlaceAccount', new YahooPlaceAccount());
  })
  .case(YahooPlaceActions.setYahooPlaceAuth, (state, yahooAuth) => {
    const yahooPlaceAuth: YahooPlaceAuth = new YahooPlaceAuth(yahooAuth);
    return state.set('yahooPlaceAuth', yahooPlaceAuth);
  })
  .case(YahooPlaceActions.setCategories, (state, categories) => {
    return state.set('categories', categories);
  })
  .case(YahooPlaceActions.clearYahooPlaceAuth, (state) => {
    return state.set('yahooPlaceAuth', new YahooPlaceAuth());
  });
