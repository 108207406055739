import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { FormContent } from 'components/atoms/FormContent';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { useDisplayType } from 'hooks/useDisplayType';
import { TranslationLanguage } from 'models/Domain/Organization';
import Promotion, {
  BODY_MAX_LENGTH,
  PromotionActionType,
  PromotionTopicType,
  TITLE_MAX_LENGTH,
} from 'models/Domain/Promotion/Promotion';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { User } from 'models/Domain/User';
import { PromotionActions } from 'modules/promotion/actions';
import { State } from 'modules/reducers';
import { SIZE } from 'style/size';

import { PromotionFormDateRangePicker } from './Form/PromotionFormDateRangePicker';
import { PromotionFormImageUpload } from './Form/PromotionFormImageUpload';
import { PromotionFormErrorMessages, PromotionFormWarningMessages } from './Form/PromotionFormMessages';
import { PromotionFormOffer } from './Form/PromotionFormOffer';
import { PromotionFormPostSettings } from './Form/PromotionFormPostSettings';
import { PromotionFormSelectStores } from './Form/PromotionFormSelectStores';
import { PromotionFormStores } from './Form/PromotionFormStores';
import { PromotionFormTranslation } from './Form/PromotionFormTranslation';
import { PromotionGenerateModal } from './PromotionGenerateModal';

const guide_url = {
  STANDARD: 'https://pathee.notion.site/4d867b5a7ec64e4390f8e9849899603f?pvs=25',
  ALERT: 'https://pathee.notion.site/4d867b5a7ec64e4390f8e9849899603f?pvs=25',
  OFFER: 'https://pathee.notion.site/7f3c23b3d0da426d8342c9ea12734bfe?pvs=25',
  EVENT: 'https://pathee.notion.site/e261cb7b531e4079a37ca3a91b05c734?pvs=25',
} satisfies Record<Promotion['topic_type'], string>;

type PromotionEditFormBaseProps = {
  promotion: Promotion;
  setPromotion: (promotion: Promotion) => void;
  stores: Stores;
  currentUser: User;
  showErrorMessage: boolean;
  setShowErrorMessage: (value: boolean) => void;
  loadingLanguages: ImmutableSet<TranslationLanguage>;
  translatePost: (language: TranslationLanguage) => void;
  promotionGroup: PromotionGroup;
  onChangePromotionGroup: (promotionGroup: PromotionGroup) => void;
};

// formType='create'が指定された場合は、storeLists、setSelectedStoreList、onCreateを必須に
export type PromotionCreateFormProps = PromotionEditFormBaseProps & {
  formType: 'create';
  storeLists: StoreLists;
  setSelectedStoreList: (v: number[]) => void;
  onCreate: () => void;
  onChangeTopicType: (topicType: PromotionTopicType) => void;
  onSaveDraft: () => void;
};

// formType='update'が指定された場合は、onUpdateを必須に
export type PromotionUpdateFormProps = PromotionEditFormBaseProps & {
  formType: 'update';
  storeLists: StoreLists;
  setSelectedStoreList: (v: number[]) => void;
  onUpdate: () => void;
  onSaveDraft: () => void;
};

export type PromotionEditFormProps = PromotionCreateFormProps | PromotionUpdateFormProps;

export const topicTypeOptions: { text: string; value: string }[] = [
  { text: '最新情報', value: 'STANDARD' },
  { text: '特典', value: 'OFFER' },
  { text: 'イベント', value: 'EVENT' },
];

export const selectButtonOptions: { text: string; value: string }[] = Object.entries(PromotionActionType).map(
  ([k, v]) => ({
    text: v,
    value: k,
  }),
);

// 本文の入力欄のラベル
export const BODY_LABELS: { [key in PromotionTopicType]: string } = {
  STANDARD: '本文を入力',
  ALERT: 'ステータスを更新',
  OFFER: '特典の詳細',
  EVENT: 'イベントの詳細',
};

// 本文入力欄のプレースホルダテキスト
const BODY_PLACEHOLDER_TEXTS: { [key in PromotionTopicType]: string } = {
  STANDARD: '投稿したい情報を入力してください',
  ALERT: 'COVID-19に関する情報を入力してください',
  OFFER: '特典の内容を入力してください',
  EVENT: 'イベントの内容を入力してください',
};

// タイトルの入力欄のラベル
export const TITLE_LABELS: { [key in PromotionTopicType]: string } = {
  STANDARD: '', // 項目なし
  ALERT: '', // 項目なし
  OFFER: '特典のタイトル',
  EVENT: 'イベントのタイトル',
};

// タイトル入力欄のプレースホルダテキスト
const TITLE_PLACEHOLDER_TEXTS: { [key in PromotionTopicType]: string } = {
  STANDARD: '', // 項目なし
  ALERT: '', // 項目なし
  OFFER: '特典のタイトルを入力してください',
  EVENT: 'イベントのタイトルを入力してください',
};

export const PromotionEditForm: React.FC<PromotionEditFormProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    formType,
    promotion,
    setPromotion,
    stores,
    currentUser,
    showErrorMessage,
    setShowErrorMessage,
    onSaveDraft,
    promotionGroup,
    onChangePromotionGroup,
  } = props;
  // 本当は以下でいいはずだけど、ビルドがうまくいかないのでany使って誤魔化している
  // const onCreate = formType === 'create' ? props.onCreate : props.onUpdate;
  const onCreate: () => void = formType === 'create' ? (props as any).onCreate : (props as any).onUpdate;
  const setSelectedStoreList: (v: number[]) => void | undefined = props.setSelectedStoreList;
  const onChangeTopicType: (topicType: PromotionTopicType) => void | undefined =
    formType === 'create' ? (props as any).onChangeTopicType : undefined;

  const dispatch = useDispatch();

  const { uploadFile, deleteMediaForEdit } = useMemo(() => bindActionCreators(PromotionActions, dispatch), [dispatch]);

  const filteredStores = stores.filterByUserRole(currentUser);

  const mediaForEdit = useSelector((state: State) => state.promotion.mediaForEdit);

  const onDeleteImage = useCallback(
    (index: number) => {
      // imageFileが存在する場合は検証用の画像情報も削除する
      if (promotion.imageFile) {
        setPromotion(promotion.changeImageFile(null));
      }
      deleteMediaForEdit(index);
    },
    [deleteMediaForEdit, promotion, setPromotion],
  );

  const onChangeImage = useCallback(
    (imageInfo: ImageFileMeta) => {
      setPromotion(promotion.changeImageFile(imageInfo));
    },
    [promotion, setPromotion],
  );

  // エイリアスに関する警告の取得
  const selectedStores = stores.filterStoresById(promotion.getStoreIds());
  const warnings = promotion.getWarningMessages(selectedStores.list);
  const errors = promotion.getErrorMessages(selectedStores.list);

  // 利用可能なエイリアス
  const bodyAvailableAliases = Promotion.getAvailableAliasTypes('body', selectedStores.list);
  const urlAvailableAliases = Promotion.getAvailableAliasTypes('url', selectedStores.list);

  // コンテンツポリシーに反する可能性のあるワードを抽出
  const contentsWarnings = promotion.getContentsPolicyWarningMessages();

  const handleOnSaveDraft = useCallback(() => {
    // 下書きフラグをtrueにする
    setPromotion(promotion.changeIsDraft(true));
    onSaveDraft();
  }, [promotion, setPromotion, onSaveDraft]);

  const handleOnClickConfirmAndCreate = useCallback(() => {
    if (
      errors.url.length > 0 ||
      !promotionGroup.isValid() ||
      promotionGroup.children.some((childPost) => childPost.getErrorMessages(selectedStores.list).url.length > 0)
    ) {
      window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
      setShowErrorMessage(true);
      return;
    }
    if (
      warnings.body.length > 0 ||
      warnings.url.length > 0 ||
      contentsWarnings.length > 0 ||
      promotionGroup.children.some((childPost) => childPost.getContentsPolicyWarningMessages().length > 0)
    ) {
      if (!window.confirm('警告のある項目がありますが、よろしいですか？')) {
        return;
      }
    }

    onCreate();
  }, [
    errors.url.length,
    warnings.body.length,
    warnings.url.length,
    contentsWarnings.length,
    onCreate,
    selectedStores.list,
    setShowErrorMessage,
    promotionGroup,
  ]);

  // 投稿できない店舗
  const canNotPostGmbPromotionStores = promotion.getCanNotPostGmbPromotionStores(stores);

  // 投稿種別を変更可能か
  const canEditTopicType = formType === 'create';

  // 店舗を変更可能か
  const canEditStores = formType === 'create' || promotion.isDraft || promotion.isScheduled;

  // 翻訳言語を追加可能か
  const canAddTranslationLanguage = formType === 'create' || promotion.isDraft || promotion.isScheduled;

  // 下書き保存可能か
  const canSaveDraft = formType === 'create' || promotion.isDraft;

  // 確認・公開ボタンの非活性
  const disabled =
    showErrorMessage &&
    (canNotPostGmbPromotionStores.size === promotion.stores.size ||
      errors.url.length > 0 ||
      !props.promotionGroup.isValid());

  const isMobile = useDisplayType('800px').isMobile;

  const handleOnApplyGeneratedPromotion = useCallback(
    (body: string, url?: string | null) => {
      let newPromotion = promotion.changeBody(body);
      // URLがある場合は、詳細ボタンのリンクとして設定する
      if (url) {
        newPromotion = newPromotion.changeCallToActionActionType('LEARN_MORE').changeCallToActionUrl(url);
      }
      setPromotion(newPromotion);
    },
    [promotion, setPromotion],
  );

  const showAIGenerateButton = currentUser.canUseLocalPostAi;
  // 翻訳は組織設定で有効かつ対応言語が１つ以上設定されている場合のみ使える
  const translationLanguages = currentUser.organization?.params.localpost_translation_languages || [];
  const canUseTranslate = currentUser.canUseLocalPostTranslation;

  const isDraftDisabled = props.loadingLanguages.size > 0 || promotion.validateImage().name != null;

  return (
    <Wrapper>
      <FormWrapper>
        <LinkWrapper>
          <ExternalLink href={guide_url[promotion.topic_type]}>STORECAST利用ガイド</ExternalLink>
        </LinkWrapper>
        {isMobile && canEditTopicType && (
          <FormContent name='投稿種別'>
            <CustomPullDown
              value={promotion.topic_type}
              options={topicTypeOptions}
              onChange={onChangeTopicType}
              clearable={false}
            />
          </FormContent>
        )}
        {canEditStores ? (
          <FormContent name='投稿先店舗の選択' required>
            <PromotionFormSelectStores
              key={promotion.topic_type}
              stores={filteredStores}
              canNotPostGmbPromotionStores={canNotPostGmbPromotionStores}
              selectedStoreIds={promotion.stores.map((store) => store.store_id)}
              setSelectedStoreIds={setSelectedStoreList}
              error={showErrorMessage && promotion.validateStore().name}
            />
          </FormContent>
        ) : (
          <FormContent name='投稿先店舗（変更できません）'>
            <PromotionFormStores storeIds={promotion.stores.map((s) => s.store_id)} stores={stores} />
          </FormContent>
        )}
        {promotion.topic_type === 'STANDARD' && (
          <FormContent name={'画像 / 本文'} required>
            <Message>最新情報には、「画像」または「本文」のいずれかの入力が必須です</Message>
          </FormContent>
        )}
        {promotion.canUseImage && (
          <FormContent name={'画像を追加'}>
            <PromotionFormImageUpload
              imageUrls={mediaForEdit.sourceUrls}
              error={promotion.validateImage().name}
              onChangeImage={onChangeImage}
              onDeleteImage={onDeleteImage}
              uploadImage={uploadFile}
            />
          </FormContent>
        )}
        {promotion.canUseEvent && (
          <FormContent name={TITLE_LABELS[promotion.topic_type]} required>
            <StyledInput
              value={promotion.event?.title ?? ''}
              onChange={(value) => setPromotion(promotion.changeEventTitle(value))}
              placeholder={TITLE_PLACEHOLDER_TEXTS[promotion.topic_type]}
              error={showErrorMessage && promotion.event?.validateTitle().name}
            />
            <StyledTextCount size={promotion.event?.title.length ?? 0} maxSize={TITLE_MAX_LENGTH} />
          </FormContent>
        )}
        <FormContent
          name={BODY_LABELS[promotion.topic_type]}
          informationText={
            <>
              {`${bodyAvailableAliases.join('、')} と記述すると、公開時に自動でそれぞれの値が入力されます。`}
              <br />
              <br />
              投稿が非公開となる可能性のある内容（電話番号や表現）について警告を表示します。
              ただし、全てを網羅していないため警告が表示されない場合があります。また、警告が表示されても投稿が公開できる場合もあります。
            </>
          }
          required={promotion.topic_type === 'ALERT'}
          additionalContents={
            showAIGenerateButton ? (
              <GenerateButton onClick={() => setIsModalOpen(true)}>
                <StyledIcon type={'robot'} />
                AIアシスト
              </GenerateButton>
            ) : null
          }
        >
          <StyledTextArea
            value={promotion.body}
            onChange={(value) => setPromotion(promotion.changeBody(value))}
            placeholder={BODY_PLACEHOLDER_TEXTS[promotion.topic_type]}
            error={showErrorMessage && promotion.validateBody().name}
          />
          <StyledTextCount size={promotion.body.length ?? 0} maxSize={BODY_MAX_LENGTH} />
          <PromotionFormWarningMessages messages={[...warnings.body, ...contentsWarnings]} />
        </FormContent>
        {promotion.canUseEvent && (
          <PromotionFormDateRangePicker
            promotion={promotion}
            setPromotion={setPromotion}
            showErrorMessage={showErrorMessage}
          />
        )}
        {promotion.canUseOffer && (
          <PromotionFormOffer promotion={promotion} setPromotion={setPromotion} showErrorMessage={showErrorMessage} />
        )}
        {promotion.canUseCallToAction && (
          <>
            <FormContent name='ボタンの追加'>
              <CustomPullDown
                value={promotion.call_to_action.action_type}
                options={selectButtonOptions}
                onChange={(value) => setPromotion(promotion.changeCallToActionActionType(value))}
                clearable={false}
              />
            </FormContent>
            {promotion.canInputUrl && (
              <FormContent
                name='サイトのURL'
                informationText={`${urlAvailableAliases.join(
                  '、',
                )} と記述すると、公開時に自動でそれぞれの値が入力されます。`}
                required
              >
                <StyledInput
                  value={promotion.call_to_action.url}
                  onChange={(value) => setPromotion(promotion.changeCallToActionUrl(value))}
                  placeholder='https://example.com'
                  error={showErrorMessage && promotion.validateUrl().name}
                />
                <PromotionFormErrorMessages messages={errors.url} />
                <PromotionFormWarningMessages messages={warnings.url} />
              </FormContent>
            )}
          </>
        )}
        {!promotion.isPosted && (
          // postedAtのデータがある場合はGBPに掲載済みなので、予約投稿の設定は表示しない
          <PromotionFormPostSettings
            setPromotion={setPromotion}
            promotion={promotion}
            showErrorMessage={showErrorMessage}
          />
        )}
      </FormWrapper>
      {canUseTranslate && (
        <PromotionFormTranslation
          canAddTranslationLanguage={canAddTranslationLanguage}
          translationLanguages={translationLanguages}
          loadingLanguages={props.loadingLanguages}
          translatePost={props.translatePost}
          showErrorMessage={showErrorMessage}
          stores={selectedStores}
          promotionGroup={promotionGroup}
          onChangePromotionGroup={onChangePromotionGroup}
        />
      )}
      <ButtonsWrapper>
        {canSaveDraft && (
          <StyledButton onClick={handleOnSaveDraft} disabled={isDraftDisabled}>
            下書き保存
          </StyledButton>
        )}
        <StyledButton
          onClick={handleOnClickConfirmAndCreate}
          priority='high'
          disabled={props.loadingLanguages.size > 0 || disabled}
        >
          {`確認・${promotion.isScheduled ? '予約' : '公開'}`}
        </StyledButton>
      </ButtonsWrapper>

      {isModalOpen && (
        <PromotionGenerateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onApply={handleOnApplyGeneratedPromotion}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  margin-top: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;

const CustomPullDown = styled(PullDown)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  height: 54px;

  &&& {
    width: 170px;
    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      margin-top: 8px;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const Message = styled.div`
  font-size: 15px;
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const GenerateButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    display: flex;
    align-items: self-end;
    margin-left: 8px;
  }
`;

const StyledIcon = styled(Icon)`
  &&& {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 8px;
    padding: 0;
  }
`;
