import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import { useSelector } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import { GraphKey } from 'components/pageComponents/SearchKeywords/SearchKeywordsDetailTable';
import { MemoIconData, getDateLabelWithWeekOfDay, getValueText } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { KeywordGraphData } from 'models/Domain/SearchKeyword/SearchKeyword';
import { YearMonth } from 'models/Domain/YearMonth';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';

const INACTIVE_TRANSPARENCY = 0.8;

type Props = {
  className?: string;
  isLoading?: boolean;
  graphData?: KeywordGraphData;
  startMonth: YearMonth;
  endMonth: YearMonth;
  showSummary?: boolean;
  selectedStoreIds?: ImmutableSet<number>;
  onClickMemo?: (ids: number[]) => void;
  onClickLegend?: (key: GraphKey) => void;
  memoData?: MemoIconData[];
  graphSettings?: GraphSettings;
};

type ActiveType = number | 'summary' | null;

export const SearchKeywordsDetailGraph = React.memo<Props>(
  ({
    graphData = new KeywordGraphData(),
    startMonth,
    endMonth,
    showSummary = true,
    selectedStoreIds,
    onClickMemo,
    onClickLegend,
    memoData,
    graphSettings = new GraphSettings(),
  }) => {
    const [activeItem, setActiveItem] = useState<ActiveType>(null);
    const { stores } = useSelector((state) => ({
      stores: state.store.stores,
    }));
    const data: any[] = useMemo(
      () =>
        YearMonth.range(startMonth, endMonth).map((month) => {
          const result: any = { month: month.format() };
          result['summary'] = graphData.summary.findByPeriod(month);
          graphData.items.forEach((item) => {
            const storeId = item.storeId;
            result[storeId] = item.value.findByPeriod(month);
          });
          return result;
        }),
      [endMonth, graphData, startMonth],
    );

    const renderTooltip = useCallback(
      (data: TooltipProps<number, number | string>) => {
        const items = data.payload ?? [];

        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {items.map((item) => {
              const circleColor = transparentize(
                activeItem == null || activeItem === item.name ? 0 : INACTIVE_TRANSPARENCY,
                item.color ?? COLOR.WHITE,
              );
              const isSummary = item.name === 'summary';
              return (
                <ChartTooltip.Item key={item.name}>
                  <ChartTooltip.Label color={item.color}>
                    <ActiveCircle color={circleColor} />
                    {isSummary ? '合計' : (stores.findStore(item.name)?.fullName ?? item.name)}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value>{getValueText(item.value)}</ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [activeItem, stores],
    );

    const handleOnMouseEnter = useCallback(
      (activeType: ActiveType) => () => {
        setActiveItem(activeType);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveItem(null);
    }, []);

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    return (
      <ResponsiveContainer minHeight={300}>
        <LineChart data={data} margin={{ top: 64, right: 32, bottom: 16 }} onMouseLeave={() => setActiveItem(null)}>
          <Tooltip
            formatter={(value: number) => value.toLocaleString()}
            wrapperStyle={{ zIndex: 10 }}
            content={renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
          <Legend
            formatter={(value) => (value === 'summary' ? '合計' : (stores.findStore(value)?.fullName ?? value))}
            align={'left'}
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            onClick={(o: any) => onClickLegend && onClickLegend(o.dataKey)}
            content={(props: any) => renderLegend(props)}
          />
          <XAxis dataKey={'month'} tickMargin={8} interval={'equidistantPreserveStart'} />
          <YAxis
            tickFormatter={(value) => getValueText(value)}
            tickMargin={8}
            label={{
              value: '流入数',
              position: 'top',
              offset: 24,
            }}
            allowDecimals={false}
            domain={graphSettings.domain}
            allowDataOverflow={true}
          />
          <CartesianGrid vertical={false} strokeDasharray={'1 1'} />
          {showSummary && (
            <Line
              dataKey={'summary'}
              stroke={'#458a6a'}
              strokeOpacity={!activeItem || activeItem === 'summary' ? 1.0 : 1.0 - INACTIVE_TRANSPARENCY}
              strokeWidth={2}
              isAnimationActive={false}
              dot={{ fill: '#458a6a', clipDot: false }}
              activeDot={{
                cursor: 'pointer',
                onMouseEnter: handleOnMouseEnter('summary'),
                onMouseLeave: handleOnMouseLeave,
              }}
              cursor='pointer'
              onMouseEnter={handleOnMouseEnter('summary')}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {selectedStoreIds?.toList().map((storeId, index) => {
            const color = UNIVERSAL_COLORS.get(index % UNIVERSAL_COLORS.size);
            const opacity = !activeItem || activeItem === storeId ? 1.0 : 1.0 - INACTIVE_TRANSPARENCY;
            return (
              <Line
                key={index}
                dataKey={storeId}
                stroke={color}
                strokeOpacity={opacity}
                strokeWidth={2}
                isAnimationActive={false}
                dot={{ fill: color, clipDot: false }}
                activeDot={{
                  cursor: 'pointer',
                  onMouseEnter: handleOnMouseEnter(storeId),
                  onMouseLeave: handleOnMouseLeave,
                }}
                cursor='pointer'
                onMouseEnter={handleOnMouseEnter(storeId)}
                onMouseLeave={handleOnMouseLeave}
              />
            );
          })}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  },
);
