import { z } from 'zod';

import { JSObject, ValidationResult } from 'types/Common';

/**
 * zodのカスタムエラーメッセージ
 * @param issue
 * @param ctx
 */
export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  // zodのデフォルトのエラーメッセージを日本語化
  // 以下のページから作成しているので、適宜STORECASTに合わせて修正する
  // https://gist.github.com/k-maru/546aefc4aa90e143fe077bb05a1da261
  let message: string;
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === z.ZodParsedType.undefined) {
        message = '必ず入力してください。';
      } else {
        message = `${issue.expected} の入力を期待していますが、${issue.received} が入力されました。`;
      }
      break;
    case z.ZodIssueCode.invalid_literal:
      message = `無効なリテラル値です。 ${JSON.stringify(
        issue.expected,
        z.util.jsonStringifyReplacer,
      )} を入力してください。`;
      break;
    case z.ZodIssueCode.unrecognized_keys:
      message = `オブジェクトのキー '${z.util.joinValues(issue.keys, ', ')}' が識別できません。`;
      break;
    case z.ZodIssueCode.invalid_union:
      message = '入力形式が間違っています。';
      break;
    case z.ZodIssueCode.invalid_union_discriminator:
      message = `無効な識別子です。${z.util.joinValues(issue.options)} で入力してください。`;
      break;
    case z.ZodIssueCode.invalid_enum_value:
      message = `'${issue.received}' は無効な値です。${z.util.joinValues(issue.options)} で入力してください。`;
      break;
    case z.ZodIssueCode.invalid_arguments:
      message = '引数が間違っています。';
      break;
    case z.ZodIssueCode.invalid_return_type:
      message = '戻り値の型が間違っています。';
      break;
    case z.ZodIssueCode.invalid_date:
      message = '日付が間違っています。';
      break;
    case z.ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('includes' in issue.validation) {
          message = `"${issue.validation.includes}"を含む文字列を入力してください。`;
          if (typeof issue.validation.position === 'number') {
            message = `${message} "${issue.validation.includes}"は ${issue.validation.position + 1}文字目以降である必要があります。`;
          }
        } else if ('startsWith' in issue.validation) {
          message = `"${issue.validation.startsWith}"で始まる文字列を入力してください。`;
        } else if ('endsWith' in issue.validation) {
          message = `"${issue.validation.endsWith}"で終わる文字列を入力してください。`;
        } else {
          z.util.assertNever(issue.validation);
        }
      } else if (issue.validation !== 'regex') {
        message = `${issue.validation} の形式で入力してください。`;
      } else {
        message = '入力形式が間違っています。';
      }
      break;
    case z.ZodIssueCode.too_small:
      if (issue.type === 'array') {
        if (issue.exact) message = `${issue.minimum}個の要素を設定する必要があります。`;
        else if (issue.inclusive) message = `${issue.minimum}個以上の要素を設定する必要があります。`;
        else message = `${issue.minimum}個より多くの要素を設定する必要があります。`;
      } else if (issue.type === 'string') {
        if (issue.exact) message = `${issue.minimum}文字を入力してください。`;
        else if (issue.inclusive) message = `${issue.minimum}文字以上で入力してください。`;
        else message = `${issue.minimum}文字より多く入力してください。`;
      } else if (issue.type === 'number') {
        if (issue.exact) message = `${issue.minimum}の数値を入力してください。`;
        else if (issue.inclusive) message = `${issue.minimum}以上の数値を入力してください。`;
        else message = `${issue.minimum}より多い数値を入力してください。`;
      } else if (issue.type === 'date') {
        if (issue.exact) message = `${new Date(Number(issue.minimum))} の日時を入力してください。`;
        else if (issue.inclusive) message = `${new Date(Number(issue.minimum))} 以降の日時を入力してください。`;
        else message = `${new Date(Number(issue.minimum))}より後の日時を入力してください。`;
      } else message = '無効な入力です。';
      break;
    case z.ZodIssueCode.too_big:
      if (issue.type === 'array') {
        if (issue.exact) message = `${issue.maximum}個の要素を設定する必要があります。`;
        else if (issue.inclusive) message = `${issue.maximum}個以下の要素を設定する必要があります。`;
        else message = `${issue.maximum}個より少ない要素を設定する必要があります。`;
      } else if (issue.type === 'string') {
        if (issue.exact) message = `${issue.maximum}文字を入力してください。`;
        else if (issue.inclusive) message = `${issue.maximum}文字以下で入力してください。`;
        else message = `${issue.maximum}文字より少なく入力してください。`;
      } else if (issue.type === 'number') {
        if (issue.exact) message = `${issue.maximum}の数値を入力してください。`;
        else if (issue.inclusive) message = `${issue.maximum}以下の数値を入力してください。`;
        else message = `${issue.maximum}より小さい数値を入力してください。`;
      } else if (issue.type === 'bigint') {
        if (issue.exact) message = `${issue.maximum}の数値を入力してください。`;
        else if (issue.inclusive) message = `${issue.maximum}以下の数値を入力してください。`;
        else message = `${issue.maximum}より小さい数値を入力してください。`;
      } else if (issue.type === 'date') {
        if (issue.exact) message = `${new Date(Number(issue.maximum))}の日時を入力してください。`;
        else if (issue.inclusive) message = `${new Date(Number(issue.maximum))}以前の日時を入力してください。`;
        else message = `${new Date(Number(issue.maximum))}より前の日時を入力してください。`;
      } else message = '無効な入力です。';
      break;
    case z.ZodIssueCode.custom:
      message = '無効な入力です。';
      break;
    case z.ZodIssueCode.invalid_intersection_types:
      message = 'インターセクションの結果をマージできませんでした。';
      break;
    case z.ZodIssueCode.not_multiple_of:
      message = `数値は${issue.multipleOf}の倍数を入力してください。`;
      break;
    case z.ZodIssueCode.not_finite:
      message = '有限の数値を入力してください。';
      break;
    default:
      message = ctx.defaultError;
      z.util.assertNever(issue);
  }
  return { message };
};

/**
 * キーを指定してバリデーションを行う
 * @param schema
 * @param value
 * @param key
 */
export const validateKey = <T extends JSObject>(
  schema: z.ZodType<T>,
  value: unknown,
  key: keyof T,
): ValidationResult => {
  // 本当は必要な項目だけをバリデーションしたいが、スキーマにrefineを使うと特定の項目のみを取り出すことができなさそうなので、
  // 一旦全ての項目をバリデーションして、その後エラーがあれば指定されたキーにエラーがあるか判定する
  const result = schema.safeParse(value);
  // result.successがtrueの場合はバリデーション成功
  if (result.success) {
    return {
      isValid: true,
    };
  }
  // result.error.errorsからkeyに該当するエラーを取得し、なければそのキーについては成功とする
  // 複数エラーがある場合は最初に見つかったエラーを返す
  const error = result.error.errors.find((error) => error.path.includes(key as string));
  if (error) {
    return {
      isValid: false,
      error: error.message,
    };
  } else {
    return {
      isValid: true,
    };
  }
};
