import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { DatePicker } from 'components/atoms/DatePicker';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import Promotion, { PromotionTopicType } from 'models/Domain/Promotion/Promotion';

type Props = {
  promotion: Promotion;
  setPromotion: (promotion: Promotion) => void;
  showErrorMessage: boolean;
};

// 期間の入力欄のラベル
const PERIOD_LABELS: { [key in PromotionTopicType]: string } = {
  STANDARD: '', // 項目なし
  ALERT: '', // 項目なし
  OFFER: '特典の有効期間',
  EVENT: 'イベントの開催期間',
};

export const PromotionFormDateRangePicker: React.FC<Props> = (props) => {
  const { promotion, setPromotion, showErrorMessage } = props;

  const onChangeShowTime = useCallback(() => {
    setPromotion(promotion.changeShowEventTime(!(promotion.event?.showTime ?? false)));
  }, [promotion, setPromotion]);

  // 今日の0時(JST)
  const today = dayjs(dayjs().format('YYYY/MM/DD')).toDate();
  // イベントの期間として設定できるのは今日から1年以内（todayから1年後の前日23:59:59まで）
  const eventMaxDate = dayjs(today).add(1, 'year').subtract(1, 'seconds').toDate();

  return (
    <>
      <FormContent name={PERIOD_LABELS[promotion.topic_type]} required>
        <TimeCheckboxWrapper>
          期間に時間を設定する
          <TimeCheckbox toggle checked={promotion.event?.showTime} onChange={onChangeShowTime} />
        </TimeCheckboxWrapper>
      </FormContent>
      <DatePickerContainer>
        <DatePickerWrapper>
          <DatePickerLabel>開始日</DatePickerLabel>
          <StyledDatePicker
            dateFormat={'yyyy/MM/dd'}
            selected={promotion.event?.schedule.startDate?.toDate()}
            onChange={(value: Date | null) => setPromotion(promotion.changeEventStartDate(value))}
            selectsStart
            startDate={promotion.event?.schedule.start}
            endDate={promotion.event?.schedule.end}
            maxDate={eventMaxDate}
            placeholderText='YYYY/MM/DD'
          />
        </DatePickerWrapper>
        {promotion.event?.showTime && (
          <DatePickerWrapper>
            <DatePickerLabel>開始時間</DatePickerLabel>
            <StyledDatePicker
              dateFormat={'HH:mm:ss'}
              timeFormat={'HH:mm'}
              selected={promotion.event?.schedule.startTime?.toDate()}
              onChange={(value: Date | null) => setPromotion(promotion.changeEventStartTime(value))}
              selectsStart
              showTimeSelect
              showTimeSelectOnly
              placeholderText='hh:mm:ss'
            />
          </DatePickerWrapper>
        )}
      </DatePickerContainer>
      <DummyTextField error={showErrorMessage && promotion.event?.validateScheduleStart().name} />
      <DatePickerContainer>
        <DatePickerWrapper>
          <DatePickerLabel>終了日</DatePickerLabel>
          <StyledDatePicker
            dateFormat={'yyyy/MM/dd'}
            selected={promotion.event?.schedule.endDate?.toDate()}
            onChange={(value: Date | null) => setPromotion(promotion.changeEventEndDate(value))}
            selectsEnd
            startDate={promotion.event?.schedule.start}
            endDate={promotion.event?.schedule.end}
            placeholderText='YYYY/MM/DD'
            minDate={today}
            maxDate={eventMaxDate}
          />
        </DatePickerWrapper>
        {promotion.event?.showTime && (
          <DatePickerWrapper>
            <DatePickerLabel>終了時間</DatePickerLabel>
            <StyledDatePicker
              dateFormat={'HH:mm:ss'}
              timeFormat={'HH:mm'}
              selected={promotion.event?.schedule.endTime?.toDate()}
              onChange={(value: Date | null) => setPromotion(promotion.changeEventEndTime(value))}
              selectsEnd
              showTimeSelect
              showTimeSelectOnly
              disabled={false}
              placeholderText='hh:mm:ss'
            />
          </DatePickerWrapper>
        )}
      </DatePickerContainer>
      <DummyTextField error={showErrorMessage && promotion.event?.validateScheduleEnd().name} />
    </>
  );
};

const TimeCheckboxWrapper = styled.div`
  display: flex;
`;

const TimeCheckbox = styled(Checkbox)`
  margin-left: 16px;
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    height: 54px;
    padding: 0 16px;
    font-family: monospace !important;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  > :not(:first-child) {
    margin-left: 16px;
  }
`;

const DummyTextField = styled(Input)`
  &&& {
    input {
      display: none;
    }
  }
`;

const DatePickerLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;

const DatePickerWrapper = styled.div`
  flex: 1;
`;
