import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface YahooPlaceRecord {
  businessName: string;
  businessNameKana: string;
  categoryIds: List<string>;
  placeSeq: number;
}

export interface YahooAccountRecord {
  accountId: number;
  accountName: string;
  places: List<YahooPlaceRecord>;
}

const DefaultYahooAccountRecord = Record<YahooAccountRecord>({
  accountId: 0,
  accountName: '',
  places: List(),
});

export default class YahooPlaceSummary extends DefaultYahooAccountRecord {
  static fromJson(data: JSObject = {}): YahooPlaceSummary {
    const placesData = (data.places || []).map((place: JSObject) => ({
      businessName: place.business_name || '',
      businessNameKana: place.business_name_kana || '',
      categoryIds: List(place.category_ids || []),
      placeSeq: place.place_seq || 0,
    }));

    return new YahooPlaceSummary({
      accountId: data.account_id || 0,
      accountName: data.account_name || '',
      places: List(placesData),
    });
  }
}
