import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List as ImmutableList, OrderedSet as ImmutableOrderedSet } from 'immutable';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import {
  GBPSNSServices,
  SNSItem,
  SNSItems,
  SNSServiceType,
  SNSServices,
  YahooPlaceSNSServices,
  getSNSServiceName,
} from 'models/Domain/SNSLink';
import { Store } from 'models/Domain/Store';
import { YahooPlaceSNSLink } from 'models/Domain/YahooPlace/SNSLink';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type Props = {
  className?: string;
  attributeMetadatas: GmbAttributeMetadatas;
  attributes: GmbUrlAttributes;
  yahooPlaceSNSLink: YahooPlaceSNSLink;
  storeForEdit: Store;
  onChangeStore: (store: Store) => void;
  isConnectedGbp: boolean;
  isConnectedYahooPlace: boolean;
};

const placeholderTextUrl = {
  facebook: 'https://www.facebook.com/[ユーザー名]',
  instagram: 'https://www.instagram.com/[ユーザー名]/',
  tiktok: 'https://www.tiktok.com/@[ユーザー名]',
  twitter: 'https://www.twitter.com/[ユーザー名]',
  youtube: 'https://www.youtube.com/[ユーザー名]',
  pinterest: 'https://www.pinterest.com/[ユーザー名]/',
  linkedin: 'https://www.linkedin.com/in/[ユーザー名]',
  line: 'https://line.me/R/ti/p/@[ユーザー名]',
} satisfies Record<SNSServiceType, string>;

const SNSOptions: SelectOption<SNSServiceType>[] = SNSServices.map((service) => ({
  text: getSNSServiceName(service),
  value: service,
}));

type InputProps = {
  item: SNSItem;
  selectedServices: ImmutableList<SNSServiceType | ''>;
  availableServices: ImmutableOrderedSet<SNSServiceType>;
  onChangeService: (service: SNSServiceType) => void;
  onChangeUrl: (url: string) => void;
  onDelete: () => void;
};

const SNSInput: React.FC<InputProps> = ({
  item,
  selectedServices,
  availableServices,
  onChangeService,
  onChangeUrl,
  onDelete,
}) => {
  const { service, url } = item;

  // 選択可能なSNSサービスを取得（選択済みのサービスは除外）
  const availableOptions = SNSOptions.filter(
    (option) =>
      option.value === service ||
      (!selectedServices.includes(option.value) && availableServices.includes(option.value)),
  );
  return (
    <>
      <FlexContent>
        <StyledPullDown
          value={service}
          options={availableOptions}
          onChange={(value) => onChangeService(value)}
          placeholder={'SNSを選択'}
        />
        <StyledInput
          value={item.url}
          onChange={onChangeUrl}
          placeholder={service ? `例）${placeholderTextUrl[service]}` : 'URLを入力してください'}
          disabled={!service}
          error={service && url ? item.validateUrl().error : undefined}
        />
        <DeleteButtonWrapper>
          <DeleteButton onClick={() => onDelete()}>
            <StyledIcon name={'close'} />
          </DeleteButton>
        </DeleteButtonWrapper>
      </FlexContent>
    </>
  );
};

export const SNSLinkForm = React.memo<Props>(
  ({
    className,
    attributeMetadatas,
    yahooPlaceSNSLink,
    attributes,
    onChangeStore,
    storeForEdit,
    isConnectedGbp,
    isConnectedYahooPlace,
  }) => {
    const [items, setItems] = useState<SNSItems>(new SNSItems());
    const availableSNSServices = useMemo(() => {
      let services: ImmutableOrderedSet<SNSServiceType> = ImmutableOrderedSet();
      services = services.concat(
        GBPSNSServices.filter(
          (service) => !!attributeMetadatas.list.find((attribute) => attribute.attributeId === `url_${service}`),
        ),
      );
      if (isConnectedYahooPlace) {
        services = services.concat(YahooPlaceSNSServices);
      }
      return services;
    }, [attributeMetadatas, isConnectedYahooPlace]);

    // 空の値を含めた属性データに変換
    const complementedAttributes = attributes.complement(attributeMetadatas);

    const handleSetItems = useCallback(
      (updater: (items: SNSItems) => SNSItems) => {
        const newItems = updater(items);
        const attributes = newItems.toGmbUrlAttributes(complementedAttributes);
        setItems(newItems);
        onChangeStore(
          storeForEdit
            .changeUrlAttributes(attributes.removeNoValueAttributes())
            .changeYahooPlaceSNSLink(newItems.toYahooPlaceSNSLink()),
        );
      },
      [complementedAttributes, items, onChangeStore, storeForEdit],
    );

    const handleAddSNSItem = () => {
      handleSetItems((prev) => prev.addItem());
    };

    const handleDeleteSNSItem = (index: number) => {
      handleSetItems((prev) => prev.removeItem(index));
    };

    const handleChangeSNSService = (index: number) => (value: SNSServiceType | '') => {
      handleSetItems((prev) => prev.updateService(index, value));
    };

    const handleChangeSNSUrl = (index: number) => (value: string) => {
      handleSetItems((prev) => prev.updateUrl(index, value));
    };

    useEffect(() => {
      // 入力中の値、GBPの値、YahooPlaceの値の順でもとのデータにないサービスの値を追加していく
      const gbpSNSItems = SNSItems.createByGmbUrlAttributes(attributes);
      const yahooPlaceSNSItems = SNSItems.createByYahooPlaceSNSLink(yahooPlaceSNSLink);
      let snsItems = new SNSItems();
      if (gbpSNSItems.size === 0 && yahooPlaceSNSItems.size === 0) {
        snsItems = SNSItems.createEmpty();
      } else {
        snsItems = items.mergeItems(gbpSNSItems).mergeItems(yahooPlaceSNSItems);
      }
      handleSetItems(() => snsItems);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Wrapper className={className}>
        {items.list.map((item, index) => (
          <SNSInput
            key={index}
            item={item}
            availableServices={availableSNSServices}
            onChangeService={handleChangeSNSService(index)}
            onChangeUrl={handleChangeSNSUrl(index)}
            onDelete={() => handleDeleteSNSItem(index)}
            selectedServices={items.services}
          />
        ))}
        {items.size < availableSNSServices.size && (
          <AddButton onClick={handleAddSNSItem}>
            <StyledIcon name={'plus'} /> SNSを追加
          </AddButton>
        )}
        <Notice>注: 編集内容の品質確認が行われることがあり、公開されるまでに 3 日ほどかかる場合があります。</Notice>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const Notice = styled.p`
  font-size: 13px;
  color: ${COLOR.GRAY};
  margin: 16px 0;
`;

const FlexContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const StyledPullDown = styled(PullDown)`
  &&&& {
    height: 60px;
    .ui.dropdown.search {
      min-width: 12em;
    }
  }
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    color: ${COLOR.GREEN};
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;

const DeleteButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    color: ${COLOR.CHARCOAL_GRAY};
  }
`;

const StyledInput = styled(Input)`
  &&& {
    flex: 1;
    input {
      height: 60px;
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
    }
  }
`;

const StyledIcon = styled(SemanticIcon)`
  &&& {
    line-height: 1;
    height: auto;
  }
`;
