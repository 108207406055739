import { Record, Set } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Competitors } from 'models/Domain/Competitor/Competitor';
import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { Store } from 'models/Domain/Store';

import { StoreDetailActions } from './actions';

export type EditItemType =
  | 'code'
  | 'name'
  | 'phone'
  | 'websiteUrl'
  | 'regularHours'
  | 'specialHours'
  | 'category'
  | 'yahooCategory'
  | 'address'
  | 'openInfo'
  | 'profile'
  | 'attributes'
  | 'urlAttributes'
  | 'moreHours';

export class StoreDetailState extends Record<{
  storeForDetail: Store;
  storeForEdit: Store;
  attributeMetadatas: GmbAttributeMetadatas;
  availableUrlTypes: GbpAvailableUrlTypes;
  requireManualSettingItems: Set<EditItemType>;
  isSuccessAddressValidation?: boolean;
  competitors: Competitors;
  isLoadingCompetitors: boolean;
  isLoadingAttributeMetadatas: boolean;
  isLoadingAvailableUrlTypes: boolean;
}>({
  storeForDetail: new Store(),
  storeForEdit: new Store(),
  attributeMetadatas: new GmbAttributeMetadatas(),
  availableUrlTypes: new GbpAvailableUrlTypes(),
  // 手動反映が必要な項目
  requireManualSettingItems: Set<EditItemType>(),
  // 住所のバリデーションが成功したかどうか(一度住所を保存するとbooleanが入る)
  // trueの場合、住所入力欄の警告メッセージは表示しない
  isSuccessAddressValidation: undefined,
  // 競合店舗情報
  competitors: new Competitors(),
  // 非同期取得のデータの状況
  isLoadingCompetitors: true,
  isLoadingAttributeMetadatas: true,
  isLoadingAvailableUrlTypes: true,
}) {}

export const storeDetailReducer = reducerWithInitialState(new StoreDetailState())
  .case(StoreDetailActions.setStoreForDetail, (state, store) => {
    return state.set('storeForDetail', store);
  })
  .case(StoreDetailActions.setStoreForEdit, (state, store) => {
    return state.set('storeForEdit', store);
  })
  .case(StoreDetailActions.resetStoreForEdit, (state, payload) => {
    return state.set('storeForEdit', state.storeForDetail);
  })
  .case(StoreDetailActions.setAttributeMetadatas, (state, payload: GmbAttributeMetadatas) => {
    return state.set('attributeMetadatas', payload);
  })
  .case(StoreDetailActions.setAvailableUrlTypes, (state, payload: GbpAvailableUrlTypes) => {
    return state.set('availableUrlTypes', payload);
  })
  .case(
    StoreDetailActions.setRequireManualSettingItem,
    (state, payload: { item: EditItemType; requireManualSetting: boolean }) => {
      const { item, requireManualSetting } = payload;
      if (requireManualSetting) {
        return state.update('requireManualSettingItems', (items) => items.add(item));
      } else {
        return state.update('requireManualSettingItems', (items) => items.delete(item));
      }
    },
  )
  .case(StoreDetailActions.setIsSuccessAddressValidation, (state, payload: boolean) => {
    return state.set('isSuccessAddressValidation', payload);
  })
  .case(StoreDetailActions.setCompetitors, (state, payload) => {
    return state.set('competitors', payload);
  })
  .case(StoreDetailActions.setIsLoadingCompetitors, (state, payload) => {
    return state.set('isLoadingCompetitors', payload);
  })
  .case(StoreDetailActions.setIsLoadingAttributeMetadatas, (state, payload) => {
    return state.set('isLoadingAttributeMetadatas', payload);
  })
  .case(StoreDetailActions.setIsLoadingAvailableUrlTypes, (state, payload) => {
    return state.set('isLoadingAvailableUrlTypes', payload);
  });
