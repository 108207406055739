import React from 'react';

import styled from 'styled-components';

import {
  YahooPlacePayment,
  YahooPlacePaymentKey,
  getPaymentKeyLabel,
  yahooPlacePaymentKeys,
} from 'models/Domain/YahooPlace/Payment';

import { BooleanAttribute } from './YahooPlaceContents';

type Props = {
  payment: YahooPlacePayment;
};

type ItemProps = {
  visible: boolean;
  payment: YahooPlacePayment;
  label: string;
  keys: YahooPlacePaymentKey[];
};

const YahooPlacePaymentListItem = React.memo<ItemProps>(({ payment, label, visible, keys }) => {
  return (
    <PaymentGroup visible={visible}>
      <PaymentHeading>{label}</PaymentHeading>
      {keys.map((key) => (
        <BooleanAttribute key={key} value={payment.get(key)} label={getPaymentKeyLabel(key)} />
      ))}
    </PaymentGroup>
  );
});

const YahooPlacePaymentList: React.FC<Props> = React.memo(({ payment }) => {
  // 支払い情報がひとつも設定されていない場合は「未設定」と表示する
  if (!payment.hasValue) {
    return <Wrapper>未設定</Wrapper>;
  }
  return (
    <Wrapper>
      <YahooPlacePaymentListItem
        visible={payment.isExistsCashValue}
        payment={payment}
        label={'支払い方法'}
        keys={yahooPlacePaymentKeys.cash}
      />
      <YahooPlacePaymentListItem
        visible={payment.isExistsCreditCardValue}
        payment={payment}
        label={'クレジットカード'}
        keys={yahooPlacePaymentKeys.creditCard}
      />
      <YahooPlacePaymentListItem
        visible={payment.isExistsQrCodePaymentValue}
        payment={payment}
        label={'QRコード決済'}
        keys={yahooPlacePaymentKeys.qrCodePayment}
      />
      <YahooPlacePaymentListItem
        visible={payment.isExistsEMoneyValue}
        payment={payment}
        label={'電子マネー'}
        keys={yahooPlacePaymentKeys.eMoney}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const PaymentHeading = styled.div`
  font-weight: bold;
`;

const PaymentGroup = styled.div<{ visible?: boolean }>`
  display: ${({ visible = true }) => (visible ? 'block' : 'none')};
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export default React.memo(YahooPlacePaymentList);
