import React, { useCallback, useEffect, useState } from 'react';

import { goBack } from 'connected-react-router';
import { Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { Tab, TabContainer } from 'components/atoms/Tab';
import {
  PromotionContent,
  PromotionTranslationContent,
} from 'components/pageComponents/PromotionDetail/PromotionContent';
import { PromotionInfoDetailHeader } from 'components/pageComponents/PromotionDetail/PromotionInfoDetailHeader';
import { PromotionInfoDetailStores } from 'components/pageComponents/PromotionDetail/PromotionInfoDetailStores';
import {
  Category,
  EditableWrapper,
  FlexHeader,
  MetaInfoWrapper,
} from 'components/pageComponents/PromotionIndex/PromotionInfoCard';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import Promotion, { PROMOTION_LANGUAGE_LABELS } from 'models/Domain/Promotion/Promotion';
import { User } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { PromotionActions } from 'modules/promotion/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

/** 投稿に対して編集・削除メニューを表示するかどうか */
export const showEditMenu = (currentUser: User, promotion: Promotion) => {
  // 本社スタッフ・管理者は可
  if (currentUser.isVmdUser || currentUser.isAdminUser) {
    return true;
  }

  // 以下、SV、店舗スタッフの場合

  // 送信先店舗が空の場合、下書きでなければ非表示
  if (promotion.stores.isEmpty()) {
    return promotion.isDraft;
  }

  const promotionStoreIds = Set(promotion.stores.map((store) => store.store_id));
  // SV: 管理店舗
  // 店舗スタッフ: 所属店舗
  const userStoreIds = Set(currentUser.isSvUser ? currentUser.managing_stores : currentUser.stores);

  // 投稿の送信先店舗が全てユーザの店舗に含まれている場合、表示
  // 投稿の送信先店舗にユーザの店舗外の店舗がある場合、非表示
  return promotionStoreIds.isSubset(userStoreIds);
};

export const PromotionDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();

  const dispatch = useDispatch();

  const { currentUser, promotionGroup, stores, isLoading } = useSelector(
    (state: State) => ({
      currentUser: state.app.currentUser,
      promotionGroup: state.promotion.promotionGroup,
      stores: state.store.stores,
      isLoading: state.promotion.isLoadingPromotion,
    }),
    shallowEqual,
  );
  const promotion = promotionGroup.promotion;

  useEffect(() => {
    dispatch(PromotionActions.setPromotion(new Promotion()));
    dispatch(PromotionActions.getPromotion(id));
  }, [dispatch, id]);

  const onEdit = () => {
    dispatch(
      AppActions.moveTo({
        pathname: Path.localpost.edit.replace(':id', promotion._id ?? ''),
        state: { source: location.pathname },
      }),
    );
  };

  const onCreate = () => {
    dispatch(
      AppActions.moveTo({
        pathname: Path.localpost.create,
        search: `?topic_type=${promotion.topic_type}`,
        state: { baseId: promotion._id ?? '', source: location.pathname },
      }),
    );
  };

  const onDelete = () => {
    if (!window.confirm('削除してよろしいですか？')) return;
    dispatch(PromotionActions.deletePromotion({ id: promotion._id ?? '' }));
  };

  const handleOnBack = useCallback(() => {
    // 遷移元が投稿一覧なら検索条件やスクロール位置を保持するために戻る、それ以外なら投稿一覧に遷移
    if (state?.source === Path.localpost.index) {
      dispatch(goBack());
    } else {
      dispatch(replaceWithOrganizationId(Path.localpost.index));
    }
  }, [dispatch, state]);

  const [selectedTranslateIndex, setSelectedTranslateIndex] = useState(0);
  useEffect(() => {
    setSelectedTranslateIndex(0);
  }, [id]);

  if (!promotion) {
    return null;
  }

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('投稿詳細')} />
      <PromotionInfoDetailHeader
        onEdit={onEdit}
        onCreate={onCreate}
        onDelete={onDelete}
        onBack={handleOnBack}
        canEdit={showEditMenu(currentUser, promotion) && promotionGroup.canEdit}
        canDelete={showEditMenu(currentUser, promotion)}
      />
      <StyledBody>
        {promotionGroup.isStatusInitialized && (
          <>
            {promotionGroup.hasGmbStatusReject ? (
              <ErrorWrapper>
                公開が拒否された投稿があります。本投稿を削除し、該当箇所を修正した内容を再度投稿してください。
                拒否の理由に関しては
                <Link href='https://support.google.com/business/answer/7213077?hl=ja' target='_blank'>
                  投稿に関するポリシー
                </Link>
                をご確認ください。
              </ErrorWrapper>
            ) : !promotionGroup.canEdit ? (
              <AlertWrapper>
                <Alert type={'caution'}>
                  <Alert.Title>この投稿はSTORECASTでは編集できません</Alert.Title>
                  <Alert.Section>
                    <Alert.Content>
                      複数枚の写真または動画を含む投稿の作成および編集は、現在サポートされておりません。 <br />
                      「複製して新規作成」した場合は、画像または動画を削除する必要があります。
                    </Alert.Content>
                  </Alert.Section>
                </Alert>
              </AlertWrapper>
            ) : null}
          </>
        )}

        <MainContentWrapper>
          <StyledSegment>
            <FlexHeader>
              <Category>{promotion.topicTypeLabel}</Category>
              <MetaInfoWrapper>
                {promotion.isDraft ? (
                  <DateWrapper>下書き</DateWrapper>
                ) : promotion.isScheduled ? (
                  <DateWrapper>{promotion.scheduledAt?.format('[投稿予定日時：]YYYY/MM/DD HH:mm') ?? ''}</DateWrapper>
                ) : (
                  <DateWrapper>{promotion.displayPostAt?.format('[投稿日時：]YYYY/MM/DD HH:mm') ?? ''}</DateWrapper>
                )}
                <EditableWrapper>
                  {promotionGroup.canEdit && showEditMenu(currentUser, promotion) ? '' : '編集不可'}
                </EditableWrapper>
              </MetaInfoWrapper>
            </FlexHeader>
            <PromotionContent promotion={promotion} stores={stores} />
          </StyledSegment>
          {promotionGroup.hasChildren && (
            <TranslationContents>
              <TranslationLabel>多言語表記</TranslationLabel>
              <StyledSegment>
                <StyledTabContainer>
                  {promotionGroup.children.map((child, i) => (
                    <StyledTab
                      key={child.language}
                      active={selectedTranslateIndex === i}
                      onClick={() => setSelectedTranslateIndex(i)}
                    >
                      {child.language ? PROMOTION_LANGUAGE_LABELS[child.language] : (child.language ?? child.language)}
                    </StyledTab>
                  ))}
                </StyledTabContainer>
                {promotionGroup.children.get(selectedTranslateIndex) && (
                  <PromotionTranslationContent
                    promotion={promotion}
                    translationData={promotionGroup.children.get(selectedTranslateIndex)!}
                    stores={stores}
                  />
                )}
              </StyledSegment>
            </TranslationContents>
          )}
        </MainContentWrapper>

        {promotion.source?.type === 'instagram' && (
          <InstagramAccountWrapper>
            <InstagramAccountTitleWrapper>
              <CustomIcon type='instagram' />
              <InstagramAccountTitle>Instagramからの投稿</InstagramAccountTitle>
            </InstagramAccountTitleWrapper>
            <InstagramAccountNameWrapper>
              <InstagramAccountName>{promotion.source.name}</InstagramAccountName>
              <InstagramAccountUserName>{promotion.source.userName}</InstagramAccountUserName>
            </InstagramAccountNameWrapper>
            <InstagramLink href={promotion.source.permalink}>Instagramで確認する</InstagramLink>
          </InstagramAccountWrapper>
        )}

        <PromotionInfoDetailStores
          promotion={promotion}
          stores={stores}
          statusSummaries={promotionGroup.getPostStatusSummaries()}
        />

        {isLoading && (
          <LoadingWrapper>
            <LoadingWrapperBase />
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
      </StyledBody>
    </MainWrapper>
  );
};

const AlertWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const MainContentWrapper = styled.div``;

const TranslationContents = styled.div`
  margin: 32px 0;
`;

const TranslationLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  background-color: ${COLOR.WHITE};
  border: 1px solid ${COLOR.RED};
  padding: 20px 30px;
  letter-spacing: 0.11em;
  margin-bottom: 16px;
  color: ${COLOR.RED};
`;

const Link = styled.a`
  color: blue;
  cursor: pointer;
`;

export const DateWrapper = styled.div`
  color: ${COLOR.DARK_GRAY};
  text-align: end;
  font-size: 16px;
`;

const StyledBody = styled(Body)`
  position: relative;
`;

const InstagramAccountWrapper = styled.div`
  display: block;
  margin-top: 30px;
`;

const InstagramAccountTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const InstagramAccountTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const InstagramAccountNameWrapper = styled.div``;

const InstagramAccountName = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
  word-break: break-all;
`;
const InstagramAccountUserName = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR.DARK_GRAY};
  word-break: break-all;
  &:before {
    content: '@';
    margin-right: 0.05em;
  }
`;

const CustomIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  padding: 0;
`;

const InstagramLink = styled(ExternalLink)`
  font-size: 14px;
  font-weight: bold;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  z-index: 1; /** カルーセルの矢印より上に表示されるようにする */
`;

const StyledTabContainer = styled(TabContainer)`
  margin: 0;
  background: white;
`;

const StyledTab = styled(Tab)`
  height: 32px;
`;

const StyledSegment = styled(Segment)`
  &&& {
    background: white;
    margin-top: 0;
    margin-bottom: 32px;
  }
`;
