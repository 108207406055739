import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { YahooPlaceCategoriesApi } from 'ApiClient/YahooPlaceApi';
import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { ContextHelp } from 'components/molecules/ContextHelp';
import VirtualizedDropdown from 'components/pageComponents/StoreDetail/CategoryPullDown';
import { StoreHelp } from 'helpers/ContextHelp';
import { Store } from 'models/Domain/Store';
import { YahooPlaceCategories } from 'models/Domain/YahooPlace/Category';
import { COLOR } from 'style/color';

export const EditYahooPlaceCategories: React.FC<{
  storeForEdit: Store;
  categoryList: YahooPlaceCategories;
  changeStore: (v: Store) => void;
  showService: boolean;
  isLoadingCategory: boolean;
}> = ({ storeForEdit, changeStore, categoryList, showService, isLoadingCategory }) => {
  const { mainCategoryId, subCategoryIds } = storeForEdit.location.connectedServices.yahooPlace;

  const [selectableMainCategories, setSelectableMainCategories] = useState<YahooPlaceCategories>(categoryList);
  // メインカテゴリの変更に制限があるか（メインカテゴリがまだ設定されていなければ制限なく変更できる）
  const [isMainCategoryLimited] = useState(!!mainCategoryId);

  const { isValid, error } = storeForEdit.validate().yahooPlaceCategory;

  const fetchSelectableMainCategories = useCallback(async (mainCategoryId) => {
    const response = await YahooPlaceCategoriesApi.get(mainCategoryId);
    if (response.isSuccess) {
      const categories = YahooPlaceCategories.fromJSON(response.data);
      setSelectableMainCategories(categories);
    }
  }, []);

  useEffect(() => {
    fetchSelectableMainCategories(mainCategoryId);
  }, [fetchSelectableMainCategories, mainCategoryId]);

  const mainCategoryOptions = useMemo(() => {
    return (isMainCategoryLimited ? selectableMainCategories : categoryList).generateCategoryOption();
  }, [categoryList, isMainCategoryLimited, selectableMainCategories]);

  const categoryOptions = useMemo(() => {
    return categoryList.generateCategoryOption();
  }, [categoryList]);

  if (isLoadingCategory) {
    return (
      <Wrapper>
        <LabelWrapper>
          <Label>カテゴリー{showService ? '（Yahoo! プレイス）' : ''}</Label>
          {showService && <ServiceIcon.Yahoo />}
        </LabelWrapper>
        <LoadingWrapper>
          <Loader active={true} size={'big'} inline={true} label={'カテゴリを取得中'} />
        </LoadingWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>カテゴリー{showService ? '（Yahoo! プレイス）' : ''}</Label>
        {showService && <ServiceIcon.Yahoo />}
      </LabelWrapper>
      {!isValid && <Error>{error}</Error>}
      <ContentWrapper>
        <HeadingWrapper>
          メインカテゴリー
          <ContextHelp content={StoreHelp.yahooMainCategory} />
        </HeadingWrapper>
        <Category
          value={mainCategoryId}
          items={mainCategoryOptions}
          placeholder={'メインカテゴリー'}
          onSelect={(value) => {
            changeStore(storeForEdit.changeYahooPlaceMainCategory(value));
          }}
        />
      </ContentWrapper>
      <ContentWrapper>
        サブカテゴリー
        {subCategoryIds.map((categoryId, idx) => {
          return (
            <AdditionalCategoryWrapper key={idx}>
              <Category
                value={categoryId}
                items={categoryOptions}
                placeholder={'カテゴリー'}
                onSelect={(value) => {
                  changeStore(storeForEdit.changeYahooPlaceSubCategory(idx, value));
                }}
              />
              <XIcon
                onClick={() => {
                  changeStore(storeForEdit.removeYahooPlaceSubCategory(idx));
                }}
              />
            </AdditionalCategoryWrapper>
          );
        })}
        <AddWrapper>
          {subCategoryIds.size < 3 && (
            <AddButton onClick={() => changeStore(storeForEdit.addYahooPlaceSubCategory())}>
              <AddIcon />
              <AddCategory>サブカテゴリーを追加</AddCategory>
            </AddButton>
          )}
        </AddWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Label = styled.label`
  font-weight: bold;
`;

const XIcon = styled(Icon).attrs({ name: 'x' })`
  &&& {
    margin-left: 16px;
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
`;

const AddWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const AddCategory = styled.div`
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const AdditionalCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Category = styled(VirtualizedDropdown)`
  &&& {
    margin-top: 8px;
    width: 100%;
  }
`;

const Error = styled.div`
  margin: 16px 0;
  color: ${COLOR.RED};
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

const AddIcon = styled(Icon).attrs({ name: 'plus' })`
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.GRAY};
  margin-top: 16px;
  height: 200px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
