import { AggregateUnit } from 'types/Common';

import ApiClient, { FaasApiClient } from './index';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

type Period = {
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
};

export type GbpPerformanceStats = {
  business_impressions_desktop_maps: number;
  business_impressions_desktop_search: number;
  business_impressions_mobile_maps: number;
  business_impressions_mobile_search: number;
  website_clicks: number;
  call_clicks: number;
  business_direction_requests: number;
  business_conversations: number;
  business_bookings: number;
  review_rate_count: number;
  total_review_rate_count: number;
  review_comment_count: number;
  total_review_comment_count: number;
  review_reply_count: number;
  total_review_reply_count: number;
  review_average_rating: number | null;
  review_period_average_rating: number | null;
  promotion_standard_count: number | null;
  promotion_alert_count: number | null;
  promotion_event_count: number | null;
  promotion_offer_count: number | null;
  promotion_standard_rejected_count: number | null;
  promotion_alert_rejected_count: number | null;
  promotion_event_rejected_count: number | null;
  promotion_offer_rejected_count: number | null;
  image_interior_count: number | null;
  image_exterior_count: number | null;
  image_product_count: number | null;
  image_additional_count: number | null;
};

export type GbpPerformanceGetParams = {
  unit: AggregateUnit;
  start_date: Period['start_date'];
  end_date: Period['end_date'];
  store_ids: string;
};
export type GbpPerformanceGetResponse = {
  condition: {
    aggregate_unit: AggregateUnit;
    store_ids: number[];
    period: Period;
  };
  last_update_at: string;
  graph_items: {
    period: Period;
    stats: GbpPerformanceStats;
  }[];
  table_items: {
    store_id: number;
    period: Period;
    stats: GbpPerformanceStats;
  }[];
};
export type GbpPerformanceGetMonthlyParams = {
  month: string; // YYYY-MM
  store_ids: string;
};
export type GbpPerformanceGetMonthlyResponse = {
  condition: {
    month: string; // YYYY-MM
    store_ids: number[];
  };
  items: {
    month: GbpPerformanceStats | null;
    last_month: GbpPerformanceStats | null;
    three_months_ago: GbpPerformanceStats | null;
    twelve_months_ago: GbpPerformanceStats | null;
  };
};

const GBP_PERFORMANCE_API_ENDPOINT = `/gmb/performance`;
export class GbpPerformanceApi {
  static get(params: GbpPerformanceGetParams) {
    return apiClient.get<GbpPerformanceGetResponse>(`${GBP_PERFORMANCE_API_ENDPOINT}`, params, { cache: true });
  }

  static getMonthly(params: GbpPerformanceGetMonthlyParams) {
    return apiClient.get<GbpPerformanceGetMonthlyResponse>(`${GBP_PERFORMANCE_API_ENDPOINT}/monthly`, params, {
      cache: true,
    });
  }
}

export type GbpPerformanceCsvCreateParams = {
  store_ids: number[];
  aggregate_unit: AggregateUnit;
  period: {
    start_date: string; // YYYY-MM-DD
    end_date: string; // YYYY-MM-DD
  };
  columns: (keyof GbpPerformanceStats)[];
};
type GbpPerformanceCsvCreateResponse = {
  executionArn: string;
};

type GbpPerformanceCsvStatusParams = {
  executionArn: string;
};
type GbpPerformanceCsvStatusResponse = {
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  download_url: string;
  message: string;
};

const GBP_PERFORMANCE_CSV_API_ENDPOINT = `/gmb/performance/csv`;
export class GbpPerformanceCsvApi {
  static create(params: GbpPerformanceCsvCreateParams) {
    return faasApiClient.post<GbpPerformanceCsvCreateResponse>(`${GBP_PERFORMANCE_CSV_API_ENDPOINT}/create`, params);
  }

  static status(params: GbpPerformanceCsvStatusParams) {
    return faasApiClient.post<GbpPerformanceCsvStatusResponse>(`${GBP_PERFORMANCE_CSV_API_ENDPOINT}/status`, params);
  }
}
