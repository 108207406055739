import ApiClient, { FaasApiClient } from 'ApiClient';
import CONFIG from 'config';
import { GmbLatLngUpdateParams } from 'models/Domain/GmbLocation/GmbLatlng';
import { JSObject } from 'types/Common';
import { StoreGmbLocationProfilePatchParams, StoreListGetResponse } from 'types/responses/Store';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

const STORE_LIST_PATH = '/stores';
const STORE_DETAIL_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}`;
const STORE_DETAIL_CODE_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/code`;
const STORE_DETAIL_PHONE_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/phone`;
const STORE_DETAIL_NAME_BRANCH_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/name`;
const STORE_DETAIL_NAME_KANA_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/name_kana`;
const STORE_DETAIL_WEBSITE_URL_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/website_url`;
const STORE_DETAIL_REGULAR_HOURS_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/regular_hours`;
const STORE_DETAIL_SPECIAL_HOURS_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/special_hours`;
const STORE_DETAIL_MORE_HOURS_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/more_hours`;
const STORE_DETAIL_ADDRESS_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/address`;
const STORE_DETAIL_PROFILE_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/profile`;
const STORE_DETAIL_ATTRIBUTES_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/attributes`;
const STORE_CONNECT_GMB_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/connect_gmb`;
const STORE_CONNECT_ABC_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}:connect_abc`;
const STORE_DISCONNECT_ABC_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}:disconnect_abc`;
const STORE_CONNECT_YAHOO_PLACE_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}:connect_yahoo`;
const STORE_DISCONNECT_YAHOO_PLACE_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}:disconnect_yahoo`;
const STORE_IMPORT_YAHOO_PLACE_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}:import_yahoo`;
const STORE_DETAIL_GMB_CATEGORIES = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/category`;
const STORE_DETAIL_YAHOO_PLACE_CATEGORIES = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/yahoo_category`;
const STORE_DETAIL_YAHOO_PLACE_SNS = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/yahoo_sns`;
const STORE_DETAIL_YAHOO_PLACE_PAYMENT = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/yahoo_payment`;
const STORE_DETAIL_YAHOO_PLACE_BUSINESS = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/yahoo_business`;
const STORE_DETAIL_GMB_OPEN_INFO_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/open_info`;
const STORE_DETAIL_OPENING_DATE_PATH = (storeId: number) => `${STORE_LIST_PATH}/${String(storeId)}/opening_date`;
const STORE_DETAIL_GMB_LATLNG_PATH = (storeId: number) => `${STORE_DETAIL_PATH(storeId)}/latlng`;
const STORE_BULK_EDIT_PATH = `${STORE_LIST_PATH}/bulk_edit`;
const STORE_BULK_EDIT_BUSINESS_INFO_PATH = `${STORE_LIST_PATH}/bulk_edit_store_info`;
const CSV_DOWNLOAD_PATH = `${STORE_LIST_PATH}/csv/download`;
const CSV_IMPORT_PATH = `${STORE_LIST_PATH}/csv/upload`;
const CSV_IMPORT_CHECK_PATH = `${STORE_LIST_PATH}/csv/upload/status`;
const STORE_CHECK_PATH = `${STORE_LIST_PATH}/check`;

export class StoresApi {
  static get() {
    return apiClient.get<StoreListGetResponse>(STORE_LIST_PATH);
  }

  static post(params: JSObject) {
    return apiClient.post(STORE_LIST_PATH, params);
  }
}

export class StoreApi {
  static get(storeId: number) {
    return apiClient.get(STORE_DETAIL_PATH(storeId));
  }

  static put(params: JSObject) {
    return apiClient.put(STORE_DETAIL_PATH(params.id), params);
  }

  static delete(storeId: number) {
    return apiClient.delete(STORE_DETAIL_PATH(storeId));
  }

  static patchCode(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_CODE_PATH(storeId), params);
  }

  static patchProfile(storeId: number, params: StoreGmbLocationProfilePatchParams) {
    return apiClient.patch(STORE_DETAIL_PROFILE_PATH(storeId), params);
  }

  static patchPhone(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_PHONE_PATH(storeId), params);
  }

  static patchNameBranch(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_NAME_BRANCH_PATH(storeId), params);
  }

  static patchNameKana(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_NAME_KANA_PATH(storeId), params);
  }

  static patchWebsiteUrl(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_WEBSITE_URL_PATH(storeId), params);
  }

  static patchRegularHours(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_REGULAR_HOURS_PATH(storeId), params);
  }

  static patchSpecialHours(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_SPECIAL_HOURS_PATH(storeId), params);
  }

  static patchMoreHours(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_MORE_HOURS_PATH(storeId), params);
  }

  static patchAddress(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_ADDRESS_PATH(storeId), params);
  }

  static patchGmbCategories(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_GMB_CATEGORIES(storeId), params);
  }

  static patchYahooPlaceCategories(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_YAHOO_PLACE_CATEGORIES(storeId), params);
  }

  static patchYahooPlaceSNS(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_YAHOO_PLACE_SNS(storeId), params);
  }

  static patchYahooPlacePayment(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_YAHOO_PLACE_PAYMENT(storeId), params);
  }

  static patchYahooPlaceBusiness(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_YAHOO_PLACE_BUSINESS(storeId), params);
  }

  static patchGmbOpenInfo(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_GMB_OPEN_INFO_PATH(storeId), params);
  }

  static patchGmbOpeningDate(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_OPENING_DATE_PATH(storeId), params);
  }

  static patchAttributes(storeId: number, params: JSObject) {
    return apiClient.patch(STORE_DETAIL_ATTRIBUTES_PATH(storeId), params);
  }
  static patchGmbMapLatlng(storeId: number, params: GmbLatLngUpdateParams) {
    return apiClient.patch(STORE_DETAIL_GMB_LATLNG_PATH(storeId), params);
  }
}

export class StoreConnectGmbApi {
  static post(storeId: number, params: JSObject) {
    return apiClient.post(STORE_CONNECT_GMB_PATH(storeId), params);
  }
}

/** 店舗をApple Business Connectと連携 */
export class StoreConnectAbcApi {
  static post(storeId: number, params: { account_id: number; location_id: string }) {
    return apiClient.post(STORE_CONNECT_ABC_PATH(storeId), params);
  }
}

/** 店舗のApple Business Connect連携を解除 */
export class StoreDisconnectAbcApi {
  static post(storeId: number) {
    return apiClient.post(STORE_DISCONNECT_ABC_PATH(storeId));
  }
}

/** 店舗とYahoo! プレイスと連携 */
export class StoreConnectYahooPlaceApi {
  static post(storeId: number, params: JSObject) {
    return apiClient.post(STORE_CONNECT_YAHOO_PLACE_PATH(storeId), params);
  }
}

/** 店舗のYahoo! プレイス連携を解除 */
export class StoreDisconnectYahooPlaceApi {
  static post(storeId: number) {
    return apiClient.post(STORE_DISCONNECT_YAHOO_PLACE_PATH(storeId));
  }
}

/** 店舗にYahoo! プレイスの情報を取り込む */
export class StoreImportYahooPlaceApi {
  static post(storeId: number, params: JSObject) {
    return apiClient.post(STORE_IMPORT_YAHOO_PLACE_PATH(storeId), params);
  }
}

export class StoreBulkEditApi {
  static patch(params: JSObject) {
    return apiClient.patch<{ success_store_ids: number[]; failure_store_ids: number[] }>(STORE_BULK_EDIT_PATH, params);
  }
}

export class StoreBulkEditBusinessInfoApi {
  static post(params: JSObject) {
    return apiClient.post(STORE_BULK_EDIT_BUSINESS_INFO_PATH, params);
  }
}

export class CsvDownloadApi {
  static get(params: { store_ids: number[] }) {
    const storeIds = [...params.store_ids];
    storeIds.sort();
    return apiClient.get<{ download_url: string }>(CSV_DOWNLOAD_PATH, { store_ids: storeIds.join(',') });
  }
}

export class CsvValidateApi {
  static post({ csv }: { csv: File }) {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('validate_only', '1');
    const apiClient = new ApiClient({
      baseURL: CONFIG.FAAS_API_ENDPOINT,
    });
    return apiClient.post(CSV_IMPORT_PATH, formData);
  }
}

export class CsvImportApi {
  static post({ csv }: { csv: File }) {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('validate_only', '0');
    const apiClient = new ApiClient({
      baseURL: CONFIG.FAAS_API_ENDPOINT,
    });
    return apiClient.post(CSV_IMPORT_PATH, formData);
  }
}

export class CsvImportApiStatusCheckApi {
  static post(params: { executionArn: string }) {
    return faasApiClient.post(CSV_IMPORT_CHECK_PATH, params);
  }
}

export type StoreCheckParams = {
  fields?: (keyof StoreCheckResponse['items'])[];
  store_ids?: string; // カンマ区切り
};

type StoreCheckResponseStatus = {
  done: number[];
  not_done: number[];
};

export type StoreCheckResponse = {
  period: {
    start_date: string; // YYYY-MM-DD
    end_date: string; // YYYY-MM-DD
  };
  items: {
    basic_information?: {
      name: StoreCheckResponseStatus;
      address: StoreCheckResponseStatus;
      phone: StoreCheckResponseStatus;
      business_hour: StoreCheckResponseStatus;
      url: StoreCheckResponseStatus;
      description: StoreCheckResponseStatus;
      diff_gbp: StoreCheckResponseStatus;
    };
    local_post?: {
      new_post: StoreCheckResponseStatus;
    };
    review?: {
      replied: StoreCheckResponseStatus;
    };
    photo?: {
      interior: StoreCheckResponseStatus;
      exterior: StoreCheckResponseStatus;
      new_photo: StoreCheckResponseStatus;
    };
  };
};

export class StoreCheckApi {
  static get(params: StoreCheckParams) {
    return apiClient.get<StoreCheckResponse>(STORE_CHECK_PATH, params, { cache: true });
  }
}
