import React, { useCallback } from 'react';

import styled from 'styled-components';

import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { toggleOptionalBoolean } from 'helpers/utils';
import {
  YahooPlacePayment,
  YahooPlacePaymentKey,
  getPaymentKeyLabel,
  yahooPlacePaymentKeys,
} from 'models/Domain/YahooPlace/Payment';

import { CheckboxWrapper, GroupItemWrapper, GroupLabel, GroupWrapper, StyledCheckbox } from './EditYahooPlace';

type Props = {
  className?: string;
  payment: YahooPlacePayment;
  onChange: (value: YahooPlacePayment) => void;
};

type FormProps = {
  label: string;
  keys: YahooPlacePaymentKey[];
  payment: YahooPlacePayment;
  onChange: (key: YahooPlacePaymentKey, value: boolean | undefined) => void;
};

const PaymentGroupForm = React.memo<FormProps>(({ label, keys, payment, onChange }) => {
  const handleClick = useCallback(
    (key: YahooPlacePaymentKey) => () => {
      onChange(key, toggleOptionalBoolean(payment.get(key)));
    },
    [onChange, payment],
  );
  return (
    <GroupWrapper>
      <GroupLabel>{label}</GroupLabel>
      <GroupItemWrapper>
        <CheckboxWrapper>
          {keys.map((key) => (
            <OptionalToggle
              key={key}
              label={getPaymentKeyLabel(key)}
              checked={payment.get(key)}
              onClick={handleClick(key)}
            />
          ))}
        </CheckboxWrapper>
      </GroupItemWrapper>
    </GroupWrapper>
  );
});

const EditYahooPlacePayment: React.FC<Props> = ({ className, payment, onChange }) => {
  const { cashOnly } = payment;

  const handleChangeCashOnly = useCallback(() => {
    // 「現金のみ」がtrueになったら、他の項目をすべて選択解除し非表示にする
    if (cashOnly) {
      onChange(payment.changeValue('cashOnly', false));
    } else {
      onChange(payment.changeToCashOnly());
    }
  }, [cashOnly, onChange, payment]);

  const handleChangePayment = useCallback(
    (key: YahooPlacePaymentKey, value: boolean | undefined) => {
      onChange(payment.changeValue(key, value));
    },
    [onChange, payment],
  );

  return (
    <Wrapper className={className}>
      <GroupWrapper>
        <GroupLabel>支払い方法</GroupLabel>
        <GroupItemWrapper>
          <StyledCheckbox checked={payment.cashOnly} label={'現金のみ'} onChange={handleChangeCashOnly} />
        </GroupItemWrapper>
      </GroupWrapper>
      {!cashOnly && (
        <React.Fragment>
          <PaymentGroupForm
            label={'クレジットカード'}
            keys={yahooPlacePaymentKeys.creditCard}
            payment={payment}
            onChange={handleChangePayment}
          />
          <PaymentGroupForm
            label={'QRコード決済'}
            keys={yahooPlacePaymentKeys.qrCodePayment}
            payment={payment}
            onChange={handleChangePayment}
          />
          <PaymentGroupForm
            label={'電子マネー'}
            keys={yahooPlacePaymentKeys.eMoney}
            payment={payment}
            onChange={handleChangePayment}
          />
        </React.Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default React.memo(EditYahooPlacePayment);
