import React, { useMemo } from 'react';

import styled from 'styled-components';

import { YahooPlaceBusiness } from 'models/Domain/YahooPlace/Business';

import { BooleanAttribute } from './YahooPlaceContents';

type Props = {
  business: YahooPlaceBusiness;
};

const getLabel = YahooPlaceBusiness.getKeyLabel;

const YahooPlaceBusinessList: React.FC<Props> = ({ business }) => {
  const {
    studentDiscount,
    reservationFlag,
    deliveryServiceFlag,
    seatCapacity,
    seatCapacityFlag,
    isPrivateRoomAvailable,
    isEntireReservationAvailable,
    smokingRuleType,
    parkingAreaFlag,
    otherServiceDescription,
    coinParkingAreaFlag,
    barrierFreeFlag,
    childFriendlyFlag,
    kidsSpaceFlag,
    petFlag,
    infectionControlSanitaryMeasuresTakenFlag,
  } = business;

  const reservationText = useMemo(() => {
    const { reservationFlag } = business;
    let result = getLabel('reservationFlag');
    const options = business.getAvailableOptions(YahooPlaceBusiness.reservationOptions);
    if (reservationFlag && options.length > 0) {
      result = `${result}（${options.join('／')}）`;
    }
    return result;
  }, [business]);

  const seatText = useMemo(() => {
    let result = '座席あり';
    if (seatCapacity) {
      result = `座席あり（${seatCapacity}席）`;
    }
    return result;
  }, [seatCapacity]);

  const privateRoomText = useMemo(() => {
    const { isPrivateRoomAvailable } = business;
    let result = getLabel('isPrivateRoomAvailable');
    const options = business.getAvailableOptions(YahooPlaceBusiness.privateRoomOptions);
    if (isPrivateRoomAvailable && options.length > 0) {
      result = `${result}（${options.join('／')}）`;
    }
    return result;
  }, [business]);

  const entireReservationText = useMemo(() => {
    const { isEntireReservationAvailable } = business;
    let result = getLabel('isEntireReservationAvailable');
    const options = business.getAvailableOptions(YahooPlaceBusiness.entireReservationOptions);
    if (isEntireReservationAvailable && options.length > 0) {
      result = `${result}（${options.join('／')}）`;
    }
    return result;
  }, [business]);

  const parkingAreaText = useMemo(() => {
    const { parkingAreaFlag, parkingCapacity } = business;
    let result = getLabel('parkingAreaFlag');
    if (parkingAreaFlag && parkingCapacity) {
      result = `${result}（${parkingCapacity}台）`;
    }
    return result;
  }, [business]);

  const smokingRuleOptions = useMemo(() => {
    if (business.smokingRuleType != '2') {
      return [];
    }
    return business.getAvailableOptions(YahooPlaceBusiness.smokingRuleOptions);
  }, [business]);

  const infectionControlOptions = useMemo(() => {
    if (!business.infectionControlSanitaryMeasuresTakenFlag) {
      return [];
    }
    const result: string[] = business.getAvailableOptions(YahooPlaceBusiness.infectionControlOptions);
    if (business.infectionControlRemarks) {
      result.push(business.infectionControlRemarks);
    }
    return result;
  }, [business]);

  // 「その他の施設情報」以外の大項目の値が設定されているか
  const showFeatures = useMemo(() => {
    return YahooPlaceBusiness.featureKeys.some((key) => business.get(key) != null);
  }, [business]);

  // 施設情報がひとつも設定されていない場合は「未設定」と表示する
  if (!business.hasValue) {
    return <Wrapper>未設定</Wrapper>;
  }

  return (
    <Wrapper>
      <FeatureWrapper visible={showFeatures}>
        <BooleanAttribute value={studentDiscount} label={getLabel('studentDiscount')} />
        <BooleanAttribute value={reservationFlag} label={reservationText} />
        <BooleanAttribute value={deliveryServiceFlag} label={getLabel('deliveryServiceFlag')} />
        <BooleanAttribute value={seatCapacityFlag} label={seatText} />
        <BooleanAttribute value={isPrivateRoomAvailable} label={privateRoomText} />
        <BooleanAttribute value={isEntireReservationAvailable} label={entireReservationText} />
        <BooleanAttribute
          value={smokingRuleType ? true : undefined}
          label={smokingRuleType ? YahooPlaceBusiness.getSmokingRuleTypeLabel(smokingRuleType) : ''}
        />
        {smokingRuleOptions.length > 0 && (
          <OptionsWrapper>
            {smokingRuleOptions.map((option) => (
              <Text key={option}>{option}</Text>
            ))}
          </OptionsWrapper>
        )}
        <BooleanAttribute value={parkingAreaFlag} label={parkingAreaText} />
        <BooleanAttribute value={coinParkingAreaFlag} label={getLabel('coinParkingAreaFlag')} />
        <BooleanAttribute value={barrierFreeFlag} label={getLabel('barrierFreeFlag')} />
        <BooleanAttribute value={childFriendlyFlag} label={getLabel('childFriendlyFlag')} />
        <BooleanAttribute value={kidsSpaceFlag} label={getLabel('kidsSpaceFlag')} />
        <BooleanAttribute value={petFlag} label={getLabel('petFlag')} />
        <BooleanAttribute
          value={infectionControlSanitaryMeasuresTakenFlag}
          label={getLabel('infectionControlSanitaryMeasuresTakenFlag')}
        />
        {infectionControlOptions.length > 0 && (
          <OptionsWrapper>
            {infectionControlOptions.map((option) => (
              <Text key={option}>{option}</Text>
            ))}
          </OptionsWrapper>
        )}
      </FeatureWrapper>
      {otherServiceDescription && (
        <ItemWrapper>
          <Heading>{getLabel('otherServiceDescription')}</Heading>
          <Text>{otherServiceDescription}</Text>
        </ItemWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FeatureWrapper = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ItemWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Heading = styled.div`
  font-weight: bold;
`;

const Text = styled.div``;

const OptionsWrapper = styled.div`
  background: #f2f2f2;
  padding: 12px 16px;
`;

export default React.memo(YahooPlaceBusinessList);
