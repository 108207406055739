import React, { useMemo } from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

import VirtualizedDropdown from './CategoryPullDown';

export const EditGmbCategoryList: React.FC<{
  storeForEdit: Store;
  categoryList: GmbCategories;
  changeStore: (v: Store) => void;
  showService: boolean;
  isLoadingCategory: boolean;
}> = ({ storeForEdit, changeStore, categoryList, showService, isLoadingCategory }) => {
  const { location } = storeForEdit;

  const { isValid, error } = storeForEdit.validate().primaryCategory.isValid
    ? storeForEdit.validate().additionalCategories
    : storeForEdit.validate().primaryCategory;

  const categoryOptions = useMemo(() => {
    return categoryList.generateCategoryOption();
  }, [categoryList]);

  if (isLoadingCategory) {
    return (
      <Wrapper>
        <LabelWrapper>
          <Label>カテゴリー{showService ? '（Googleビジネスプロフィール）' : ''}</Label>
          {showService && <ServiceIcon.Google />}
        </LabelWrapper>
        <LoadingWrapper>
          <Loader active={true} size={'big'} inline={true} label={'カテゴリを取得中'} />
        </LoadingWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>カテゴリー{showService ? '（Googleビジネスプロフィール）' : ''}</Label>
        {showService && <ServiceIcon.Google />}
      </LabelWrapper>
      {!isValid && <Error>{error}</Error>}
      <ContentWrapper>
        メインカテゴリー
        <Category
          value={location.primaryCategory.categoryId}
          items={categoryOptions}
          placeholder={'カテゴリー'}
          onSelect={(value) => {
            changeStore(storeForEdit.changePrimaryCategory(value));
          }}
        />
      </ContentWrapper>
      <ContentWrapper>
        追加のカテゴリー
        {location.additionalCategories.list.map((additionalCategory, idx) => {
          return (
            <AdditionalCategoryWrapper key={idx}>
              <Category
                value={additionalCategory.categoryId}
                items={categoryOptions}
                placeholder={'カテゴリー'}
                onSelect={(value) => {
                  changeStore(storeForEdit.changeAdditionalCategory(idx, value));
                }}
              />
              <XIcon
                onClick={() => {
                  changeStore(storeForEdit.removeAdditionalCategory(idx));
                }}
              />
            </AdditionalCategoryWrapper>
          );
        })}
        <AddWrapper>
          {location.additionalCategories.list.size < 9 && (
            <AddButton onClick={() => changeStore(storeForEdit.addAdditionalCategory())}>
              <AddIcon />
              <AddCategory>カテゴリーを追加</AddCategory>
            </AddButton>
          )}
        </AddWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Label = styled.label`
  font-weight: bold;
`;

const XIcon = styled(Icon).attrs({ name: 'x' })`
  &&& {
    margin-left: 16px;
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
`;

const AddWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const AddCategory = styled.div`
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const AdditionalCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Category = styled(VirtualizedDropdown)`
  &&& {
    margin-top: 8px;
    width: 100%;
  }
`;

const Error = styled.div`
  margin: 16px 0;
  color: ${COLOR.RED};
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const AddIcon = styled(Icon).attrs({ name: 'plus' })`
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.GRAY};
  margin-top: 16px;
  height: 200px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
