import React from 'react';

import { List } from 'immutable';
import { Icon, Modal, Radio } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { AttributesForm } from 'components/molecules/GmbAttributes/AttributesForm';
import { AttributesUrlForm } from 'components/molecules/GmbAttributes/AttributesUrlForm';
import { SNSLinkForm } from 'components/molecules/GmbAttributes/SNSLinkForm';
import { EditMoreHours } from 'components/organisms/EditMoreHours';
import { EditRegularHours } from 'components/organisms/EditRegularHours';
import { EditGmbCategoryList } from 'components/pageComponents/StoreDetail/EditGmbCategoryList';
import { EditSpecialHours } from 'components/pageComponents/StoreDetail/EditSpecialHours';
import { administrativeAreaOptions, trimForGmbText } from 'helpers/utils';
import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { openInfoOptions } from 'models/Domain/GmbLocation/GmbOpenInfo';
import {
  LOCATION_NAME_KANA_MAX_LENGTH,
  MAX_LENGTH as PROFILE_DESCRIPTION_MAX_LENGTH,
} from 'models/Domain/GmbLocation/GmbProfile';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { Store } from 'models/Domain/Store';
import { YahooPlaceCategories } from 'models/Domain/YahooPlace/Category';
import { COLOR } from 'style/color';

import { StyledCheckbox } from '../StoreIndex/StoreTable';

import { EditOpeningDate } from './EditOpeningDate';
import EditYahooPlaceBusiness from './EditYahooPlaceBusiness';
import { EditYahooPlaceCategories } from './EditYahooPlaceCategories';
import EditYahooPlacePayment from './EditYahooPlacePayment';

export type EditStoreItemModalType =
  | 'code'
  | 'name'
  | 'nameKana'
  | 'phone'
  | 'websiteUrl'
  | 'regularHours'
  | 'specialHours'
  | 'category'
  | 'yahooCategory'
  | 'address'
  | 'openInfo'
  | 'openingDate'
  | 'profile'
  | 'attributes'
  | 'urlAttributes'
  | 'snsLink'
  | 'moreHours'
  | 'yahooPayment'
  | 'yahooBusiness';

export const EditStoreItemModal = React.memo<{
  isOpen: boolean;
  type: EditStoreItemModalType;
  store: Store;
  storeForEdit: Store;
  categoryList: GmbCategories;
  yahooPlaceCategories: YahooPlaceCategories;
  attributeMetadatas: GmbAttributeMetadatas;
  availableUrlTypes: GbpAvailableUrlTypes;
  onClose: () => void;
  changeStore: (v: Store) => void;
  updateStore: () => void;
}>(
  ({
    isOpen,
    type,
    store,
    storeForEdit,
    onClose,
    changeStore,
    updateStore,
    categoryList,
    yahooPlaceCategories,
    attributeMetadatas,
    availableUrlTypes,
  }) => {
    const validateResult = storeForEdit.validate();
    const warning = storeForEdit.getWarning();
    const { location } = storeForEdit;
    // Yahoo!プレイス連携店舗の編集中の営業時間のエラーメッセージ
    const yplaceRegularHoursError =
      storeForEdit.isConnectedYahooPlace && storeForEdit.location.regularHours.errorForYahooPlace;
    // Yahoo!プレイス連携店舗の編集中の特別営業時間のエラーメッセージ
    const yplaceSpecialHoursError =
      storeForEdit.isConnectedYahooPlace && storeForEdit.location.specialHours.errorForYahooPlace;
    // 2つ以上のサービスに接続しているかを判定する
    const numberOfConnectedService = [store.isConnectedGBP, store.isConnectedYahooPlace].filter((v) => v).length;
    // 流入パラメータの設定が存在するかを判定する
    const websiteUrlParamExistsWithConnected =
      !!location.connectedServices.gbp.websiteUrlParam || !!location.connectedServices.yahooPlace.websiteUrlParam;
    const [showUrlParamSetting, setShowUrlParamSetting] = React.useState<boolean>(
      numberOfConnectedService >= 2 && websiteUrlParamExistsWithConnected,
    );

    const isLoadingGbpCategories = categoryList.list.isEmpty();
    const isLoadingYahooPlaceCategories = yahooPlaceCategories.list.isEmpty();

    let isValid = false;
    switch (type) {
      case 'openInfo':
        isValid = validateResult.code.isValid;
        break;
      case 'openingDate':
        isValid = validateResult.openingDate.isValid;
        break;
      case 'code':
        isValid = validateResult.code.isValid;
        break;
      case 'name':
        isValid = validateResult.name.isValid && validateResult.branch.isValid;
        break;
      case 'nameKana':
        isValid = validateResult.locationNameKana.isValid;
        break;
      case 'phone':
        isValid =
          validateResult.primaryPhone.isValid &&
          validateResult.additionalPhones.filter((validate) => !validate.isValid).length === 0;
        break;
      case 'websiteUrl':
        isValid =
          validateResult.websiteUrl.isValid &&
          validateResult.websiteUrlGbpParam.isValid &&
          validateResult.websiteUrlYplaceParam.isValid;
        break;
      case 'regularHours':
        isValid = validateResult.businessHours.isValid && !yplaceRegularHoursError;
        break;
      case 'specialHours':
        isValid = validateResult.specialHours.isValid && !yplaceSpecialHoursError;
        break;
      case 'address':
        isValid =
          validateResult.postalCode.isValid &&
          validateResult.administrativeArea.isValid &&
          validateResult.address1.isValid &&
          validateResult.address2.isValid;
        break;
      case 'category':
        isValid =
          validateResult.primaryCategory.isValid &&
          validateResult.additionalCategories.isValid &&
          !isLoadingGbpCategories;
        break;
      case 'yahooCategory':
        isValid = validateResult.yahooPlaceCategory.isValid && !isLoadingYahooPlaceCategories;
        break;
      case 'profile':
        isValid = validateResult.profile.isValid;
        break;
      case 'attributes':
        isValid = validateResult.attributes.isValid;
        break;
      case 'urlAttributes':
        isValid = validateResult.urlAttributes.isValid;
        break;
      case 'snsLink':
        isValid = validateResult.snsLink.isValid;
        break;
      case 'moreHours':
        isValid = validateResult.moreHours.isValid;
        break;
      case 'yahooPayment':
        isValid = validateResult.yahooPlacePayment.isValid;
        break;
      case 'yahooBusiness':
        isValid = validateResult.yahooPlaceBusiness.isValid;
        break;
      default:
        return type satisfies never;
    }

    return (
      <Modal size='small' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Content>
          {type === 'openInfo' && (
            <ItemWrapper>
              <ItemLabel>ステータス</ItemLabel>
              <EditContentWrapper>
                <RadioItemWrapper>
                  <Radio
                    value={openInfoOptions.OPEN.value}
                    name='openInfo'
                    label={openInfoOptions.OPEN.name}
                    checked={storeForEdit.location.openInfo.status === openInfoOptions.OPEN.value}
                    onChange={(e) => changeStore(storeForEdit.changeOpenInfoStatus(openInfoOptions.OPEN.value))}
                  />
                </RadioItemWrapper>
                <RadioItemWrapper>
                  <Radio
                    value={openInfoOptions.CLOSED_PERMANENTLY.value}
                    name='openInfo'
                    label={openInfoOptions.CLOSED_PERMANENTLY.name}
                    checked={storeForEdit.location.openInfo.status === openInfoOptions.CLOSED_PERMANENTLY.value}
                    onChange={(e) =>
                      changeStore(storeForEdit.changeOpenInfoStatus(openInfoOptions.CLOSED_PERMANENTLY.value))
                    }
                  />
                </RadioItemWrapper>
                <RadioItemWrapper>
                  <Radio
                    value={openInfoOptions.CLOSED_TEMPORARILY.value}
                    name='openInfo'
                    label={openInfoOptions.CLOSED_TEMPORARILY.name}
                    checked={storeForEdit.location.openInfo.status === openInfoOptions.CLOSED_TEMPORARILY.value}
                    onChange={(e) =>
                      changeStore(storeForEdit.changeOpenInfoStatus(openInfoOptions.CLOSED_TEMPORARILY.value))
                    }
                  />
                </RadioItemWrapper>
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'openingDate' && <EditOpeningDate changeStore={changeStore} storeForEdit={storeForEdit} />}
          {type === 'code' && (
            <ItemWrapper>
              <ItemLabel>店舗コード</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={storeForEdit.code}
                  error={!validateResult.code.isValid && validateResult.code.error}
                  onChange={(value) => changeStore(storeForEdit.changeCode(value))}
                />
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'name' && (
            <ItemWrapper>
              <ItemLabel>店名</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={storeForEdit.name}
                  error={!validateResult.name.isValid && validateResult.name.error}
                  onChange={(value: string) => changeStore(storeForEdit.changeName(value))}
                />
                {store.isConnectedYahooPlace && <StyledTextCount size={storeForEdit.name.length} />}
              </EditContentWrapper>

              <ItemLabel>支店名</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={storeForEdit.branch}
                  error={!validateResult.branch.isValid && validateResult.branch.error}
                  onChange={(value: string) => changeStore(storeForEdit.changeBranchName(value))}
                />
                {store.isConnectedYahooPlace && <StyledTextCount size={storeForEdit.branch.length} />}
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'nameKana' && (
            <ItemWrapper>
              <ItemLabel>店舗名（フリガナ）</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={location.locationNameKana}
                  error={!validateResult.locationNameKana.isValid && validateResult.locationNameKana.error}
                  onChange={(value) => changeStore(storeForEdit.changeNameKana(value))}
                  onBlur={() => changeStore(storeForEdit.changeNameKana(trimForGmbText(location.locationNameKana)))}
                />
                <StyledTextCount size={location.locationNameKana.length} maxSize={LOCATION_NAME_KANA_MAX_LENGTH} />
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'phone' && (
            <ItemWrapper>
              <ItemLabel>電話番号</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={location.primaryPhone}
                  error={!validateResult.primaryPhone.isValid && validateResult.primaryPhone.error}
                  onChange={(value) => changeStore(storeForEdit.changePrimaryPhone(value))}
                  placeholder='電話番号'
                />
              </EditContentWrapper>
              {location.primaryPhone &&
                location.additionalPhonesForEdit.map((additionalPhone, index) => {
                  return (
                    <EditContentWrapper key={index}>
                      <Input
                        value={additionalPhone}
                        error={
                          !validateResult.additionalPhones[index].isValid &&
                          validateResult.additionalPhones[index].error
                        }
                        onChange={(value) => changeStore(storeForEdit.changeAdditionalPhones(index, value))}
                        placeholder='追加の電話番号'
                      />
                    </EditContentWrapper>
                  );
                })}
              {warning.phone && (
                <WarningContainer>
                  <Icon name='warning sign' />
                  {warning.phone}
                </WarningContainer>
              )}
            </ItemWrapper>
          )}
          {type === 'websiteUrl' && (
            <ItemWrapper>
              <ItemLabel>ウェブサイト</ItemLabel>
              <EditContentWrapper>
                <Input
                  value={location.websiteUrl}
                  error={!validateResult.websiteUrl.isValid && validateResult.websiteUrl.error}
                  onChange={(value) => changeStore(storeForEdit.changeWebsiteUrl(value))}
                  placeholder={'例 https://storecast.io'}
                />
                {(numberOfConnectedService >= 2 ||
                  (numberOfConnectedService === 1 && websiteUrlParamExistsWithConnected)) && (
                  <>
                    <CheckBoxItemWrapper>
                      <StyledCheckboxForEditStoreItemModal
                        checked={showUrlParamSetting}
                        onChange={() => {
                          if (
                            [
                              location.connectedServices.gbp.websiteUrlParam,
                              location.connectedServices.yahooPlace.websiteUrlParam,
                            ].some((v) => v !== '') &&
                            showUrlParamSetting
                          ) {
                            if (window.confirm('設定を無効にすると入力内容が初期化されますが、よろしいですか？')) {
                              changeStore(storeForEdit.initiateWebSiteUrlParams());
                            } else {
                              return;
                            }
                          }
                          setShowUrlParamSetting((prev) => !prev);
                        }}
                        label='サービスごとに流入パラメータを設定'
                      />
                      <ContextHelp
                        header={'流入パラメータ設定'}
                        content={
                          <>
                            サービスごとに異なるパラメータを設定できます。ウェブサイトにもパラメータが設定されている場合、名前が同じパラメータはサービスごとに設定されているもので上書きします。
                          </>
                        }
                      />
                    </CheckBoxItemWrapper>
                    {showUrlParamSetting && (
                      <>
                        {store.isConnectedGBP && (
                          <div>
                            <PaddingForEachInput />
                            <ItemLabel>流入パラメータ (Googleビジネスプロフィール)</ItemLabel>
                            <PaddingForEachInput />
                            <Input
                              value={location.connectedServices.gbp.websiteUrlParam}
                              error={
                                !validateResult.websiteUrlGbpParam.isValid && validateResult.websiteUrlGbpParam.error
                              }
                              onChange={(value) => changeStore(storeForEdit.changeWebsiteUrlGbpParam(value))}
                              placeholder={'utm_source=source&utm_medium=medium&utm_campaign=campaign'}
                            />
                          </div>
                        )}
                        {store.isConnectedYahooPlace && (
                          <div>
                            <PaddingForEachInput />
                            <ItemLabel>流入パラメータ (Yahoo! プレイス)</ItemLabel>
                            <PaddingForEachInput />
                            <Input
                              value={location.connectedServices.yahooPlace.websiteUrlParam}
                              error={
                                !validateResult.websiteUrlYplaceParam.isValid &&
                                validateResult.websiteUrlYplaceParam.error
                              }
                              onChange={(value) => changeStore(storeForEdit.changeWebsiteUrlYplaceParam(value))}
                              placeholder={'utm_source=source&utm_medium=medium&utm_campaign=campaign'}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'address' && (
            <ItemWrapper>
              <ItemLabel>所在地</ItemLabel>
              <EditContentWrapper>
                <FlexWrapper>
                  <LeftContent>
                    <ItemLabel>郵便番号</ItemLabel>
                    <EditContentWrapper>
                      <Input
                        value={location.address.postalCode}
                        error={!validateResult.postalCode.isValid && validateResult.postalCode.error}
                        onChange={(value) => changeStore(storeForEdit.changePostalCode(value))}
                      />
                    </EditContentWrapper>
                  </LeftContent>
                  <RightContent>
                    <ItemLabel>都道府県</ItemLabel>
                    <EditContentWrapper>
                      <SmallPullDown
                        value={location.address.administrativeArea}
                        options={administrativeAreaOptions}
                        placeholder={'都道府県'}
                        error={!validateResult.administrativeArea.isValid && validateResult.administrativeArea.error}
                        onChange={(value) => changeStore(storeForEdit.changeAdministrativeArea(value))}
                      />
                    </EditContentWrapper>
                  </RightContent>
                </FlexWrapper>
                <ItemLabel>住所1</ItemLabel>
                <EditContentWrapper>
                  <Input
                    value={location.address.address1}
                    error={!validateResult.address1.isValid && validateResult.address1.error}
                    onChange={(value) => changeStore(storeForEdit.changeAddress1(value))}
                  />
                </EditContentWrapper>
                <ItemLabel>住所2</ItemLabel>
                <EditContentWrapper>
                  <Input
                    value={location.address.address2}
                    error={!validateResult.address2.isValid && validateResult.address2.error}
                    onChange={(value) => changeStore(storeForEdit.changeAddress2(value))}
                  />
                </EditContentWrapper>
              </EditContentWrapper>
              <EditContentWrapper>
                <Icon name='info circle' /> 住所を変更後、緯度経度の反映に1日程度時間がかかる場合があります。
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'regularHours' && (
            <>
              <EditRegularHours
                regularHours={storeForEdit.location.regularHours}
                changeRegularHours={(value: GmbRegularHours) => {
                  changeStore(storeForEdit.changeRegularHours(value));
                }}
              />
              {
                // Yahoo!プレイス連携店舗の編集中の営業時間のエラーメッセージ
                yplaceRegularHoursError && (
                  <Error>
                    <StyledIcon name='exclamation circle' />
                    {yplaceRegularHoursError}
                  </Error>
                )
              }
              {warning.regularHours && (
                <WarningContainer>
                  <StyledIcon name='warning sign' />
                  {warning.regularHours}
                </WarningContainer>
              )}
            </>
          )}
          {type === 'moreHours' && (
            <EditMoreHours
              moreHours={storeForEdit.location.moreHours}
              moreHoursTypes={storeForEdit.location.availableMoreHoursTypes}
              changeMoreHours={(value) => {
                changeStore(storeForEdit.changeMoreHours(value));
              }}
            />
          )}
          {type === 'specialHours' && (
            <>
              <EditSpecialHours changeStore={changeStore} storeForEdit={storeForEdit} />
              {
                // Yahoo!プレイス連携店舗の編集中の特別営業時間のエラーメッセージ
                yplaceSpecialHoursError && (
                  <Error>
                    <StyledIcon name='exclamation circle' />
                    {yplaceSpecialHoursError}
                  </Error>
                )
              }
              {warning.specialHours && (
                <WarningContainer>
                  <StyledIcon name='warning sign' />
                  {warning.specialHours}
                </WarningContainer>
              )}
            </>
          )}
          {type === 'category' && (
            <EditGmbCategoryList
              categoryList={categoryList}
              changeStore={changeStore}
              storeForEdit={storeForEdit}
              showService={numberOfConnectedService >= 2}
              isLoadingCategory={isLoadingGbpCategories}
            />
          )}
          {type === 'yahooCategory' && (
            <EditYahooPlaceCategories
              categoryList={yahooPlaceCategories}
              changeStore={changeStore}
              storeForEdit={storeForEdit}
              showService={numberOfConnectedService >= 2}
              isLoadingCategory={isLoadingYahooPlaceCategories}
            />
          )}
          {type === 'attributes' && (
            <ItemWrapper>
              <ItemLabel>属性</ItemLabel>
              <EditContentWrapper>
                <AttributesForm
                  attributeMetadatas={attributeMetadatas}
                  attributes={storeForEdit.location.attributes}
                  individualUpdate={false}
                  setAttributes={(attributes) => changeStore(storeForEdit.changeAttributes(attributes))}
                  targetAttributeIds={List()}
                  setTargetAttributeIds={() => {
                    /* 何もしない */
                  }}
                />
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'snsLink' && (
            <ItemWrapper>
              <EditContentWrapper>
                <SNSLinkForm
                  storeForEdit={storeForEdit}
                  attributeMetadatas={attributeMetadatas}
                  attributes={storeForEdit.location.urlAttributes}
                  yahooPlaceSNSLink={storeForEdit.location.connectedServices.yahooPlace.snsLink}
                  onChangeStore={changeStore}
                  isConnectedGbp={storeForEdit.isConnectedGBP}
                  isConnectedYahooPlace={storeForEdit.isConnectedYahooPlace}
                />
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'urlAttributes' && (
            <ItemWrapper>
              <EditContentWrapper>
                <AttributesUrlForm
                  attributeMetadatas={attributeMetadatas}
                  attributes={storeForEdit.location.urlAttributes}
                  availableUrlTypes={availableUrlTypes}
                  setAttributes={(attributes) => changeStore(storeForEdit.changeUrlAttributes(attributes))}
                  canModifyUrlAppointment={store.locationState.canModifyUrlAppointment}
                  canModifyUrlReservations={store.locationState.canModifyUrlReservations}
                />
              </EditContentWrapper>
            </ItemWrapper>
          )}
          {type === 'profile' && (
            <ItemWrapper>
              <ItemLabel>店舗の説明</ItemLabel>
              <EditContentWrapper>
                <StyledTextArea
                  value={location.profile.description}
                  onChange={(value) => changeStore(storeForEdit.changeProfileDescription(value))}
                  placeholder='店舗の説明'
                  error={!validateResult.profile.isValid ? validateResult.profile.error : false}
                />
                <StyledTextCount size={location.profile.description.length} maxSize={PROFILE_DESCRIPTION_MAX_LENGTH} />
              </EditContentWrapper>
              {warning.profile && (
                <WarningContainer>
                  <Icon name='warning sign' />
                  {warning.profile}
                </WarningContainer>
              )}
            </ItemWrapper>
          )}
          {type === 'yahooPayment' && (
            <EditYahooPlacePayment
              payment={location.connectedServices.yahooPlace.payment}
              onChange={(value) => changeStore(storeForEdit.changeYahooPlacePayment(value))}
            />
          )}
          {type === 'yahooBusiness' && (
            <EditYahooPlaceBusiness
              business={location.connectedServices.yahooPlace.business}
              onChange={(value) => changeStore(storeForEdit.changeYahooPlaceBusiness(value))}
            />
          )}
        </Modal.Content>
        <ButtonWrapper>
          <CloseButton onClick={() => onClose()}>キャンセル</CloseButton>
          <ApplyButton priority='high' onClick={() => updateStore()} disabled={!isValid}>
            適用
          </ApplyButton>
        </ButtonWrapper>
      </Modal>
    );
  },
);

const SmallPullDown = styled(PullDown)`
  &&& {
    & > .ui.selection.dropdown {
      padding: 17px 16px;
      min-height: 53px;
      & > input {
        padding: 17px 16px !important;
      }
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const LeftContent = styled.div`
  padding-right: 16px;
`;

const RightContent = styled.div``;

const ItemWrapper = styled.div``;

const RadioItemWrapper = styled.div`
  margin-top: 8px;
`;

const ItemLabel = styled.label`
  color: ${COLOR.BLACK};
  display: block;
  margin-top: 8px;
  font-weight: bold;
`;

const EditContentWrapper = styled.div`
  margin-top: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const ButtonCommonStyle = css`
  max-width: 150px;
  width: calc(50% - 10px);
  padding: 17px 8px;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle};
    margin-left: 20px;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const WarningContainer = styled.div`
  font-size: 14px;
  margin-top: 30px;
  border-color: ${COLOR.WARNING};
  color: ${COLOR.WARNING};
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
  top: 1px;
`;

const Error = styled.div`
  margin: 16px 0;
  font-size: 14px;
  color: ${COLOR.ERROR};
`;

const StyledCheckboxForEditStoreItemModal = styled(StyledCheckbox)`
  &&& {
    line-height: 25px;
    label {
      &:after {
        top: 0px;
      }
    }
  }
`;

const PaddingForEachInput = styled.div`
  margin-top: 16px;
`;

const CheckBoxItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding-top: 16px;
`;
