import React from 'react';

import * as Sentry from '@sentry/browser';
import { Amplify } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import CoreJS from 'core-js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { AppContainer } from 'react-hot-loader';
import 'dayjs/locale/ja';
import { Provider } from 'react-redux';
import { z } from 'zod';

import CONFIG from 'config';
import configureStore, { history } from 'configureStore';
import routes from 'routes';
import * as serviceWorker from 'serviceWorker';
import { GlobalStyle } from 'style/GlobalStyle';

import { customErrorMap } from './helpers/schema';

dayjs.locale('ja');
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

z.setErrorMap(customErrorMap);

Amplify.configure(CONFIG.AWS_CONFIG);
Sentry.init({
  dsn: CONFIG.SENTRY_DATA_SOURCE_NAME,
  environment: CONFIG.ENVIRONMENT,
  ignoreErrors: CONFIG.SENTRY_IGNORE_ERRORS,
});

const store = configureStore();

// FIXME: babelの設定上はimportのみで十分そうだが、この行を追加しないと動かない
// eslint-disable-next-line no-unused-expressions
CoreJS;

const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
    <AppContainer>
      <HelmetProvider>
        <GlobalStyle />
        <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      </HelmetProvider>
    </AppContainer>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
