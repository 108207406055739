import actionCreatorFactory from 'typescript-fsa';

import YahooPlaceAuth from 'models/Domain/YahooPlace/Auth';
import { YahooPlaceCategories } from 'models/Domain/YahooPlace/Category';
import { GetPlacesResponse } from 'types/responses/YahooPlace';
import { GetAccountsResponse } from 'types/responses/YahooPlaceAccount';

const actionCreator = actionCreatorFactory('YahooPlaceAccount');

export const YahooPlaceActions = {
  getAccounts: actionCreator('getAccounts'),
  setAccounts: actionCreator<GetAccountsResponse>('setAccounts'),
  registerYahooPlaceAccount: actionCreator<{
    account_id: number | null;
    name: string;
    redirect_uri: string;
    state: string;
    code: string;
  }>('registerYahooPlaceAccount'),
  getPlaces: actionCreator('getPlaces'),
  setPlaces: actionCreator<GetPlacesResponse>('setPlaces'),
  connect: actionCreator<{ storeId: number; accountId: number; placeSeq: number }>('connect'),
  disconnect: actionCreator<number>('disconnect'),
  import: actionCreator<{ storeId: number; accountId: number; placeSeq: number }>('import'),
  changeRegisterYahooPlaceAccountName: actionCreator<string>('changeRegisterYahooPlaceAccountName'),
  clearRegisterYahooPlaceAccount: actionCreator('clearRegisterYahooPlaceAccount'),
  setYahooPlaceAuth: actionCreator<YahooPlaceAuth>('setYahooPlaceAuth'),
  clearYahooPlaceAuth: actionCreator('clearYahooPlaceAuth'),
  deleteAccount: actionCreator<number>('deleteAccount'),
  setCategories: actionCreator<YahooPlaceCategories>('setCategories'),
};
