import React from 'react';

import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';

// 店舗詳細画面のYahoo!プレイス系情報で共通で使用するコンポーネント

export const BooleanAttribute = React.memo<{
  className?: string;
  value: boolean | undefined;
  label: string;
  showEmpty?: boolean;
}>(({ className, value, label, showEmpty = false }) => {
  const iconName = value == null ? 'dash' : value ? 'check' : 'cancel_red';
  const invisibleText = value == null ? '- ' : value ? '✔︎ ' : '× ';
  if (!showEmpty && value == null) {
    return null;
  }

  return (
    <ItemWrapper className={className}>
      <AttributeIcon type={iconName} />
      <InvisibleText>{invisibleText}</InvisibleText>
      {label}
    </ItemWrapper>
  );
});

const ItemWrapper = styled.div``;

const AttributeIcon = styled(Icon)`
  padding: 0 2px 2px 0;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
`;

const InvisibleText = styled.span`
  font-size: 0;
  color: transparent;
`;
