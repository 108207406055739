import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ItemProps, ListProps, Virtuoso } from 'react-virtuoso';
import { bindActionCreators } from 'redux';
import { Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { EditableCell } from 'components/atoms/BulkEditStores/EditableCell';
import { RegularHours } from 'components/atoms/BulkEditStores/RegularHours';
import { Button } from 'components/atoms/Button';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { CellAttributes } from 'components/molecules/StoreBuisnesInfo/CellAttributes';
import { BulkEditAttributesModal } from 'components/pageComponents/BulkEditStores/BulkEditAttributesModal';
import { EditSpecialHoursModal } from 'components/pageComponents/BulkEditStores/BulkEditSpecialHoursModal';
import { EditProfileModal } from 'components/pageComponents/BulkEditStores/EditProfileModal';
import { EditRegularHours } from 'components/pageComponents/BulkEditStores/EditRegularHours';
import { StoreBusinessInfoFooter } from 'components/pageComponents/BulkEditStores/StoreBusinessInfoFooter';
import { getPageTitle } from 'helpers/utils';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { OpenInfoStatus, selectOpenInfoOptions } from 'models/Domain/GmbLocation/GmbOpenInfo';
import { GmbProfile } from 'models/Domain/GmbLocation/GmbProfile';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { GmbSpecialHours } from 'models/Domain/GmbLocation/GmbSpecialHours';
import { DayOfWeek, GmbTimePeriods } from 'models/Domain/GmbLocation/GmbTimePeriod';
import { Store } from 'models/Domain/Store';
import { AppActions } from 'modules/app/actions';
import { BulkEditStoresActions } from 'modules/bulkEditStores/actions';
import { GmbActions } from 'modules/gmb/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type RegularHoursColumnType = DayOfWeek;
const regularHoursOrder: RegularHoursColumnType[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

type ColumnType = RegularHoursColumnType | 'SPECIAL_HOURS' | 'ATTRIBUTES' | 'PROFILE';
type CellType = {
  row: number;
  column: ColumnType;
};

// クリップボード情報
// コピーできる情報をdataTypeとdataの組み合わせで定義する
type Clipboard =
  | { cell: CellType; dataType: 'TIME_PERIODS'; data: GmbTimePeriods }
  | { cell: CellType; dataType: 'SPECIAL_HOURS'; data: GmbSpecialHours }
  | { cell: CellType; dataType: 'ATTRIBUTES'; data: GmbAttributes; categoryId: string }
  | { cell: CellType; dataType: 'PROFILE'; data: GmbProfile };

export const BulkEditStores: React.FC = () => {
  const dispatch = useDispatch();

  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const {
    initialize,
    changeOpenInfo,
    changeSpecialHours,
    bulkChangeSpecialHours,
    changeRegularHours,
    changeAttributes,
    bulkChangeAttributes,
    updateBulkEditBusinessInfo,
    changeProfile,
    bulkChangeProfile,
  } = useMemo(() => bindActionCreators(BulkEditStoresActions, dispatch), [dispatch]);

  const { getGmbCategoryList } = useMemo(() => bindActionCreators(GmbActions, dispatch), [dispatch]);

  useEffect(() => {
    initialize();
    getGmbCategoryList();
  }, [getGmbCategoryList, initialize]);

  // 編集キャンセルダイアログの表示有無
  const [isCanceling, setIsCanceling] = useState(false);
  // 保存確認ダイアログの表示有無
  const [isConfirm, setIsConfirm] = useState(false);
  // 編集中のセル(行、列)
  const [editingCell, setEditingCell] = useState<CellType | null>(null);

  // 特別営業時間が編集中かどうか
  const isEditingSpecialHours = editingCell?.column === 'SPECIAL_HOURS';
  // 編集中の特別営業時間データ
  const [editingSpecialHours, setEditingSpecialHours] = useState(new GmbSpecialHours());

  // 特別営業時間が一括編集中かどうか
  const [isEditingBulkSpecialHours, setIsEditingBulkSpecialHours] = useState(false);
  // 一括編集中の特別営業時間データ
  const [editingBulkSpecialHours, setEditingBulkSpecialHours] = useState(new GmbSpecialHours());

  // 編集中の属性が紐づくカテゴリーID
  const [editingAttributesCategoryId, setEditingAttributesCategoryId] = useState<string | null>(null);
  // 編集中の属性が紐づくカテゴリー名
  const [editingAttributesCategoryName, setEditingAttributesCategoryName] = useState<string | null>(null);
  // 属性が編集中かどうか
  const isEditingAttributes = editingCell?.column === 'ATTRIBUTES';
  // 編集中の特別営業時間データ
  const [editingAttributes, setEditingAttributes] = useState(new GmbAttributes());

  // 属性が一括編集中かどうか
  const [isEditingBulkAttributes, setIsEditingBulkAttributes] = useState(false);

  // 一括編集の属性更新対象
  const [targetAttributeIds, setTargetAttributeIds] = useState<List<string>>(List());

  // 一括編集中の属性データ
  const [editingBulkAttributes, setEditingBulkAttributes] = useState(new GmbAttributes());

  // 店舗の説明が編集中かどうか
  const isEditingProfile = editingCell?.column === 'PROFILE';
  // 編集中の店舗の説明データ
  const [editingProfile, setEditingProfile] = useState(new GmbProfile());

  // 店舗の説明が一括編集中かどうか
  const [isEditingBulkProfile, setIsEditingBulkProfile] = useState(false);
  // 一括編集中の店舗の説明データ
  const [editingBulkProfile, setEditingBulkProfile] = useState(new GmbProfile());

  // クリップボード情報
  const [clipboard, setClipboard] = useState<Clipboard | null>(null);

  const bulkEditStores = useSelector((state: State) => state.bulkEditStores, shallowEqual);
  const { initialized } = bulkEditStores;
  const categoryList = useSelector((state: State) => state.gmb.categoryList, shallowEqual);

  // 編集中の店舗の説明のエイリアスを展開したデータ
  // エイリアス展開の際に文字数がGBPの規定を超えないようにするため
  const editingProfileAliasRestored = editingProfile.update('description', (description) =>
    // 編集中の店舗のエイリアスを展開
    editingCell?.row !== undefined
      ? (bulkEditStores.stores.list.get(editingCell?.row)?.getAliasRestoredText(editingProfile.description) ??
        description)
      : description,
  );

  // 一括編集中の店舗の説明のエイリアスを展開したデータ
  // エイリアス展開の際に全ての店舗で文字数がGBPの規定を超えないようにするため
  const editingBulkProfileAliasRestored = editingBulkProfile.update(
    'description',
    (description) =>
      // 最も店舗名が長い店舗のエイリアスを展開
      bulkEditStores.getStoreHavingLongestStoreName()?.getAliasRestoredText(editingBulkProfile.description) ??
      description,
  );

  useEffect(() => {
    if (bulkEditStores.stores.list.isEmpty()) {
      moveTo(Path.store.index);
    }
  }, [bulkEditStores.stores.list, moveTo]);

  /**
   * クリップボードをクリアする
   */
  const clearClipboard = useCallback(() => {
    setClipboard(null);
  }, []);

  /**
   * 営業状態を変更する
   */
  const onChangeOpenInfo = useCallback(
    (row: number, value: OpenInfoStatus) => {
      changeOpenInfo({ index: row, value });
    },
    [changeOpenInfo],
  );

  /**
   * 通常営業時間の編集の開始する
   */
  const onStartEditRegularHours = useCallback(
    (row: number, column: RegularHoursColumnType) => {
      // 当該セルがコピー状態なら解除する(ややこしいので)
      if (clipboard?.cell.row === row && clipboard?.cell.column === column) {
        clearClipboard();
      }
      setEditingCell({ row, column });
    },
    [clipboard, clearClipboard],
  );

  /**
   * 編集した通常営業時間を反映する
   */
  const onApplyRegularHours = useCallback(
    (row: number, column: RegularHoursColumnType, regularHours: GmbRegularHours) => {
      changeRegularHours({
        index: row,
        dayOfWeek: column,
        regularHours,
      });
      setEditingCell(null);
    },
    [changeRegularHours],
  );

  /**
   * 通常営業時間の編集をキャンセルする
   */
  const onCancelEditRegularHours = useCallback(() => {
    setEditingCell(null);
  }, []);

  /**
   * 特別営業時間の編集の開始する
   */
  const onStartEditSpecialHours = useCallback(
    (row: number, specialHours: GmbSpecialHours) => {
      // 当該セルがコピー状態なら解除する
      if (clipboard?.cell.row === row && clipboard?.cell.column === 'SPECIAL_HOURS') {
        clearClipboard();
      }
      setEditingCell({ row, column: 'SPECIAL_HOURS' });
      setEditingSpecialHours(specialHours);
    },
    [clipboard, clearClipboard],
  );

  /**
   * 編集した特別営業時間を反映する
   */
  const onApplySpecialHours = useCallback(() => {
    if (!editingCell) {
      return;
    }
    // 過去の日付のデータが含まれていたら除外する
    changeSpecialHours({
      index: editingCell.row,
      specialHours: editingSpecialHours.removePastDate(),
    });
    setEditingCell(null);
  }, [editingCell, editingSpecialHours, changeSpecialHours]);

  /**
   * 特別営業時間の一括編集の開始する
   */
  const onStartBulkEditSpecialHours = useCallback(() => {
    setIsEditingBulkSpecialHours(true);
    setEditingBulkSpecialHours(new GmbSpecialHours());
  }, []);

  /**
   * 編集した特別営業時間を一括反映する
   */
  const onApplyBulkSpecialHours = useCallback(() => {
    // 過去の日付のデータが含まれていたら除外する
    bulkChangeSpecialHours({ specialHours: editingBulkSpecialHours.removePastDate() });
    setIsEditingBulkSpecialHours(false);
  }, [editingBulkSpecialHours, bulkChangeSpecialHours]);

  /**
   * 属性の編集の開始する
   */
  const onStartEditAttributes = useCallback(
    (row: number, attributes: GmbAttributes, categoryId: string) => {
      // 当該セルがコピー状態なら解除する
      if (clipboard?.cell.row === row && clipboard?.cell.column === 'ATTRIBUTES') {
        clearClipboard();
      }
      setEditingCell({ row, column: 'ATTRIBUTES' });
      setEditingAttributes(attributes);
      setEditingAttributesCategoryId(categoryId);
      setEditingAttributesCategoryName(categoryList.generateDisplayName(categoryId));
    },
    [clipboard?.cell.row, clipboard?.cell.column, categoryList, clearClipboard],
  );

  /**
   * 編集した属性を反映する
   */
  const onApplyAttributes = useCallback(() => {
    if (!editingCell) {
      return;
    }
    changeAttributes({
      index: editingCell.row,
      attributes: editingAttributes,
    });
    setEditingCell(null);
    setEditingAttributesCategoryId(null);
  }, [editingCell, editingAttributes, changeAttributes]);

  /**
   * 属性の一括編集の開始する
   */
  const onStartBulkEditAttributes = useCallback(() => {
    // 対象店舗一覧で最頻出のカテゴリーID(一括編集の対象となる)
    const mostCommonCategoryId = bulkEditStores.getMostCommonCategoryId();
    if (!mostCommonCategoryId) {
      return;
    }

    setIsEditingBulkAttributes(true);
    setEditingBulkAttributes(new GmbAttributes());
    setEditingAttributesCategoryId(mostCommonCategoryId);
    setTargetAttributeIds(List());
    setEditingAttributesCategoryName(categoryList.generateDisplayName(mostCommonCategoryId));
  }, [bulkEditStores, categoryList]);

  /**
   * 編集した属性を一括反映する
   */
  const onApplyBulkAttributes = useCallback(() => {
    bulkChangeAttributes({ attributes: editingBulkAttributes, targetAttributeIds: targetAttributeIds });
    setIsEditingBulkAttributes(false);
    setEditingAttributesCategoryId(null);
  }, [editingBulkAttributes, bulkChangeAttributes, targetAttributeIds]);

  /**
   * 店舗の説明の編集の開始する
   */
  const onStartEditProfile = useCallback(
    (row: number, profile: GmbProfile) => {
      // 当該セルがコピー状態なら解除する
      if (clipboard?.cell.row === row && clipboard?.cell.column === 'PROFILE') {
        clearClipboard();
      }
      setEditingCell({ row, column: 'PROFILE' });
      setEditingProfile(profile);
    },
    [clipboard, clearClipboard],
  );

  /**
   * 編集した店舗の説明を反映する
   */
  const onApplyProfile = useCallback(() => {
    if (!editingCell) {
      return;
    }
    changeProfile({
      index: editingCell.row,
      profile: editingProfile,
    });
    setEditingCell(null);
  }, [editingCell, editingProfile, changeProfile]);

  /**
   * 店舗の説明の一括編集の開始する
   */
  const onStartBulkEditProfile = useCallback(() => {
    setIsEditingBulkProfile(true);
    setEditingBulkProfile(new GmbProfile());
  }, []);

  /**
   * 編集した店舗の説明を一括反映する
   */
  const onApplyBulkProfile = useCallback(() => {
    bulkChangeProfile({ profile: editingBulkProfile });
    setIsEditingBulkProfile(false);
  }, [editingBulkProfile, bulkChangeProfile]);

  /**
   * 通常営業時間をコピーする
   */
  const onCopyTimePeriods = useCallback((row: number, column: RegularHoursColumnType, periods: GmbTimePeriods) => {
    setClipboard({ cell: { row, column }, dataType: 'TIME_PERIODS', data: periods });
  }, []);

  /**
   * 通常営業時間をペーストする
   */
  const onPasteTimePeriods = useCallback(
    (row: number, column: RegularHoursColumnType, regularHours: GmbRegularHours) => {
      if (clipboard?.dataType !== 'TIME_PERIODS') {
        return;
      }

      const dayOfWeek = column;

      // コピーしている営業時間で指定曜日の営業時間を置き換える
      // 営業時間内のopenDay, closeDayはGmbRegularHours.changePeriods内で置き換えられる
      const updatedRegularHours = regularHours.changePeriods(dayOfWeek, clipboard.data);
      changeRegularHours({
        index: row,
        dayOfWeek,
        regularHours: updatedRegularHours,
      });
    },
    [clipboard, changeRegularHours],
  );

  /**
   * 特別営業時間をコピーする
   */
  const onCopySpecialHours = useCallback((row: number, specialHours: GmbSpecialHours) => {
    setClipboard({ cell: { row, column: 'SPECIAL_HOURS' }, dataType: 'SPECIAL_HOURS', data: specialHours });
  }, []);

  /**
   * 特別営業時間をペーストする
   */
  const onPasteSpecialHours = useCallback(
    (row: number) => {
      if (clipboard?.dataType !== 'SPECIAL_HOURS') {
        return;
      }

      changeSpecialHours({ index: row, specialHours: clipboard.data });
    },
    [clipboard, changeSpecialHours],
  );

  /**
   * 属性をコピーする
   */
  const onCopyAttributes = useCallback((row: number, attributes: GmbAttributes, categoryId: string) => {
    setClipboard({ cell: { row, column: 'ATTRIBUTES' }, dataType: 'ATTRIBUTES', data: attributes, categoryId });
  }, []);

  /**
   * 属性をペーストする
   */
  const onPasteAttributes = useCallback(
    (row: number, categoryId: string) => {
      if (clipboard?.dataType !== 'ATTRIBUTES') {
        return;
      }
      if (clipboard?.categoryId !== categoryId) {
        window.alert('カテゴリーが異なる場合、属性データを貼り付けることはできません。');
        return;
      }

      changeAttributes({ index: row, attributes: clipboard.data });
    },
    [clipboard, changeAttributes],
  );

  /**
   * 店舗の説明をコピーする
   */
  const onCopyProfile = useCallback((row: number, profile: GmbProfile) => {
    setClipboard({ cell: { row, column: 'PROFILE' }, dataType: 'PROFILE', data: profile });
  }, []);

  /**
   * 店舗の説明をペーストする
   */
  const onPasteProfile = useCallback(
    (row: number) => {
      if (clipboard?.dataType !== 'PROFILE') {
        return;
      }

      changeProfile({ index: row, profile: clipboard.data });
    },
    [clipboard, changeProfile],
  );

  /**
   * 編集モーダルを閉じる
   */
  const onCloseEditModal = useCallback(() => {
    setEditingCell(null);
    setIsEditingBulkSpecialHours(false);
    setIsEditingBulkAttributes(false);
    setEditingAttributesCategoryId(null);
    setIsEditingBulkProfile(false);
  }, []);

  /**
   * 属性の編集対象を変更する
   */
  const onChangeAttributes = useCallback(
    (attributeId: string) => {
      const target = targetAttributeIds.find((t) => t === attributeId);
      if (target) {
        setTargetAttributeIds(targetAttributeIds.filter((t) => t !== attributeId));
      } else {
        setTargetAttributeIds(targetAttributeIds.push(attributeId));
      }
    },
    [targetAttributeIds, setTargetAttributeIds],
  );

  /**
   * (Virtuosoのcomponents.Listに指定する) 店舗一覧のテーブルのコンポーネント
   *
   * - refを伝搬することができるようにforwardRefを利用する
   * - できる限り再描画されないようにするためにuseMemoでラップする
   */
  const StoreList = useMemo(
    () =>
      React.forwardRef<any, ListProps>(({ children, style }, ref) => {
        return (
          <StyledTable
            key='styled-table'
            style={{
              '--virtuosoPaddingTop': (style?.paddingTop ?? 0) + 'px',
              '--virtuosoPaddingBottom': (style?.paddingBottom ?? 0) + 'px',
            }}
          >
            <Table.Header>
              <Table.Row>
                <StyledStickyTableHeaderCell>コード</StyledStickyTableHeaderCell>
                <StyledStickyTableHeaderCell>店舗名</StyledStickyTableHeaderCell>
                <StyledTableHeaderCell>営業状態</StyledTableHeaderCell>
                <StyledTableHeaderCell>月曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>火曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>水曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>木曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>金曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>土曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>日曜日</StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  特別営業時間
                  <CustomButton priority='low' onClick={onStartBulkEditSpecialHours}>
                    一括変更
                  </CustomButton>
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  属性
                  <CustomButton priority='low' onClick={onStartBulkEditAttributes}>
                    一括変更
                  </CustomButton>
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  店舗の説明
                  <CustomButton priority='low' onClick={onStartBulkEditProfile}>
                    一括変更
                  </CustomButton>
                </StyledTableHeaderCell>
              </Table.Row>
            </Table.Header>
            <StyledTBody ref={ref}>{children}</StyledTBody>
          </StyledTable>
        );
      }),
    [onStartBulkEditSpecialHours, onStartBulkEditAttributes, onStartBulkEditProfile],
  );

  /**
   * (Virtuosoのcomponents.Itemに指定する) 店舗行のコンポーネント
   *
   * - useMemoでラップしないとrerenderの度に別の要素に置き換わってしまうため注意
   */
  const StoreItem = useMemo(
    () => (props: ItemProps) => {
      const row = props['data-index'];

      const store = bulkEditStores.stores.list.get(row);
      const initialStore = bulkEditStores.initialStores.list.get(row);
      if (!store || !initialStore) {
        return <React.Fragment key={row} />;
      }
      const modifiedParams = store.getModifiedParamsForBulkEdit(initialStore);
      const isEditing = editingCell?.row === row;
      const attributeMetadatas = bulkEditStores.attributeMetadatas[store.location.primaryCategory.categoryId];
      return (
        <StoreRow
          {...props}
          key={row}
          store={store}
          modifiedParams={modifiedParams}
          row={row}
          editingCell={isEditing ? editingCell : null}
          clipboard={clipboard}
          attributeMetadatas={attributeMetadatas}
          categoryList={categoryList}
          onChangeOpenInfo={onChangeOpenInfo}
          onApplyRegularHours={onApplyRegularHours}
          onCancelEditRegularHours={onCancelEditRegularHours}
          onStartEditRegularHours={onStartEditRegularHours}
          onCopyTimePeriods={onCopyTimePeriods}
          onPasteTimePeriods={onPasteTimePeriods}
          onStartEditSpecialHours={onStartEditSpecialHours}
          onCopySpecialHours={onCopySpecialHours}
          onPasteSpecialHours={onPasteSpecialHours}
          onStartEditAttributes={onStartEditAttributes}
          onCopyAttributes={onCopyAttributes}
          onPasteAttributes={onPasteAttributes}
          onStartEditProfile={onStartEditProfile}
          onCopyProfile={onCopyProfile}
          onPasteProfile={onPasteProfile}
        />
      );
    },
    [
      bulkEditStores.stores.list,
      bulkEditStores.initialStores.list,
      bulkEditStores.attributeMetadatas,
      editingCell,
      clipboard,
      categoryList,
      onChangeOpenInfo,
      onApplyRegularHours,
      onCancelEditRegularHours,
      onStartEditRegularHours,
      onCopyTimePeriods,
      onPasteTimePeriods,
      onStartEditSpecialHours,
      onCopySpecialHours,
      onPasteSpecialHours,
      onStartEditAttributes,
      onCopyAttributes,
      onPasteAttributes,
      onStartEditProfile,
      onCopyProfile,
      onPasteProfile,
    ],
  );

  if (!initialized) {
    return <></>;
  }

  // 編集中の店舗がGoogleビジネスプロフィールと連携しているかどうか
  const isEditingStoreConnectedToGBP =
    (editingCell !== null && bulkEditStores.stores.list.get(editingCell.row)?.isConnectedGBP) ?? false;
  // 編集中の店舗がYahoo! プレイスと連携しているかどうか
  const isEditingStoreConnectedToYplace =
    (editingCell !== null && bulkEditStores.stores.list.get(editingCell.row)?.isConnectedYahooPlace) ?? false;

  // Note
  // Virtuoso はデフォルトではテーブルをサポートしていないので、下記を参照して、
  // ListおよびItemを独自に指定することで、テーブルでも利用できるようにしている。
  // https://github.com/petyosi/react-virtuoso/issues/42
  // https://codesandbox.io/s/virtuoso-table-fshis?file=/src/App.tsx
  return (
    <>
      <Helmet title={getPageTitle('店舗情報の変更')} />
      <StickyHeader title='店舗情報の変更' />
      <Virtuoso
        style={{
          // ここで指定しないと効かない...
          height: 'calc(100vh - 160px)',
          width: 'calc(100vw - 320px)',
        }}
        totalCount={bulkEditStores.stores.list.size}
        components={{
          List: StoreList,
          Item: StoreItem,
        }}
        overscan={500}
      />
      <StoreBusinessInfoFooter
        canApply={bulkEditStores.hasDifference}
        selectedSize={bulkEditStores.numberOfModifiedStores}
        onCancel={() =>
          bulkEditStores.hasDifference ? setIsCanceling(!isCanceling) : dispatch(AppActions.moveTo(Path.store.index))
        }
        onConfirm={() => setIsConfirm(true)}
      />
      {/* 特別営業時間の変更モーダル */}
      <EditSpecialHoursModal
        open={isEditingSpecialHours}
        isConnectedToYPlace={isEditingStoreConnectedToYplace}
        specialHours={editingSpecialHours}
        onChange={setEditingSpecialHours}
        onApply={onApplySpecialHours}
        onClose={onCloseEditModal}
      />
      {/* 特別営業時間の一括変更モーダル */}
      <EditSpecialHoursModal
        open={isEditingBulkSpecialHours}
        isConnectedToYPlace={!bulkEditStores.stores.filterByIsConnectedYahooPlace().isEmpty}
        specialHours={editingBulkSpecialHours}
        onChange={setEditingBulkSpecialHours}
        onApply={onApplyBulkSpecialHours}
        onClose={onCloseEditModal}
      />
      {/* 属性の編集モーダル */}
      <BulkEditAttributesModal
        open={isEditingAttributes}
        gmbAttributes={editingAttributes}
        attributeMetadatas={bulkEditStores.attributeMetadatas[editingAttributesCategoryId || '']}
        individualUpdate={false}
        onChange={setEditingAttributes}
        onApply={onApplyAttributes}
        onClose={onCloseEditModal}
        categoryName={editingAttributesCategoryName || ''}
        targetAttributeIds={targetAttributeIds}
        setTargetAttributeIds={(v) => {
          /* 何もしない */
        }}
      />
      {/* 属性の一括編集モーダル */}
      <BulkEditAttributesModal
        open={isEditingBulkAttributes}
        gmbAttributes={editingBulkAttributes}
        attributeMetadatas={bulkEditStores.mergedAttributesMetadatas}
        individualUpdate={true}
        onChange={setEditingBulkAttributes}
        onApply={onApplyBulkAttributes}
        onClose={onCloseEditModal}
        categoryName={editingAttributesCategoryName || ''}
        targetAttributeIds={targetAttributeIds}
        setTargetAttributeIds={onChangeAttributes}
      />
      {/* 店舗の説明の変更モーダル */}
      <EditProfileModal
        open={isEditingProfile}
        profile={editingProfile}
        profileAliasRestored={editingProfileAliasRestored}
        isConnectedToGBP={isEditingStoreConnectedToGBP}
        isConnectedToYPlace={isEditingStoreConnectedToYplace}
        onChange={setEditingProfile}
        onApply={onApplyProfile}
        onClose={onCloseEditModal}
      />
      {/* 店舗の説明の一括変更モーダル */}
      <EditProfileModal
        open={isEditingBulkProfile}
        profile={editingBulkProfile}
        profileAliasRestored={editingBulkProfileAliasRestored}
        isConnectedToGBP={!bulkEditStores.stores.filterByIsConnectedGmb().isEmpty}
        isConnectedToYPlace={!bulkEditStores.stores.filterByIsConnectedYahooPlace().isEmpty}
        onChange={setEditingBulkProfile}
        onApply={onApplyBulkProfile}
        onClose={onCloseEditModal}
      />
      <ConfirmModal
        type='small'
        open={isCanceling}
        onExecution={() => dispatch(AppActions.moveTo(Path.store.index))}
        onNegativeExecution={() => setIsCanceling(false)}
        text='このページに新しいデータを入力しました。このページから離れると入力したデータは変更されません。'
      />
      <ConfirmModal
        type='small'
        open={isConfirm}
        contentText='変更'
        onExecution={updateBulkEditBusinessInfo}
        onNegativeExecution={() => setIsConfirm(false)}
        text='店舗情報を変更しますか？'
      />
    </>
  );
};

type StoreRowProps = {
  store: Store;
  modifiedParams: { [key: string]: boolean };
  row: number;
  editingCell: CellType | null;
  clipboard: Clipboard | null;
  attributeMetadatas: GmbAttributeMetadatas;
  categoryList: GmbCategories;
  onChangeOpenInfo: (row: number, value: OpenInfoStatus) => void;
  onApplyRegularHours: (row: number, column: RegularHoursColumnType, regularHours: GmbRegularHours) => void;
  onCancelEditRegularHours: () => void;
  onStartEditRegularHours: (row: number, column: RegularHoursColumnType) => void;
  onCopyTimePeriods: (row: number, column: RegularHoursColumnType, periods: GmbTimePeriods) => void;
  onPasteTimePeriods: (row: number, column: RegularHoursColumnType, regularHours: GmbRegularHours) => void;
  onStartEditSpecialHours: (row: number, specialHours: GmbSpecialHours) => void;
  onCopySpecialHours: (row: number, specialHours: GmbSpecialHours) => void;
  onPasteSpecialHours: (row: number) => void;
  onStartEditAttributes: (row: number, attributes: GmbAttributes, categoryId: string) => void;
  onCopyAttributes: (row: number, attributes: GmbAttributes, categoryId: string) => void;
  onPasteAttributes: (row: number, categoryId: string) => void;
  onStartEditProfile: (row: number, profile: GmbProfile) => void;
  onCopyProfile: (row: number, profile: GmbProfile) => void;
  onPasteProfile: (row: number) => void;
};

/**
 * StoreRow
 *
 * レンダリング範囲を最小限に止めるために行のデータをコンポーネント化したもの。
 * 関係ない情報の編集でレンダリングされることを避けるため、propsとして渡す値として他の行が編集されるたびに変化される情報は渡さないこと。
 */
const StoreRow: React.FC<StoreRowProps> = React.memo(
  ({
    store,
    modifiedParams,
    row,
    editingCell,
    clipboard,
    attributeMetadatas,
    categoryList,
    onChangeOpenInfo,
    onApplyRegularHours,
    onCancelEditRegularHours,
    onStartEditRegularHours,
    onCopyTimePeriods,
    onPasteTimePeriods,
    onStartEditSpecialHours,
    onCopySpecialHours,
    onPasteSpecialHours,
    onStartEditAttributes,
    onCopyAttributes,
    onPasteAttributes,
    onStartEditProfile,
    onCopyProfile,
    onPasteProfile,
    ...restProps
  }) => {
    // 注釈付き店舗の説明のデータ
    const annotatedProfileDescription = store.getAliasAnnotatedText(store.location.profile.description);

    const canEditAttribute = !!store.location.primaryCategory.categoryId;
    const canPasteAttribute = canEditAttribute && clipboard?.dataType === 'ATTRIBUTES' && clipboard?.cell.row !== row;

    return (
      <Table.Row key={store.id} {...restProps}>
        {/* 店舗コード (表示のみ) */}
        <StyledStickyCell uneditable={true}>
          <CodeLabel>{store.code}</CodeLabel>
        </StyledStickyCell>

        {/* 店舗名 (表示のみ) */}
        <StyledStickyCell uneditable={true}>
          <NameLabel>{store.name}</NameLabel>
          {store.branch && <BranchLabel>{store.branch}</BranchLabel>}
        </StyledStickyCell>

        {/* 営業状態 */}
        <StyledCell changed={modifiedParams.OPEN_INFO}>
          <CustomPullDown
            value={store.location.openInfo.status}
            options={selectOpenInfoOptions}
            onChange={(value) => onChangeOpenInfo(row, value)}
          />
        </StyledCell>

        {/* 営業時間 */}
        {regularHoursOrder.map((dayOfWeek, idx) => {
          return (
            <RegularHoursCell
              key={idx}
              store={store}
              row={row}
              dayOfWeek={dayOfWeek}
              changed={modifiedParams[dayOfWeek]}
              editingCell={editingCell}
              clipboard={clipboard}
              onApplyRegularHours={onApplyRegularHours}
              onCancelEditRegularHours={onCancelEditRegularHours}
              onStartEditRegularHours={onStartEditRegularHours}
              onCopyTimePeriods={onCopyTimePeriods}
              onPasteTimePeriods={onPasteTimePeriods}
            />
          );
        })}

        {/* 特別営業時間 */}
        <StyledEditableCell
          changed={modifiedParams.SPECIAL_HOURS}
          border={clipboard?.dataType === 'SPECIAL_HOURS' && clipboard?.cell.row === row}
          editing={false}
          canStartEdit={true}
          canCopy={true}
          canPaste={clipboard?.dataType === 'SPECIAL_HOURS' && clipboard?.cell.row !== row}
          onStartEdit={() => onStartEditSpecialHours(row, store.location.specialHours)}
          onCopy={() => onCopySpecialHours(row, store.location.specialHours)}
          onPaste={() => onPasteSpecialHours(row)}
        >
          <TimeWrapper>
            {store.location.specialHoursForDisplay.isEmpty()
              ? '－'
              : store.location.specialHoursForDisplay.map((specialHour, index) => (
                  <TimeLabel key={index}>{specialHour}</TimeLabel>
                ))}
          </TimeWrapper>
        </StyledEditableCell>

        {/* 属性 */}
        <StyledEditableCell
          changed={modifiedParams.ATTRIBUTES}
          border={clipboard?.dataType === 'ATTRIBUTES' && clipboard?.cell.row === row}
          editing={false}
          canStartEdit={canEditAttribute}
          canCopy={canEditAttribute}
          canPaste={canPasteAttribute}
          onStartEdit={() =>
            onStartEditAttributes(row, store.location.attributes, store.location.primaryCategory.categoryId)
          }
          onCopy={() => onCopyAttributes(row, store.location.attributes, store.location.primaryCategory.categoryId)}
          onPaste={() => onPasteAttributes(row, store.location.primaryCategory.categoryId)}
        >
          <CellAttributes
            categoryName={categoryList.generateDisplayName(store.location.primaryCategory.categoryId)}
            attributes={store.location.attributes}
            attributeMetadatas={attributeMetadatas}
          />
        </StyledEditableCell>

        {/* 店舗の説明 */}
        <StyledEditableCell
          changed={modifiedParams.PROFILE}
          border={clipboard?.dataType === 'PROFILE' && clipboard?.cell.row === row}
          editing={false}
          canStartEdit={true}
          canCopy={true}
          canPaste={clipboard?.dataType === 'PROFILE' && clipboard?.cell.row !== row}
          onStartEdit={() => onStartEditProfile(row, store.location.profile)}
          onCopy={() => onCopyProfile(row, store.location.profile)}
          onPaste={() => onPasteProfile(row)}
        >
          <DescriptionLabel>
            {annotatedProfileDescription.map((data, i) => {
              if (data.type == 'Alias') {
                return <Alias key={i}>{data.text}</Alias>;
              }
              return data.text;
            })}
          </DescriptionLabel>
        </StyledEditableCell>
      </Table.Row>
    );
  },
  (prevProps: StoreRowProps, nextProps: StoreRowProps) => {
    // modifiedParamsは毎回生成されるので必ず異なるデータになるため、個別にshallowEqualでチェックする
    const { modifiedParams: prevModifiedParams, ...restPrevProps } = prevProps;
    const { modifiedParams: nextModifiedParams, ...restNextProps } = nextProps;
    return shallowEqual(prevModifiedParams, nextModifiedParams) && shallowEqual(restPrevProps, restNextProps);
  },
);

type RegularHoursCellProps = {
  store: Store;
  row: number;
  dayOfWeek: RegularHoursColumnType;
  changed: boolean;
  editingCell: CellType | null;
  clipboard: Clipboard | null;
  onApplyRegularHours: (row: number, column: RegularHoursColumnType, regularHours: GmbRegularHours) => void;
  onCancelEditRegularHours: () => void;
  onStartEditRegularHours: (row: number, column: RegularHoursColumnType) => void;
  onCopyTimePeriods: (row: number, column: RegularHoursColumnType, periods: GmbTimePeriods) => void;
  onPasteTimePeriods: (row: number, column: RegularHoursColumnType, regularHours: GmbRegularHours) => void;
};

const RegularHoursCell: React.FC<RegularHoursCellProps> = React.memo(
  ({
    store,
    row,
    dayOfWeek,
    changed,
    editingCell,
    clipboard,
    onApplyRegularHours,
    onCancelEditRegularHours,
    onStartEditRegularHours,
    onCopyTimePeriods,
    onPasteTimePeriods,
  }) => {
    const regularHours = store.location.regularHours;
    const periods = regularHours.getPeriods(dayOfWeek);
    const isEditing = editingCell?.row === row && editingCell?.column === dayOfWeek;
    const isCopiedCell = clipboard?.cell.row === row && clipboard?.cell.column === dayOfWeek;
    const canPaste = !isCopiedCell && clipboard?.dataType === 'TIME_PERIODS';

    return isEditing && onApplyRegularHours !== null ? (
      <StyledCell changed={changed} border={isCopiedCell} editing={true}>
        <EditRegularHours
          store={store}
          regularHours={regularHours}
          editType={dayOfWeek}
          onApply={(regularHours: GmbRegularHours) => onApplyRegularHours(row, dayOfWeek, regularHours)}
          onCancel={onCancelEditRegularHours}
        />
      </StyledCell>
    ) : (
      <StyledEditableCell
        changed={changed}
        border={isCopiedCell}
        editing={false}
        canStartEdit={true}
        canCopy={true}
        canPaste={canPaste}
        displayLabel={false}
        onStartEdit={() => onStartEditRegularHours(row, dayOfWeek)}
        onCopy={() => onCopyTimePeriods(row, dayOfWeek, periods)}
        onPaste={() => onPasteTimePeriods(row, dayOfWeek, regularHours)}
      >
        <RegularHours periods={periods} />
      </StyledEditableCell>
    );
  },
);

const StyledTable = styled(Table)`
  &&& {
    width: 100%;
    border: 0;

    height: calc(100vh - 160px);
    @media (max-width: 600px) {
      padding: 16px;
      margin-right: 0;
      height: 100vh;
    }
  }
`;

const StyledTableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    white-space: nowrap;
    position: sticky;
    top: 0;
    z-index: 2;

    &:not(:first-child) {
      border-top: 0.5px solid rgba(34, 36, 38, 0.1);
      border-right: 0.5px solid rgba(34, 36, 38, 0.1);
    }
  }
`;

const StyledStickyTableHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    left: 0;
    z-index: 3;

    &:nth-child(2) {
      left: 100px;
      z-index: 3;
    }

    &:first-child {
      border: 0.5px solid rgba(34, 36, 38, 0.1);
      border-right: 0.5px solid rgba(34, 36, 38, 0.1);
      border-radius: 0.28571429rem;
    }
  }
`;

const StyledCellCss = css<{ changed?: boolean; border?: boolean; editing?: boolean; uneditable?: boolean }>`
  min-width: 125px;
  position: relative;
  ${(props) => props.border && 'outline: solid 2px rgba(5, 204, 173, 0.6) !important;'}
  outline-offset: -2px;

  border-top: 0.5px solid rgba(34, 36, 38, 0.1);
  border-right: 0.5px solid rgba(34, 36, 38, 0.1);

  &&& {
    white-space: nowrap;
    vertical-align: top;

    background-color: ${(props) =>
      props.uneditable
        ? `${COLOR.WHITE}`
        : props.editing
          ? `${COLOR.BACKGROUND}`
          : props.changed
            ? '#CFF2E6'
            : `${COLOR.WHITE}`};
    &:hover {
      background-color: ${(props) =>
        props.uneditable
          ? `${COLOR.WHITE}`
          : props.editing
            ? `${COLOR.BACKGROUND}`
            : props.changed
              ? '#DEECE8'
              : `${COLOR.BACKGROUND}`};
    }
  }
`;
const StyledCell = styled(Table.Cell)<{ changed: boolean; border?: boolean; editing?: boolean; uneditable?: boolean }>`
  ${StyledCellCss}
`;

const StyledEditableCell = styled(EditableCell)<{
  changed: boolean;
  border?: boolean;
  editing?: boolean;
  uneditable?: boolean;
}>`
  ${StyledCellCss}
`;

const StyledStickyCell = styled(StyledCell)`
  &&& {
    position: sticky;
    z-index: 2;
    left: 0;

    &:first-child {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      border-left: 0.5px solid rgba(34, 36, 38, 0.1);
    }

    &:nth-child(2) {
      left: 100px;
    }
  }
`;

const NameLabel = styled.div`
  font-size: 12px;
  white-space: normal;
  word-break: break-all;
  width: 220px;
`;

const BranchLabel = styled.div`
  white-space: normal;
  word-break: break-all;
  width: 220px;
`;

const CodeLabel = styled.div`
  white-space: normal;
  word-break: break-all;
  width: 70px;
`;

const TimeWrapper = styled.div``;

const TimeLabel = styled.p`
  white-space: nowrap;
`;

const CustomPullDown = styled(PullDownNarrow)`
  &&& {
    width: 118px;
  }
`;

const CustomButton = styled(Button)`
  &&& {
    font-weight: normal;
    cursor: pointer;
    width: auto;
    font-size: 16px;
    margin-left: 10px;
    padding: 6px 4px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  &:before {
    white-space: pre;
  }
`;

const DescriptionLabel = styled.div`
  width: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
`;

const Alias = styled.span`
  font-weight: bold;
  color: ${COLOR.GREEN};
  vertical-align: baseline;
`;

const StyledTBody = styled.tbody`
  &::before {
    display: block;
    padding-top: var(--virtuosoPaddingTop);
    content: '';
  }
  &::after {
    display: block;
    padding-top: var(--virtuosoPaddingBottom);
    content: '';
  }
`;
