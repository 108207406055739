import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { TagInput } from 'components/molecules/TagInput';
import { InstagramPostTargetTable } from 'components/pageComponents/Instagram/InstagramPostTargetTable';
import { InstagramHelp as Help } from 'helpers/ContextHelp';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { InstagramAccount, InstagramPostRule, InstagramPostTarget } from 'models/Domain/instagram';

import { InstagramPostTargetModal } from './InstagramPostTargetModal';
import { InstagramPostTargetPreviewModal } from './InstagramPostTargetPreviewModal';

type Props = {
  account: InstagramAccount;
  stores: Stores;
  storeLists: StoreLists;
  showErrorMessage: boolean;
  onDelete: () => void;
  onUpdate: (account: InstagramAccount) => void;
};

export const InstagramAccountEditForm = React.memo<Props>(
  ({ account, onDelete, onUpdate, stores, storeLists, showErrorMessage }) => {
    const [accountForEdit, setAccountForEdit] = useState<InstagramAccount>(account);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
    const [editingTargetIndex, setEditingTargetIndex] = useState<number | null>(null);

    useEffect(() => {
      // accountが更新されたらaccountForEditを置き換える
      setAccountForEdit(account);
    }, [account]);

    const postRule = useMemo(() => accountForEdit.postRule || new InstagramPostRule(), [accountForEdit.postRule]);

    const handleOnChangeExcludeWords = useCallback((values: string[]) => {
      setAccountForEdit((account) => account.setIn(['postRule', 'excludeWords'], ImmutableList(values)));
    }, []);

    const handleOnDelete = useCallback(() => {
      if (window.confirm('本当に連携解除しますか？')) {
        onDelete();
      }
    }, [onDelete]);

    const handleOnUpdate = useCallback(() => {
      onUpdate(accountForEdit);
    }, [onUpdate, accountForEdit]);

    const handleOnCloneTarget = useCallback(
      (index: number) => {
        // 複製元のデータを取得し、idを空にして、末尾に追加する
        let target = accountForEdit.postRule?.targets?.get(index);
        if (!target) {
          return;
        }
        target = target.set('id', null);
        setAccountForEdit((account) => account.updateIn(['postRule', 'targets'], (targets) => targets.push(target)));
      },
      [accountForEdit.postRule?.targets],
    );

    const handleOnEditTarget = useCallback((index: number) => {
      setEditingTargetIndex(index);
      setIsSettingsModalOpen(true);
    }, []);

    const handleOnDeleteTarget = useCallback(
      (index: number) => {
        const target = postRule.targets?.get(index) ?? null;
        if (!target) {
          return;
        }
        setAccountForEdit((account) => account.updateIn(['postRule', 'targets'], (targets) => targets.delete(index)));
      },
      [postRule],
    );

    const handleOnChangeOrder = useCallback(
      (index: number) => {
        if (index < 1) {
          return;
        }
        const targets = postRule.targets;
        const t1 = targets.get(index);
        const t2 = targets.get(index - 1);
        if (t1 && t2) {
          const newTargets = targets.set(index - 1, t1).set(index, t2);
          setAccountForEdit((account) => account.setIn(['postRule', 'targets'], newTargets));
        }
      },
      [postRule.targets],
    );

    const handleOnChangeTarget = useCallback(
      (target: InstagramPostTarget) => {
        setAccountForEdit((account) => account.setIn(['postRule', 'targets', editingTargetIndex], target));
      },
      [editingTargetIndex],
    );

    const handleOnCloseTargetModal = useCallback(() => {
      setEditingTargetIndex(null);
      setIsSettingsModalOpen(false);
    }, []);

    const handleOnClosePreviewModal = useCallback(() => {
      setIsPreviewModalOpen(false);
    }, []);

    const handleOnClickPreview = useCallback(() => {
      setIsPreviewModalOpen(true);
    }, []);

    // 更新ボタンを非活性にするかどうか
    const updateButtonDisabled = useMemo(
      () => (showErrorMessage ? !accountForEdit.isValidRule : false),
      [showErrorMessage, accountForEdit],
    );

    return (
      <Wrapper>
        <Title>投稿のGBPへの自動反映</Title>
        <Content>
          <LabelWrapper>
            <Label>
              投稿対象
              <ContextHelp content={Help.targets} />
            </Label>
            <AddButton
              priority={'low'}
              onClick={() => {
                setEditingTargetIndex(postRule.targets.size);
                setIsSettingsModalOpen(true);
              }}
            >
              投稿対象を追加
            </AddButton>
          </LabelWrapper>
          <InstagramPostTargetTable
            account={accountForEdit}
            stores={stores}
            storeLists={storeLists}
            onClone={handleOnCloneTarget}
            onEdit={handleOnEditTarget}
            onDelete={handleOnDeleteTarget}
            onChangeOrder={handleOnChangeOrder}
          />
        </Content>
        <Content>
          <Label>
            除外キーワード
            <ContextHelp content={Help.excludeWords} />
          </Label>
          <TagInput values={postRule.excludeWords?.toArray() ?? []} onChange={handleOnChangeExcludeWords} />
        </Content>
        <ButtonWrapper>
          <PreviewButton onClick={handleOnClickPreview} disabled={postRule.targets.isEmpty()}>
            プレビュー
          </PreviewButton>
          <UpdateButton onClick={handleOnUpdate} disabled={updateButtonDisabled}>
            更新
          </UpdateButton>
        </ButtonWrapper>
        <DeleteButtonWrapper>
          <DeleteButton onClick={handleOnDelete}>
            <DeleteIcon type={'break_link'} />
            Instagramアカウントの連携を解除する
          </DeleteButton>
        </DeleteButtonWrapper>
        {isSettingsModalOpen && (
          <InstagramPostTargetModal
            isOpen={isSettingsModalOpen}
            onClose={handleOnCloseTargetModal}
            target={editingTargetIndex != null ? (postRule.targets.get(editingTargetIndex) ?? null) : null}
            onChange={handleOnChangeTarget}
          />
        )}
        {accountForEdit.postRule && (
          <InstagramPostTargetPreviewModal
            isOpen={isPreviewModalOpen}
            onClose={handleOnClosePreviewModal}
            account={accountForEdit}
            stores={stores}
            storeLists={storeLists}
          />
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 24px 0;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
`;

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const AddButton = styled(Button)`
  margin-left: auto;
`;

const DeleteButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DeleteIcon = styled(Icon).attrs({ name: 'unlinkify' })`
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 4px;
`;

const DeleteButton = styled(Button).attrs({ priority: 'low', negative: true })`
  &&& {
    display: flex;
    align-items: baseline;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 32px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 170px;
    max-width: calc(50% - 8px);
    padding: 16px;
  }
`;

const UpdateButton = styled(StyledButton).attrs({ priority: 'high' })``;
const PreviewButton = styled(StyledButton)``;
const Content = styled.div`
  margin-bottom: 32px;
`;
