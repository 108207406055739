import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadata, GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbUrlAttribute, GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { COLOR } from 'style/color';

const RESERVATION_LINK_ATTRIBUTE_IDS = ['url_appointment', 'url_reservations'];

// GBPが取り扱うURLタイプの属性マスタ
const UrlAttributes = [
  {
    attributeId: 'url_menu',
    displayName: 'メニューリンク',
    groupDisplayName: 'プレイスページの URL',
    valueMetadata: [],
    valueType: 'URL',
    isDeprecated: null,
    isRepeatable: null,
  },
  {
    attributeId: 'url_appointment',
    displayName: '予約リンク',
    groupDisplayName: 'プレイスページの URL',
    valueMetadata: [],
    valueType: 'URL',
    isDeprecated: null,
    isRepeatable: true,
  },
  {
    attributeId: 'url_reservations',
    displayName: '予約リンク',
    groupDisplayName: 'プレイスページの URL',
    valueMetadata: [],
    valueType: 'URL',
    isDeprecated: null,
    isRepeatable: true,
  },
  {
    attributeId: 'url_order_ahead',
    displayName: '事前注文リンク',
    groupDisplayName: 'プレイスページの URL',
    valueMetadata: [],
    valueType: 'URL',
    isDeprecated: null,
    isRepeatable: true,
  },
  {
    attributeId: 'url_inventory_search',
    displayName: '在庫検索 URL',
    groupDisplayName: 'プレイスページの URL',
    valueMetadata: [],
    valueType: 'URL',
    isDeprecated: true,
    isRepeatable: true,
  },
];

const UrlAttributeMetadatas = new GmbAttributeMetadatas(UrlAttributes);

export type UrlAttributeListProps = {
  // 店舗に紐付いている属性情報
  attributes: GmbUrlAttributes;
  // 店舗のメインカテゴリに紐付いている属性情報
  attributeMetadatas: GmbAttributeMetadatas;
  // 店舗が利用可能なURL種別の情報
  availableUrlTypes: GbpAvailableUrlTypes;
  // 空の属性のみのグループを表示対象に含めるかどうか
  displayEmptyAttributeGroup: boolean;
  // 予約リンク(url_appointment)を更新可能かどうか
  canModifyUrlAppointment: boolean;
  // 予約リンク(url_reservations)を更新可能かどうか
  canModifyUrlReservations: boolean;
};

type AttributePair = [GmbUrlAttribute, GmbAttributeMetadata];

export const OtherLinkList: React.FC<UrlAttributeListProps> = React.memo(
  ({
    attributes,
    attributeMetadatas,
    availableUrlTypes,
    displayEmptyAttributeGroup,
    canModifyUrlAppointment,
    canModifyUrlReservations,
  }) => {
    if (!attributeMetadatas) return null;

    // 店舗に紐付く属性情報の中で、メインカテゴリに紐付く属性情報に存在しないものを抽出
    const attributesOnly = attributes.list.filter((attribute) => !attributeMetadatas.find(attribute.attributeId));

    // 店舗に紐付く属性情報とメインカテゴリに紐付く属性情報をマージした属性情報リストを作成する
    let mergedAttributeMetadatas = attributeMetadatas;
    attributesOnly.forEach((attribute) => {
      // URLタイプの属性情報のマスタから、メタデータを取得する
      const urlAttributeMetadata = UrlAttributeMetadatas.find(attribute.attributeId);
      if (urlAttributeMetadata) {
        mergedAttributeMetadatas = mergedAttributeMetadatas.add(urlAttributeMetadata);
      } else {
        // メタデータを取得できない場合は、attributeIdをdisplayNameとしてメタデータを作成して追加する
        mergedAttributeMetadatas = mergedAttributeMetadatas.add(
          new GmbAttributeMetadata({
            attributeId: attribute.attributeId,
            valueType: attribute.valueType,
            displayName: attribute.attributeId,
          }),
        );
      }
    });

    // 表示するグループのみに絞り込む(空の属性グループを表示しないかつ attribute が1件もない場合を除外する)
    const groupedUrlAttributeMetadatas = List(mergedAttributeMetadatas.groupByOtherUrlGroupDisplayName()).filter(
      ([_, urlAttributeMetadataList]) =>
        displayEmptyAttributeGroup ||
        urlAttributeMetadataList.some((urlAttributeMetadata) => !!attributes.find(urlAttributeMetadata.attributeId)),
    );

    return (
      <Wrapper>
        {groupedUrlAttributeMetadatas.map(([groupName, urlAttributeMetadataList], index) => {
          const displayAttributes = urlAttributeMetadataList
            .toList()
            .map(
              (urlAttributeMetadata) =>
                [
                  attributes.find(urlAttributeMetadata.attributeId),
                  attributeMetadatas.find(urlAttributeMetadata.attributeId),
                ] as AttributePair,
            )
            .filter(([attribute, attributeMetadata]) => attribute || attributeMetadata) as List<AttributePair>;

          const hasUrlValues = !displayAttributes.filter(([attribute]) => attribute && attribute.hasValue()).isEmpty();
          const editableByMainCategory = displayAttributes.some(
            ([_, attributeMetadata]) => attributeMetadata !== undefined,
          );
          const isReservationLinkAttributes = displayAttributes.some(
            ([_, attributeMetadata]) =>
              attributeMetadata !== undefined && RESERVATION_LINK_ATTRIBUTE_IDS.includes(attributeMetadata.attributeId),
          );
          const editableByAvailableUrlTypes = isReservationLinkAttributes
            ? availableUrlTypes.canEditReservationLink
            : true;
          const editable = editableByMainCategory && editableByAvailableUrlTypes;

          // 予約リンクがサードパーティプロバイダにより設定されている場合は編集不可
          const disabledByAggregator3p =
            (displayAttributes.some(
              ([_, metadata]) => metadata !== undefined && metadata.attributeId === 'url_appointment',
            ) &&
              !canModifyUrlAppointment) ||
            (displayAttributes.some(
              ([_, metadata]) => metadata !== undefined && metadata.attributeId === 'url_reservations',
            ) &&
              !canModifyUrlReservations);

          // 値がカラの属性グループを表示対象に含めない設定 かつ 編集できない属性グループの場合 null を返す
          if (!displayEmptyAttributeGroup && !editable) {
            return null;
          }

          return (
            <React.Fragment key={groupName}>
              {
                // コピペした時にグループごとに改行が入るようにするための <br />
                index > 0 && <br />
              }
              <AttributeGroup>
                <Attribute>
                  <AttributeGroupDisplayName editable={editable}>
                    {groupName}
                    {!editable && (
                      <>
                        （編集できません）
                        <ContextHelp
                          content={`現在、この店舗が所属しているメインカテゴリーでは、${groupName}を設定できません。`}
                        />
                      </>
                    )}
                    {disabledByAggregator3p && (
                      <>
                        （編集できません）
                        <ContextHelp
                          content={
                            <>
                              予約リンクはプロバイダとの連携により設定されているためSTORECASTから変更することはできません。
                              <br />
                              変更が必要な場合、予約リンクのプロバイダにお問い合わせください。
                            </>
                          }
                        />
                      </>
                    )}
                  </AttributeGroupDisplayName>
                  {!hasUrlValues ? (
                    <AttributeUrlValue editable={editable}>リンクが設定されていません</AttributeUrlValue>
                  ) : (
                    <>
                      {displayAttributes.map(([attribute]) => {
                        return attribute ? (
                          <React.Fragment key={attribute.attributeId}>
                            {attribute.urlValues.map((value, valueIndex) => (
                              <AttributeUrlValue key={valueIndex} editable={editable}>
                                <InvisibleText>- </InvisibleText>
                                {value.url}
                              </AttributeUrlValue>
                            ))}
                          </React.Fragment>
                        ) : null;
                      })}
                    </>
                  )}
                </Attribute>
              </AttributeGroup>
            </React.Fragment>
          );
        })}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const AttributeGroup = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const Attribute = styled.div``;

const AttributeGroupDisplayName = styled.div<{ editable: boolean }>`
  font-weight: bold;
  color: ${(props) => (props.editable ? `${COLOR.BLACK}` : `${COLOR.GRAY}`)};
`;

const AttributeUrlValue = styled.div<{ editable: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.editable ? `${COLOR.BLACK}` : `${COLOR.GRAY}`)};
`;

const InvisibleText = styled.span`
  font-size: 0;
  color: transparent;
`;
