import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { JSObject } from 'types/Common';

import { GmbAttributes } from './GmbAttributes';
import { MoreHoursType } from './MoreHours';
import { ServiceType } from './Service';

export interface Option {
  text: string;
  value: string;
}

export class GmbLocationCategory extends Record<{
  categoryId: string;
  displayName: string;
  serviceTypes?: List<ServiceType>;
  moreHoursTypes?: List<MoreHoursType>;
  attributes?: GmbAttributes;
}>({
  categoryId: '',
  displayName: '',
  serviceTypes: undefined,
  moreHoursTypes: undefined,
  attributes: undefined,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.serviceTypes) {
      params.serviceTypes = List(params.serviceTypes.map((serviceType: JSObject) => ServiceType.fromJSON(serviceType)));
    }
    if (params.moreHoursTypes) {
      params.moreHoursTypes = List(
        params.moreHoursTypes.map((moreHoursType: JSObject) => MoreHoursType.fromJSON(moreHoursType)),
      );
    }
    if (params.attributes) {
      params.attributes = GmbAttributes.fromJSON(params.attributes);
    }
    super(params);
  }

  get isEmpty() {
    return !this.categoryId;
  }

  hasService() {
    return !!this.serviceTypes && !this.serviceTypes.isEmpty();
  }

  hasMoreHours() {
    return !!this.moreHoursTypes && !this.moreHoursTypes.isEmpty();
  }

  hasAttributes() {
    return !!this.attributes && !this.attributes.list.isEmpty();
  }

  changeCategoryId(value: string) {
    return this.set('categoryId', value);
  }

  validateCategory() {
    if (!this.categoryId) {
      return {
        isValid: false,
        error: ErrorType.PRIMARY_CATEGORY_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  get updateParams() {
    return {
      categoryId: this.categoryId,
      displayName: this.displayName,
      serviceTypes: this.serviceTypes?.toJS(),
      moreHoursTypes: this.moreHoursTypes?.toJS(),
      attributes: this.attributes?.list.toJS(),
    };
  }
}

export class GmbLocationCategories extends Record<{
  list: List<GmbLocationCategory>;
}>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data && data.map((p) => new GmbLocationCategory(p)));
    super({ list });
  }

  addCategory() {
    return this.set('list', this.list.push(new GmbLocationCategory()));
  }

  removeCategory(index: number) {
    return this.set(
      'list',
      this.list.filter((target, categoryIndex) => index !== categoryIndex),
    );
  }

  changeCategories(index: number, value: string) {
    return this.set(
      'list',
      List(
        this.list.map((target, listIndex) => {
          if (index === listIndex) {
            return target.changeCategoryId(value);
          }
          return target;
        }),
      ),
    );
  }
}
