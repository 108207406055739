import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface LocationRecord {
  id: string;
  accountId: number;
  businessId: string;
  displayName: string;
}

export default class Location extends Record<LocationRecord>({
  id: '',
  accountId: 0,
  businessId: '',
  displayName: '',
}) {
  static fromJson(data: JSObject = {}): Location {
    return new Location({
      id: data.id,
      accountId: data.accountId,
      businessId: data.businessId,
      displayName: data.displayName,
    });
  }
}
