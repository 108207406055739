import { List, Record, Set } from 'immutable';

import ErrorType from 'helpers/errorType';
import {
  validateDomainWithSlash,
  validatePhoneNumber,
  validatePostalCode,
  validateWebsiteUrl,
  validateWebsiteUrlParam,
} from 'helpers/utils';
import WarningType from 'helpers/warningType';
import { JSObject } from 'types/Common';

import { SNSItems } from '../SNSLink';
import { YahooPlaceBusiness } from '../YahooPlace/Business';
import { YahooPlacePayment } from '../YahooPlace/Payment';
import { YahooPlaceSNSLink } from '../YahooPlace/SNSLink';

import { GmbAddress } from './GmbAddress';
import { GmbAttributes, GmbUrlAttributes, getDiffAttributes } from './GmbAttributes';
import { GmbLatlng } from './GmbLatlng';
import { GmbLocationCategories, GmbLocationCategory } from './GmbLocationCategories';
import { GmbOpenInfo, OpenInfoStatus } from './GmbOpenInfo';
import { GmbProfile } from './GmbProfile';
import { GmbRegularHours } from './GmbRegularHours';
import { GmbServiceItems } from './GmbServiceItems';
import { GmbSpecialHours } from './GmbSpecialHours';
import { MoreHoursList, MoreHoursType, getDiffMoreHoursTypeIds } from './MoreHours';
import { OpeningDate } from './OpeningDate';
import {
  STConnectedServiceValueABC,
  STConnectedServiceValueGBP,
  STConnectedServiceValueYahooPlace,
  STConnectedServices,
} from './STConnectedServices';

// https://developers.google.com/my-business/reference/rest/v4/accounts.locations

// 店舗名（フリガナ）の入力形式
const LOCATION_NAME_KANA_PATTERN = /^[\u30A0-\u30FF\s]+$/;
export class GmbLocation extends Record<{
  name: string; // accounts/{account_id}/locations/{location_id}
  locationName: string;
  locationNameKana: string;
  primaryPhone: string;
  additionalPhones: List<string>;
  websiteUrl: string;
  storeCode: string;
  regularHours: GmbRegularHours;
  specialHours: GmbSpecialHours;
  primaryCategory: GmbLocationCategory;
  additionalCategories: GmbLocationCategories;
  address: GmbAddress;
  openInfo: GmbOpenInfo;
  profile: GmbProfile;
  attributes: GmbAttributes;
  urlAttributes: GmbUrlAttributes;
  latlng: GmbLatlng;
  moreHours: MoreHoursList;
  openingDate: OpeningDate | null;
  serviceItems: GmbServiceItems;
  connectedServices: STConnectedServices;
}>({
  name: '',
  locationName: '',
  locationNameKana: '',
  primaryPhone: '',
  additionalPhones: List(),
  websiteUrl: '',
  storeCode: '',
  regularHours: new GmbRegularHours(),
  specialHours: new GmbSpecialHours(),
  primaryCategory: new GmbLocationCategory(),
  additionalCategories: new GmbLocationCategories(),
  address: new GmbAddress(),
  openInfo: new GmbOpenInfo(),
  profile: new GmbProfile(),
  attributes: new GmbAttributes(),
  urlAttributes: new GmbUrlAttributes(),
  latlng: new GmbLatlng(),
  moreHours: new MoreHoursList(),
  openingDate: null,
  serviceItems: new GmbServiceItems(),
  connectedServices: new STConnectedServices(),
}) {
  constructor(data: JSObject = {}) {
    const { attributes, ...params } = data;
    params.locationNameKana = params.locationNameKana || '';
    params.additionalPhones = List(params.additionalPhones || []);
    params.address = new GmbAddress(params.address);
    params.regularHours = new GmbRegularHours((params.regularHours && params.regularHours.periods) || []);
    params.primaryCategory = new GmbLocationCategory(params.primaryCategory);
    params.additionalCategories = new GmbLocationCategories(params.additionalCategories);
    params.specialHours = new GmbSpecialHours(params.specialHours);
    params.openInfo = new GmbOpenInfo(params.openInfo);
    params.openingDate = params.openingDate && new OpeningDate(params.openingDate);
    params.profile = new GmbProfile(params.profile);

    // attributesは属性とURL属性の２つに分けて管理する
    params.attributes = GmbAttributes.fromJSON(attributes || []);
    params.urlAttributes = GmbUrlAttributes.fromJSON(attributes || []);
    params.latlng = GmbLatlng.fromJSON(params.latlng);
    params.moreHours = MoreHoursList.fromJSON(params.moreHours || []);
    params.serviceItems = GmbServiceItems.fromJSON(params.serviceItems || []);
    params.connectedServices = new STConnectedServices({
      gbp:
        params.connectedServices && params.connectedServices.gbp
          ? STConnectedServiceValueGBP.fromJSON(params.connectedServices.gbp)
          : new STConnectedServiceValueGBP(),
      abc:
        params.connectedServices && params.connectedServices.abc
          ? STConnectedServiceValueABC.fromJSON(params.connectedServices.abc)
          : new STConnectedServiceValueABC(),
      yahooPlace:
        params.connectedServices && params.connectedServices.yahooPlace
          ? STConnectedServiceValueYahooPlace.fromJSON(params.connectedServices.yahooPlace)
          : new STConnectedServiceValueYahooPlace(),
    });

    super(params);
  }

  get regularHoursForDisplay() {
    return this.regularHours ? this.regularHours.regularHoursForDisplay : [];
  }

  get specialHoursForDisplay() {
    return this.specialHours.specialHoursForDisplay;
  }

  get phones() {
    return [this.primaryPhone, ...this.additionalPhones.toArray()];
  }

  get additionalPhonesForEdit() {
    let phones = this.additionalPhones;
    if (phones.size === 0 || (phones.size < 2 && phones.last())) {
      phones = phones.push('');
    }
    return phones.toArray();
  }

  /**
   * 利用可能なその他の営業時間
   * メインカテゴリに紐づく追加の営業移管
   */
  get availableMoreHoursTypes() {
    return this.primaryCategory.moreHoursTypes ?? List<MoreHoursType>();
  }

  get allCategoryIds() {
    return List([this.primaryCategory.categoryId]).concat(
      this.additionalCategories.list.map((category) => category.categoryId),
    );
  }

  /**
   * ロケーションを受け取り、自身との差分があるその他の営業時間のhoursTypeIdを返す
   */
  getMoreHoursDiffTypes(other: GmbLocation) {
    return getDiffMoreHoursTypeIds(this.moreHours, other.moreHours);
  }

  /**
   * ロケーションを受け取り、自身の属性差分がある attributeId を返す
   * ビジネスカテゴリが同じ前提
   * @param 比較対象のロケーション
   * @returns 差分がある attributeId の Set
   */
  getAttributesDiffIds(other: GmbLocation) {
    const urlAttributeIds = getDiffAttributes(this.urlAttributes, other.urlAttributes);
    const attributeIds = getDiffAttributes(this.attributes, other.attributes);
    return Set(urlAttributeIds.concat(attributeIds));
  }

  /**
   * 属性情報の差分が出ているid以外削除したものを返す
   * @param location 比較対象の属性情報を持つロケーション
   * @returns 差分が出ている属性を残したものを属性情報に持つロケーション
   */
  filterAttributeDiffs(location: GmbLocation) {
    const diffIds = this.getAttributesDiffIds(location);
    return this.setIn(
      ['attributes', 'list'],
      this.attributes.list.filter((attribute) => diffIds.includes(attribute.attributeId)),
    ).setIn(
      ['urlAttributes', 'list'],
      this.urlAttributes.list.filter((urlAttribute) => diffIds.includes(urlAttribute.attributeId)),
    );
  }

  changeLocationNameKana(nameKana: string) {
    return this.set('locationNameKana', nameKana);
  }

  changePrimaryPhone(phone: string) {
    // 数字と"-"以外の文字を除外する
    let result = phone.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    result = result.replace(/[^\d-]/g, '');
    return this.set('primaryPhone', result);
  }

  changeGmbAttributes(attributes: GmbAttributes) {
    return this.set('attributes', attributes);
  }

  changeGmbUrlAttributes(attributes: GmbUrlAttributes) {
    return this.set('urlAttributes', attributes);
  }

  changeAdditionalPhones(index: number, phone: string) {
    // 数字と"-"以外の文字を除外する
    let result = phone.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    result = result.replace(/[^\d-]/g, '');
    return this.setIn(['additionalPhones', index], result);
  }

  changeWebsiteUrl(websiteUrl: string) {
    return this.set('websiteUrl', websiteUrl);
  }

  initiateWebsiteUrlParams() {
    return this.set('connectedServices', new STConnectedServices());
  }

  changeWebsiteUrlGbpParam(websiteUrlParam: string) {
    return this.set('connectedServices', this.connectedServices.setIn(['gbp', 'websiteUrlParam'], websiteUrlParam));
  }

  changeWebsiteUrlYplaceParam(websiteUrlParam: string) {
    return this.set(
      'connectedServices',
      this.connectedServices.setIn(['yahooPlace', 'websiteUrlParam'], websiteUrlParam),
    );
  }

  changeYahooPlaceSNSLink(yahooPlaceSNSLink: YahooPlaceSNSLink) {
    return this.set('connectedServices', this.connectedServices.setIn(['yahooPlace', 'snsLink'], yahooPlaceSNSLink));
  }

  changeRegularHours(regularHours: GmbRegularHours) {
    return this.set('regularHours', regularHours);
  }

  toggleDaySpecialHours(dayIndex: number, value: boolean) {
    return this.update('specialHours', (specialHours) => specialHours.toggleOpenDay(dayIndex, value));
  }

  changeSpecialHoursPeriod(dayIndex: number, timeIndex: number, openTime: string, closeTime: string) {
    return this.update('specialHours', (specialHours) =>
      specialHours.changePeriod(dayIndex, timeIndex, openTime, closeTime),
    );
  }

  changeSpecialHoursDate(dayIndex: number, value: string) {
    return this.update('specialHours', (specialHours) => specialHours.changeDate(dayIndex, value));
  }

  addSpecialHours() {
    return this.update('specialHours', (specialHours) => specialHours.addSpecialHours());
  }

  addSpecialHoursOpenDay(dayIndex: number) {
    return this.update('specialHours', (specialHours) => specialHours.addOpenDay(dayIndex));
  }

  removeSpecialHoursPeriod(dayIndex: number, timeIndex: number) {
    return this.update('specialHours', (specialHours) => specialHours.removePeriod(dayIndex, timeIndex));
  }

  removeSpecialHoursDay(index: number) {
    return this.update('specialHours', (specialHours) => specialHours.removeDay(index));
  }

  removePastSpecialHours() {
    return this.update('specialHours', (specialHours) => specialHours.removePastDate());
  }

  changePostalCode(postalCode: string) {
    return this.update('address', (address) => address.changePostalCode(postalCode));
  }

  changeAdministrativeArea(administrativeArea: string) {
    return this.update('address', (address) => address.changeAdministrativeArea(administrativeArea));
  }

  changeAddress1(address1: string) {
    return this.update('address', (address) => address.changeAddress1(address1));
  }
  changeAddress2(address2: string) {
    return this.update('address', (address) => address.changeAddress2(address2));
  }

  changeOpenInfoStatus(status: OpenInfoStatus) {
    return this.update('openInfo', (openInfo) => openInfo.changeStatus(status));
  }

  changeOpeningDate(openingDate: OpeningDate | null) {
    return this.set('openingDate', openingDate);
  }

  changeProfileDescription(description: string) {
    return this.update('profile', (profile) => profile.changeDescription(description));
  }

  changeMoreHours(moreHours: MoreHoursList) {
    return this.set('moreHours', moreHours);
  }

  validate(isConnectedGBP: boolean = false, isConnectedYahooPlace: boolean = false) {
    const errors = {
      locationNameKana: this.validateLocationNameKana(isConnectedYahooPlace),
      websiteUrl: this.validateWebsiteUrl(),
      websiteUrlGbpParam: this.validateWebsiteUrlGbpParam(),
      websiteUrlYplaceParam: this.validateWebsiteUrlYplaceParam(),
      postalCode: this.validatePostalCode(),
      primaryPhone: this.validatePrimaryPhone(),
      additionalPhones: this.validateAdditionalPhones(),
      administrativeArea: this.validateAdministrativeArea(),
      address1: this.validateAddress1(),
      address2: this.validateAddress2(),
      primaryCategory: this.primaryCategory.validateCategory(),
      additionalCategories: this.validateAdditionalCategories(),
      yahooPlaceCategory: this.validateYahooPlaceCategories(),
      profile: this.profile.validateDescription(isConnectedGBP),
      attributes: this.attributes.validateAttributes(),
      urlAttributes: this.urlAttributes.validateAttributes(),
      snsLink: this.validateSNSLink(),
      yahooPlacePayment: this.validateYahooPlacePayment(),
      yahooPlaceBusiness: this.validateYahooPlaceBusiness(),
    };
    return errors;
  }

  getLocationWarning(isConnectedYahooPlace: boolean = false) {
    return {
      phone: this.getPhoneWarning(isConnectedYahooPlace),
      regularHours: this.getRegularHoursWarning(isConnectedYahooPlace),
      specialHours: this.getSpecialHoursWarning(isConnectedYahooPlace),
      profile: this.profile.getDescriptionWarning(isConnectedYahooPlace),
    };
  }

  changePrimaryCategory(value: string) {
    return this.update('primaryCategory', (primaryCategory) => primaryCategory.changeCategoryId(value));
  }

  updatePrimaryCategoryDetail(value: GmbLocationCategory) {
    return this.set('primaryCategory', value);
  }

  addAdditionalCategory() {
    return this.update('additionalCategories', (additionalCategories) => additionalCategories.addCategory());
  }

  changeAdditionalCategory(index: number, value: string) {
    return this.update('additionalCategories', (additionalCategories) =>
      additionalCategories.changeCategories(index, value),
    );
  }

  removeAdditionalCategory(index: number) {
    return this.set('additionalCategories', this.additionalCategories.removeCategory(index));
  }

  changeYahooPlaceCategories(value: string[]) {
    const [mainCategoryId, ...subCategoryIds] = value;
    return this.mergeIn(['connectedServices', 'yahooPlace'], {
      mainCategoryId: mainCategoryId || null,
      subCategoryIds: List(subCategoryIds),
    });
  }

  changeYahooPlaceMainCategory(value: string) {
    return this.setIn(['connectedServices', 'yahooPlace', 'mainCategoryId'], value);
  }

  addYahooPlaceSubCategory() {
    return this.setIn(
      ['connectedServices', 'yahooPlace', 'subCategoryIds'],
      this.connectedServices.yahooPlace.subCategoryIds.push(''),
    );
  }

  changeYahooPlaceSubCategory(index: number, value: string) {
    return this.setIn(['connectedServices', 'yahooPlace', 'subCategoryIds', index], value);
  }

  removeYahooPlaceSubCategory(index: number) {
    return this.setIn(
      ['connectedServices', 'yahooPlace', 'subCategoryIds'],
      this.connectedServices.yahooPlace.subCategoryIds.delete(index),
    );
  }

  changeYahooPlacePayment(value: YahooPlacePayment) {
    return this.setIn(['connectedServices', 'yahooPlace', 'payment'], value);
  }

  changeYahooPlaceBusiness(value: YahooPlaceBusiness) {
    return this.setIn(['connectedServices', 'yahooPlace', 'business'], value);
  }

  validatePrimaryPhone() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.primaryPhone && !validatePhoneNumber(this.primaryPhone)) {
      result = {
        isValid: false,
        error: ErrorType.PHONE_ERROR,
      };
    }
    return result;
  }

  validateAdditionalPhones() {
    const result = this.additionalPhonesForEdit.map((phone) => {
      if (phone && !validatePhoneNumber(phone)) {
        return { isValid: false, error: ErrorType.PHONE_ERROR };
      }
      return { isValid: true, error: '' };
    });
    return result;
  }

  validateWebsiteUrl() {
    let result = {
      isValid: true,
      error: '',
    };

    if (this.websiteUrl && !validateDomainWithSlash(this.websiteUrl)) {
      result = {
        isValid: false,
        error: ErrorType.WEBSITE_URL_DOMAIN_NAME_MUST_END_WITH_SLASH_ERROR,
      };
    }

    if (this.websiteUrl && !validateWebsiteUrl(this.websiteUrl)) {
      result = {
        isValid: false,
        error: ErrorType.WEBSITE_URL_ERROR,
      };
    }

    return result;
  }

  validateAdditionalCategories() {
    // 重複したカテゴリーが設定されている
    if (this.additionalCategories.list.size > 0) {
      let categoryIds = this.additionalCategories.list.map((category) => category.categoryId);
      if (this.primaryCategory.categoryId) {
        categoryIds = categoryIds.push(this.primaryCategory.categoryId);
      }
      if (categoryIds.size !== categoryIds.toSet().size) {
        return {
          isValid: false,
          error: ErrorType.CATEGORIES_DUPLICATION_ERROR,
        };
      }
    }
    return {
      isValid: true,
      error: '',
    };
  }

  /**
   * Yahoo! プレイス 業種カテゴリのバリデーション
   * @return {Object} バリデーション結果
   */
  validateYahooPlaceCategories() {
    let result = {
      isValid: true,
      error: '',
    };
    // メインカテゴリーが設定されていない
    if (!this.connectedServices.yahooPlace.mainCategoryId) {
      result = {
        isValid: false,
        error: ErrorType.YAHOO_PLACE_CATEGORIES_MAIN_CATEGORY_EMPTY_ERROR,
      };
    }
    // サブカテゴリーは最大3件まで
    if (this.connectedServices.yahooPlace.subCategoryIds.size > 3) {
      result = {
        isValid: false,
        error: ErrorType.YAHOO_PLACE_CATEGORIES_LENGTH_ERROR,
      };
    }
    // 重複したカテゴリーが設定されている
    if (this.connectedServices.yahooPlace.subCategoryIds.size > 0) {
      const selectingCategoryIds = [
        this.connectedServices.yahooPlace.mainCategoryId,
        ...this.connectedServices.yahooPlace.subCategoryIds.toArray(),
      ];
      if (selectingCategoryIds.length !== Set(selectingCategoryIds).size) {
        result = {
          isValid: false,
          error: ErrorType.CATEGORIES_DUPLICATION_ERROR,
        };
      }
    }
    // 確認OK
    return result;
  }

  validateYahooPlacePayment() {
    const result = {
      isValid: true,
      error: '',
    };
    if (!this.connectedServices.yahooPlace.payment.isValid()) {
      return {
        isValid: false,
        error: ErrorType.YAHOO_PLACE_PAYMENT_CASH_ONLY_ERROR,
      };
    }
    return result;
  }

  validateYahooPlaceBusiness() {
    const result = {
      isValid: true,
      error: '',
    };
    if (!this.connectedServices.yahooPlace.business.isValid()) {
      return {
        isValid: false,
        error: ErrorType.YAHOO_PLACE_BUSINESS_ERROR,
      };
    }
    return result;
  }

  validateWebsiteUrlGbpParam() {
    let result = {
      isValid: true,
      error: '',
    };
    if (
      this.connectedServices.gbp.websiteUrlParam &&
      !validateWebsiteUrlParam(this.connectedServices.gbp.websiteUrlParam)
    ) {
      result = {
        isValid: false,
        error: ErrorType.WEBSITE_URL_PARAM_ERROR,
      };
    }
    return result;
  }

  validateWebsiteUrlYplaceParam() {
    let result = {
      isValid: true,
      error: '',
    };
    if (
      this.connectedServices.yahooPlace.websiteUrlParam &&
      !validateWebsiteUrlParam(this.connectedServices.yahooPlace.websiteUrlParam)
    ) {
      result = {
        isValid: false,
        error: ErrorType.WEBSITE_URL_PARAM_ERROR,
      };
    }
    return result;
  }

  validateBusinessHours() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.regularHours && !this.regularHours.validate.isValid) {
      result = {
        isValid: false,
        error: this.regularHours.validate.error,
      };
    }
    return result;
  }

  validateAdministrativeArea() {
    let result = {
      isValid: true,
      error: '',
    };

    if (!this.address || !this.address.administrativeArea) {
      result = {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return result;
  }

  validateSpecialHours() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.specialHours && !this.specialHours.validate.isValid) {
      result = {
        isValid: false,
        error: this.specialHours.validate.error,
      };
    }
    return result;
  }
  validatePostalCode() {
    if (!this.address || !this.address.postalCode) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    if (!validatePostalCode(this.address.postalCode)) {
      return {
        isValid: false,
        error: ErrorType.POSTAL_CODE_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  validateSNSLink() {
    const gbpSNSItems = SNSItems.createByGmbUrlAttributes(this.urlAttributes);
    const yahooPlaceSNSItems = SNSItems.createByYahooPlaceSNSLink(this.connectedServices.yahooPlace.snsLink);
    const snsItems = gbpSNSItems.mergeItems(yahooPlaceSNSItems);
    const isValid = snsItems.list.every((item) => item.validateUrl().isValid);

    if (isValid) {
      return {
        isValid: true,
        error: '',
      };
    } else {
      return {
        isValid: false,
        error: ErrorType.SNS_LINK_ERROR,
      };
    }
  }

  validateAddress1() {
    if (!this.address || !this.address.address1) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  validateAddress2() {
    return {
      isValid: true,
      error: '',
    };
  }

  validateLocationNameKana(isConnectedYahooPlace: boolean = false) {
    // [Yahooプレイス連携時] 店舗名（フリガナ）は必須
    if (isConnectedYahooPlace && this.locationNameKana.trim().length === 0) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    // 店舗名（フリガナ）は全角カタカナまたはスペースのみ
    if (this.locationNameKana.trim().length > 0 && !LOCATION_NAME_KANA_PATTERN.test(this.locationNameKana)) {
      return {
        isValid: false,
        error: ErrorType.LOCATION_NAME_KANA_ERROR,
      };
    }
    // [Yahooプレイス連携時] 店舗名（フリガナ）は60文字以内
    if (isConnectedYahooPlace && this.locationNameKana.trim().length > 60) {
      return {
        isValid: false,
        error: ErrorType.LOCATION_NAME_KANA_LENGTH_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  getPhoneWarning(isConnectedYahooPlace: boolean = false) {
    if (isConnectedYahooPlace) {
      // [Yahooプレイス連携時] 電話番号が2件以上登録されている
      if (
        (this.primaryPhone.length > 0 && this.additionalPhones.filter((phone) => phone.length > 0).size >= 1) ||
        this.additionalPhones.filter((phone) => phone.length > 0).size > 1
      ) {
        return WarningType.MULTIPLE_PHONES_WARNING;
      }
    }
    return '';
  }

  getRegularHoursWarning(isConnectedYahooPlace: boolean = false) {
    // [Yahooプレイス連携時] 各曜日の営業時間において、設定されているTimePeriodの数が4件以上設定されている曜日がある
    if (isConnectedYahooPlace) {
      if (this.regularHours.maxPeriodsCount > 3) {
        return WarningType.BUSINESS_HOUR_PERIODS_COUNT_WARNING;
      }
    }
    return '';
  }

  getSpecialHoursWarning(isConnectedYahooPlace: boolean = false) {
    // [Yahooプレイス連携時] 特別営業時間に営業日または休業日が11件以上設定されている
    if (isConnectedYahooPlace) {
      return this.specialHours.warningForYahooPlace;
    }
    return '';
  }

  updatePrimaryPhoneParams() {
    return { primaryPhone: this.primaryPhone };
  }

  updateAdditionalPhonesParams() {
    return {
      additionalPhones: this.additionalPhones.isEmpty()
        ? null // Googleビジネスプロフィール の差分検知と合わせるため
        : this.additionalPhones.filter((phone) => phone).toArray(),
    };
  }

  updatePhoneParams() {
    return { ...this.updatePrimaryPhoneParams(), ...this.updateAdditionalPhonesParams() };
  }

  updateWebsiteUrlParams() {
    return { websiteUrl: this.websiteUrl, connectedServices: this.connectedServices.updateParams };
  }

  updateRegularHoursParams() {
    return { regularHours: this.regularHours.updateParams };
  }

  updateSpecialHoursParams() {
    return { specialHours: this.specialHours.updateParams };
  }

  updateMoreHoursParams() {
    return { moreHours: this.moreHours.updateParams };
  }

  updatePrimaryCategoryParams() {
    return { primaryCategory: this.primaryCategory.updateParams };
  }

  updateAdditionalCategoriesParams() {
    return {
      additionalCategories: this.additionalCategories.list
        .filter((target) => !target.isEmpty)
        .map((target) => {
          return {
            categoryId: target.categoryId,
          };
        })
        .toArray(),
    };
  }

  updateCategoriesParams() {
    return {
      ...this.updatePrimaryCategoryParams(),
      ...this.updateAdditionalCategoriesParams(),
    };
  }

  updateYahooPlaceCategoriesParams() {
    return { connectedServices: this.connectedServices.updateParams };
  }

  updateAttributesParams() {
    return { attributes: [...this.attributes.updateParams, ...this.urlAttributes.updateParams] };
  }

  updateOpenInfoParams() {
    return this.openInfo.updateParams();
  }

  updateOpeningDateParams() {
    return {
      openingDate: this.openingDate?.updateParams() ?? null,
    };
  }

  updateProfileParams() {
    return this.profile.updateParams();
  }

  updateAddressParams() {
    return this.address.updateParams();
  }

  updateGmbLatlngParams() {
    return this.latlng.updateParams();
  }
}
