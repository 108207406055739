import React, { useMemo, useState } from 'react';

import { Virtuoso } from 'react-virtuoso';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { DropdownOverride } from 'components/atoms/PullDown';

interface DropdownItem {
  value: string;
  text: string;
}

interface Props {
  items: DropdownItem[];
  placeholder?: string;
  value: string | null;
  onSelect: (value: string) => void;
  className?: string;
}

const ITEM_HEIGHT = 57;
const DISPLAY_ITEM_COUNT = 5;

const VirtualizedDropdown: React.FC<Props> = ({ className, items, value, placeholder, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const options = useMemo(() => {
    return items.filter((item) => item.text.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
  }, [items, searchValue]);

  const itemCount = options.length;
  // 表示領域の高さ。最大表示数を超えた場合は最大表示数分+次が少し見える高さで表示する
  const height = Math.min(itemCount * ITEM_HEIGHT, ITEM_HEIGHT * DISPLAY_ITEM_COUNT + ITEM_HEIGHT / 2);

  return (
    <>
      <DropdownOverride
        className={className}
        value={value}
        placeholder={placeholder}
        search
        selection
        options={options}
        onSearchChange={(_: any, data: any) => setSearchValue(data.searchQuery)}
        closeOnChange={true}
        open={isOpen}
        onLabelClick={() => setIsOpen(!isOpen)}
        onFocus={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <Dropdown.Menu>
          {options.length === 0 ? (
            <Item disabled>一致しませんでした</Item>
          ) : (
            <Virtuoso
              style={{ height: `${height}px` }}
              totalCount={options.length}
              itemContent={(index) => {
                return (
                  <Item
                    key={index}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onSelect(options[index].value);
                      setIsOpen(false);
                    }}
                  >
                    {options[index].text}
                  </Item>
                );
              }}
            />
          )}
        </Dropdown.Menu>
      </DropdownOverride>
    </>
  );
};

const Item = styled(Dropdown.Item)`
  &&& {
    font-size: 16px;
    font-weight: normal;
    padding: 20px 16px;
    cursor: pointer;

    i {
      margin-right: 10px;
    }

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`;

export default React.memo(VirtualizedDropdown);
