import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import ErrorType from 'helpers/errorType';
import { validateWebsiteUrl } from 'helpers/utils';

import { GmbUrlAttribute, GmbUrlAttributes } from './GmbLocation/GmbAttributes';
import { YahooPlaceSNSLink } from './YahooPlace/SNSLink';

export const SNSServices = [
  'facebook',
  'instagram',
  'tiktok',
  'twitter',
  'youtube',
  'pinterest',
  'linkedin',
  'line',
] as const;

export type SNSServiceType = (typeof SNSServices)[number];

// YahooPlaceで利用可能なSNSServices
export const YahooPlaceSNSServices = [
  'facebook',
  'instagram',
  'tiktok',
  'twitter',
  'youtube',
  'line',
] as const satisfies SNSServiceType[];

// GBPで利用可能なSNSServices
export const GBPSNSServices = [
  'facebook',
  'instagram',
  'tiktok',
  'twitter',
  'youtube',
  'pinterest',
  'linkedin',
] as const satisfies SNSServiceType[];

/**
 * GBPの属性IDを取得
 * @param service
 */
const getAttributeId = (service: SNSServiceType) => {
  // GBPの属性IDはurl_{service}の形式
  return (GBPSNSServices as readonly SNSServiceType[]).includes(service) ? `url_${service}` : null;
};

/**
 * サービス名を取得
 * @param service
 */
export const getSNSServiceName = (service: SNSServiceType) => {
  switch (service) {
    case 'facebook':
      return 'Facebook';
    case 'instagram':
      return 'Instagram';
    case 'tiktok':
      return 'TikTok';
    case 'twitter':
      return 'X(Twitter)';
    case 'youtube':
      return 'YouTube';
    case 'pinterest':
      return 'Pinterest';
    case 'linkedin':
      return 'LinkedIn';
    case 'line':
      return 'LINE';
    default:
      return service satisfies never;
  }
};

export class SNSItem extends ImmutableRecord<{
  service: SNSServiceType | '';
  url: string;
}>({
  service: '',
  url: '',
}) {
  validateUrl(): { isValid: boolean; error?: string } {
    if (!this.service || (this.url && !validateWebsiteUrl(this.url))) {
      return {
        isValid: false,
        error: ErrorType.SNS_LINK_ERROR,
      };
    }
    // ソーシャル メディアのリンクの形式
    // https://support.google.com/business/answer/13580646?sjid=10890649764224931379-AP
    switch (this.service) {
      case 'facebook': {
        // ユーザーネームに使用できる文字は、英数字(a～z、0～9)とピリオド(「.」)のみ
        // ユーザーネームは5文字以上とします
        // ピリオド(「.」)や、大文字と小文字の違いによってユーザーネームを区別することはできません。例えば、johnsmith55、John.Smith55、john.smith.55はすべて同じユーザーネームとみなされます。
        // 参考 https://www.facebook.com/help/1740158369563165
        const pattern = new RegExp(/^https:\/\/(?:www\.)?facebook\.com\/([^\\/]+)$/);
        const match = this.url.match(pattern);
        if (!match) {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_FACEBOOK_ERROR,
          };
        }
        const userName = match.at(1)?.replaceAll('.', '') ?? ''; // ピリオドは区別に利用されないので除外する
        const pattern2 = new RegExp(/^[0-9a-zA-Z\\.]{5,}$/);
        if (pattern2.test(userName)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_FACEBOOK_ERROR,
          };
        }
      }
      case 'instagram': {
        // 半角英数字とピリオド（.）、アンダースコア(_)
        // 最大30文字まで
        // ※「.（ピリオド）」は先頭と最後につけられない.
        // 参考 https://www.catasumisns.com/basiciinfo/instagramusername
        const pattern = new RegExp(/^https:\/\/(?:www\.)?instagram\.com\/(?:\w|\w[\w\\.]{0,28}\w)\/?$/);
        if (pattern.test(this.url)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_INSTAGRAM_ERROR,
          };
        }
      }
      case 'linkedin': {
        // カスタムURLの長さは3～100文字で、スペース、記号、特殊文字、LinkedInという語を含めることはできません。
        // 参考 https://www.linkedin.com/help/linkedin/answer/a542685/manage-your-public-profile-url
        const pattern = new RegExp(/^https:\/\/(?:www\.)?linkedin\.com\/(?:in|company)\/[0-9a-zA-Z]{3,100}$/);
        if (pattern.test(this.url)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_LINKEDIN_ERROR,
          };
        }
      }
      case 'pinterest': {
        // ・文字のみの名前、あるいは文字、数字、下線を組み合わせた名前
        // ・3～30 文字
        // ・数字だけにすることはできない
        // 参考 https://help.pinterest.com/ja/article/edit-your-profile
        const pattern = new RegExp(/^https:\/\/(?:www\.)?pinterest\.(?:com|jp)\/\w{3,30}\/?$/);
        const match = this.url.match(pattern);
        if (!match) {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_PINTEREST_ERROR,
          };
        }
        const userName = match.at(1) ?? ''; // ピリオドは区別に利用されないので除外する
        const pattern2 = new RegExp(/\d+$/); // 数字だけのパターン
        if (!pattern2.test(userName)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_PINTEREST_ERROR,
          };
        }
      }
      case 'tiktok': {
        // 文字、数字、アンダースコア、ピリオドのみ
        // ピリオドをユーザー名の末尾に使用することはできない
        // 参考 https://support.tiktok.com/ja/getting-started/setting-up-your-profile/changing-your-username
        const pattern = new RegExp(/^https:\/\/(?:www\.)?tiktok\.com\/@[\w\\.]+\w$/);
        if (pattern.test(this.url)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_TIKTOK_ERROR,
          };
        }
      }
      case 'twitter': {
        // ユーザー名の長さは15文字まで
        // 英数字（文字A～Z、数字0～9）とアンダースコア（_）を利用可能
        // 参考 https://help.twitter.com/en/managing-your-account/x-username-rules
        const pattern = new RegExp(/^https:\/\/(?:www\.)?(?:twitter|x)\.com\/\w{1,15}$/);
        if (pattern.test(this.url)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_TWITTER_ERROR,
          };
        }
      }
      case 'youtube': {
        // 3～30 文字
        // 英数字（A～Z、a～z、1～9）で構成されていること
        // ハンドルには、アンダースコア（_）、ハイフン（-）、ピリオド（.）も使用可能
        // 参考 https://support.google.com/youtube/answer/11585688?sjid=8529101755235285746-AP
        const pattern = new RegExp(/^https:\/\/(?:www\.)?youtube\.com\/(?:channel\/|user\/|@)[\w\\.-]{3,30}$/);
        if (pattern.test(this.url)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_YOUTUBE_ERROR,
          };
        }
      }
      case 'line': {
        // 4〜18文字以下
        // 半角英数字、ハイフン、アンダースコア、ピリオドのみ
        // 参考：https://www.lycbiz.com/jp/service/line-official-account/plan/
        const pattern = new RegExp(/^https:\/\/line\.me\/R\/ti\/p\/@[\w_.-]{4,18}$/);
        if (pattern.test(this.url)) {
          return { isValid: true };
        } else {
          return {
            isValid: false,
            error: ErrorType.ATTRIBUTE_SNS_URL_LINE_ERROR,
          };
        }
      }
      default:
        return this.service satisfies never;
    }
  }

  toGmbUrlAttribute(): GmbUrlAttribute | null {
    if (!this.service || !this.url) {
      return null;
    }
    const attributeId = getAttributeId(this.service);
    if (!attributeId) {
      return null;
    }
    return new GmbUrlAttribute({
      attributeId,
      valueType: 'URL',
      urlValues: ImmutableList([{ url: this.url }]),
    });
  }
}

export class SNSItems extends ImmutableRecord<{ list: ImmutableList<SNSItem> }>({
  list: ImmutableList<SNSItem>(),
}) {
  // 初期値として空のSNSItemを追加したものを生成
  static createEmpty() {
    return new SNSItems({ list: ImmutableList([new SNSItem()]) });
  }

  // GMBの属性からSNSItemsを生成
  static createByGmbUrlAttributes(attributes: GmbUrlAttributes) {
    return new SNSItems({
      list: ImmutableList(
        GBPSNSServices.map((service) => {
          const attributeId = getAttributeId(service);
          const attribute = attributes.list.find((attr) => attr.attributeId === attributeId);
          return new SNSItem({
            service,
            url: attribute?.urlValues.get(0)?.url,
          });
        }),
      ).filter((item) => !!item.url),
    });
  }

  // YahooPlaceSNSLinkからSNSItemsを生成
  static createByYahooPlaceSNSLink(yahooPlaceSNSLink: YahooPlaceSNSLink) {
    return new SNSItems({
      list: ImmutableList(
        YahooPlaceSNSServices.map((service) => {
          return new SNSItem({
            service,
            url: yahooPlaceSNSLink.get(service),
          });
        }),
      ).filter((item) => !!item.url),
    });
  }

  get services() {
    return this.list.map((item) => item.service);
  }

  /**
   * SNSItemを追加
   */
  addItem() {
    return this.update('list', (list) => list.push(new SNSItem()));
  }

  /**
   * SNSItemのserviceを更新
   * @param index
   * @param service
   */
  updateService(index: number, service: SNSServiceType | '') {
    return this.setIn(['list', index, 'service'], service);
  }

  /**
   * SNSItemのurlを更新
   * @param index
   * @param url
   */
  updateUrl(index: number, url: string) {
    return this.setIn(['list', index, 'url'], url);
  }

  /**
   * SNSItemを削除
   * @param index
   */
  removeItem(index: number) {
    return this.update('list', (list) => list.remove(index));
  }

  get size() {
    return this.list.size;
  }

  /**
   * SNSItemをserviceで検索
   * @param service
   */
  findItemByService(service: SNSServiceType) {
    return this.list.find((item) => item.service === service);
  }

  /**
   * SNSItemのurlをserviceで検索
   * @param service
   */
  getUrlByService(service: SNSServiceType) {
    return this.findItemByService(service)?.url;
  }

  /**
   * YahooPlaceSNSLinkに変換
   */
  toYahooPlaceSNSLink() {
    return new YahooPlaceSNSLink({
      facebook: this.getUrlByService('facebook') ?? '',
      instagram: this.getUrlByService('instagram') ?? '',
      tiktok: this.getUrlByService('tiktok') ?? '',
      twitter: this.getUrlByService('twitter') ?? '',
      line: this.getUrlByService('line') ?? '',
      youtube: this.getUrlByService('youtube') ?? '',
    });
  }

  /**
   * GMBの属性に変換
   */
  toGmbUrlAttributes(complementedAttributes: GmbUrlAttributes) {
    // complementedAttributesにはSNS以外のURL属性も含まれているので、SNSの値のみ上書きする
    return new GmbUrlAttributes({
      list: complementedAttributes.list.map((attribute) => {
        const service = attribute.attributeId.replace('url_', '');
        // SNS以外の属性はそのまま返す
        if (!SNSServices.includes(service as SNSServiceType)) {
          return attribute;
        }
        // SNSの属性はSNSItemsの値で上書きする、なければ空のURLを設定
        const item = this.findItemByService(service as SNSServiceType);
        return item?.toGmbUrlAttribute() ?? attribute.updateUrlAttribute(0, '');
      }),
    });
  }

  mergeItems(items: SNSItems) {
    return new SNSItems({
      list: items.list.reduce((acc, item) => {
        if (!acc.find((accItem) => accItem.service === item.service)) {
          return acc.push(item);
        }
        return acc;
      }, this.list),
    });
  }
}
