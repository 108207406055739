import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { Counter } from 'components/atoms/Counter';
import { Input } from 'components/atoms/Input';
import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { TextArea } from 'components/atoms/TextArea';
import { isNonNegativeInteger, toHalfWidth } from 'helpers/utils';
import {
  MAX_INFECTION_CONTROL_REMARKS_LENGTH,
  MAX_OTHER_SERVICE_DESCRIPTION_LENGTH,
  MAX_PARKING_CAPACITY,
  MAX_SEAT_CAPACITY,
  SmokingRuleType,
  YahooPlaceBusiness,
  YahooPlaceBusinessBooleanKey,
  smokingRuleTypes,
} from 'models/Domain/YahooPlace/Business';
import { SelectOption } from 'types/Common';

import { CheckboxWrapper, GroupItemWrapper, GroupLabel, GroupWrapper, StyledCheckbox } from './EditYahooPlace';

type Props = {
  business: YahooPlaceBusiness;
  onChange: (value: YahooPlaceBusiness) => void;
};

const getLabel = YahooPlaceBusiness.getKeyLabel;

const smokingRuleTypeOptions: SelectOption<SmokingRuleType>[] = smokingRuleTypes.map((smokingRuleType) => ({
  text: YahooPlaceBusiness.getSmokingRuleTypeLabel(smokingRuleType),
  value: smokingRuleType,
}));

const useCapacity = (
  key: 'seatCapacity' | 'parkingCapacity',
  onChange: (value: YahooPlaceBusiness) => void,
  business: YahooPlaceBusiness,
  initialValue: number | undefined,
) => {
  const [inputValue, setInputValue] = useState<string>(initialValue != null ? String(initialValue) : '');

  const handleChange = useCallback(
    (newInputValue: string) => {
      setInputValue(newInputValue);
      // 数値以外の文字列が入力された場合は、表示は入力値のまま、データを0扱いしてエラーを表示する
      const value = toHalfWidth(newInputValue).replace(',', '');
      if (isNonNegativeInteger(value)) {
        onChange(business.changeValue(key, Number(value)));
      } else if (newInputValue === '') {
        onChange(business.changeValue(key, undefined));
      } else {
        onChange(business.changeValue(key, 0));
      }
    },
    [business, key, onChange],
  );

  const handleBlur = useCallback(
    (newInputValue: string) => {
      // 数値以外の文字列が入力された場合は、表示もデータも空にする
      const value = toHalfWidth(newInputValue).replace(',', '');
      if (isNonNegativeInteger(value)) {
        onChange(business.changeValue(key, Number(value)));
        setInputValue(String(value));
      } else {
        onChange(business.changeValue(key, undefined));
        setInputValue('');
      }
    },
    [business, key, onChange],
  );

  const resetValue = useCallback(() => {
    setInputValue('');
  }, []);

  return [inputValue, handleChange, handleBlur, resetValue] as const;
};

const EditYahooPlaceBusiness: React.FC<Props> = ({ business, onChange }) => {
  const [inputSeatCapacity, handleChangeSeatCapacity, handleBlurSeatCapacity] = useCapacity(
    'seatCapacity',
    onChange,
    business,
    business.seatCapacity,
  );
  const [inputParkingCapacity, handleChangeParkingCapacity, handleBlurParkingCapacity, resetParkingCapacity] =
    useCapacity('parkingCapacity', onChange, business, business.parkingCapacity);

  const handleClickOptionalToggle = useCallback(
    (key: YahooPlaceBusinessBooleanKey) => () => {
      onChange(business.toggleOptionalValue(key));
    },
    [business, onChange],
  );

  const handleClickParkingAreaFlag = useCallback(() => {
    onChange(business.toggleOptionalValue('parkingAreaFlag'));
    resetParkingCapacity();
  }, [business, onChange, resetParkingCapacity]);

  const handleClickCheckbox = useCallback(
    (key: YahooPlaceBusinessBooleanKey) => () => {
      onChange(business.toggleValue(key));
    },
    [business, onChange],
  );

  const handleChangeSmokingRuleType = useCallback(
    (value: SmokingRuleType) => {
      onChange(business.changeSmokingRuleType(value || undefined));
    },
    [business, onChange],
  );

  return (
    <Wrapper>
      <GroupWrapper>
        <GroupLabel>学割</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('studentDiscount')}
              checked={business.studentDiscount}
              onClick={handleClickOptionalToggle('studentDiscount')}
            />
          </CheckboxWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>予約</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('reservationFlag')}
              checked={business.reservationFlag}
              onClick={handleClickOptionalToggle('reservationFlag')}
            />
          </CheckboxWrapper>
          {business.reservationFlag && (
            <CheckboxWrapper>
              {YahooPlaceBusiness.reservationOptions.map((key) => (
                <StyledCheckbox
                  key={key}
                  checked={business.get(key)}
                  label={getLabel(key)}
                  onChange={handleClickCheckbox(key)}
                />
              ))}
            </CheckboxWrapper>
          )}
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>デリバリー</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('deliveryServiceFlag')}
              checked={business.deliveryServiceFlag}
              onClick={handleClickOptionalToggle('deliveryServiceFlag')}
            />
          </CheckboxWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>座席</GroupLabel>
        <GroupItemWrapper>
          <InputWrapper>
            <InputLabel>{getLabel('seatCapacity')}</InputLabel>
            <StyledInput
              value={inputSeatCapacity}
              onChange={handleChangeSeatCapacity}
              onBlur={() => handleBlurSeatCapacity(inputSeatCapacity)}
              error={business.validate('seatCapacity').error || undefined}
              placeholder={`1〜${MAX_SEAT_CAPACITY}`}
            />
          </InputWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>貸切</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('isEntireReservationAvailable')}
              checked={business.isEntireReservationAvailable}
              onClick={handleClickOptionalToggle('isEntireReservationAvailable')}
            />
          </CheckboxWrapper>
          {business.isEntireReservationAvailable && (
            <CheckboxWrapper>
              {YahooPlaceBusiness.entireReservationOptions.map((key) => (
                <StyledCheckbox
                  key={key}
                  checked={business.get(key)}
                  label={getLabel(key)}
                  onChange={handleClickCheckbox(key)}
                />
              ))}
            </CheckboxWrapper>
          )}
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>個室</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('isPrivateRoomAvailable')}
              checked={business.isPrivateRoomAvailable}
              onClick={handleClickOptionalToggle('isPrivateRoomAvailable')}
            />
          </CheckboxWrapper>
          {business.isPrivateRoomAvailable && (
            <CheckboxWrapper>
              {YahooPlaceBusiness.privateRoomOptions.map((key) => (
                <StyledCheckbox
                  key={key}
                  checked={business.get(key)}
                  label={getLabel(key)}
                  onChange={handleClickCheckbox(key)}
                />
              ))}
            </CheckboxWrapper>
          )}
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>喫煙</GroupLabel>
        <GroupItemWrapper>
          <PullDownWrapper>
            <StyledPullDown
              value={business.smokingRuleType}
              options={smokingRuleTypeOptions}
              onChange={handleChangeSmokingRuleType}
              clearable={true}
              placeholder={'選択してください'}
            />
          </PullDownWrapper>
          {business.smokingRuleType === '2' && (
            <CheckboxWrapper>
              {YahooPlaceBusiness.smokingRuleOptions.map((key) => (
                <StyledCheckbox
                  key={key}
                  checked={business.get(key)}
                  label={getLabel(key)}
                  onChange={handleClickCheckbox(key)}
                />
              ))}
            </CheckboxWrapper>
          )}
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>駐車場</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('parkingAreaFlag')}
              checked={business.parkingAreaFlag}
              onClick={handleClickParkingAreaFlag}
            />
          </CheckboxWrapper>
          {business.parkingAreaFlag && (
            <InputWrapper>
              <InputLabel>{getLabel('parkingCapacity')}</InputLabel>
              <StyledInput
                value={inputParkingCapacity}
                onChange={handleChangeParkingCapacity}
                onBlur={() => handleBlurParkingCapacity(inputParkingCapacity)}
                error={business.validate('parkingCapacity').error ?? undefined}
                placeholder={`1〜${MAX_PARKING_CAPACITY}`}
              />
            </InputWrapper>
          )}
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>コインパーキング</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('coinParkingAreaFlag')}
              checked={business.coinParkingAreaFlag}
              onClick={handleClickOptionalToggle('coinParkingAreaFlag')}
            />
          </CheckboxWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>バリアフリー</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('barrierFreeFlag')}
              checked={business.barrierFreeFlag}
              onClick={handleClickOptionalToggle('barrierFreeFlag')}
            />
          </CheckboxWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>子ども</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('childFriendlyFlag')}
              checked={business.childFriendlyFlag}
              onClick={handleClickOptionalToggle('childFriendlyFlag')}
            />
            <OptionalToggle
              label={getLabel('kidsSpaceFlag')}
              checked={business.kidsSpaceFlag}
              onClick={handleClickOptionalToggle('kidsSpaceFlag')}
            />
          </CheckboxWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>ペット</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('petFlag')}
              checked={business.petFlag}
              onClick={handleClickOptionalToggle('petFlag')}
            />
          </CheckboxWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>衛生対策</GroupLabel>
        <GroupItemWrapper>
          <CheckboxWrapper>
            <OptionalToggle
              label={getLabel('infectionControlSanitaryMeasuresTakenFlag')}
              checked={business.infectionControlSanitaryMeasuresTakenFlag}
              onClick={handleClickOptionalToggle('infectionControlSanitaryMeasuresTakenFlag')}
            />
          </CheckboxWrapper>
          {business.infectionControlSanitaryMeasuresTakenFlag && (
            <CheckboxWrapper>
              {YahooPlaceBusiness.infectionControlOptions.map((key) => (
                <StyledCheckbox
                  key={key}
                  checked={business.get(key)}
                  label={getLabel(key)}
                  onChange={handleClickCheckbox(key)}
                />
              ))}
            </CheckboxWrapper>
          )}
          {business.infectionControlSanitaryMeasuresTakenFlag && (
            <TextWrapper>
              <InputLabel>{getLabel('infectionControlRemarks')}</InputLabel>
              <StyledTextArea
                value={business.infectionControlRemarks}
                onChange={(value) => onChange(business.changeValue('infectionControlRemarks', value))}
                error={business.validate('infectionControlRemarks').error ?? undefined}
              />
              <StyledTextCount
                size={business.infectionControlRemarks?.length ?? 0}
                maxSize={MAX_INFECTION_CONTROL_REMARKS_LENGTH}
              />
            </TextWrapper>
          )}
        </GroupItemWrapper>
      </GroupWrapper>
      <GroupWrapper>
        <GroupLabel>{getLabel('otherServiceDescription')}</GroupLabel>
        <GroupItemWrapper>
          <TextWrapper>
            <StyledTextArea
              value={business.otherServiceDescription}
              onChange={(value) => onChange(business.set('otherServiceDescription', value))}
              error={business.validate('otherServiceDescription').error ?? undefined}
              placeholder={'（例）Wi-Fi完備、電源あり'}
            />
            <StyledTextCount
              size={business.otherServiceDescription?.length ?? 0}
              maxSize={MAX_OTHER_SERVICE_DESCRIPTION_LENGTH}
            />
          </TextWrapper>
        </GroupItemWrapper>
      </GroupWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TextWrapper = styled.div`
  width: 100%;
`;

const StyledInput = styled(Input)`
  &&& {
    flex: 1;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const InputLabel = styled.div`
  font-size: 13px;
  margin-bottom: 4px;
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const PullDownWrapper = styled.div`
  min-width: 200px;
`;

const StyledPullDown = styled(PullDownNarrow)`
  &&& {
    & .ui.search.selection.dropdown {
      width: 100%;
    }
  }
`;

export default React.memo(EditYahooPlaceBusiness);
