import styled from 'styled-components';

import { StyledCheckbox as Checkbox } from '../StoreIndex/StoreTable';

// Yahoo!プレイス系の編集画面で共通で使用するコンポーネント

export const GroupWrapper = styled.div`
  padding: 25px 0 18px;
  :not(:last-child) {
    border-bottom: 1px solid rgba(181, 181, 181, 0.5);
  }
`;

export const GroupLabel = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
`;

export const GroupItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledCheckbox = styled(Checkbox)`
  &&& {
    line-height: 25px;
    font-size: 14px;
    label {
      &:after {
        top: 0;
      }
    }
  }
`;
