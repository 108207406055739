import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { YahooPlaceBusiness, YahooPlaceBusinessJSON } from '../YahooPlace/Business';
import { YahooPlacePayment, YahooPlacePaymentJSON } from '../YahooPlace/Payment';
import { YahooPlaceSNSLink, YahooPlaceSNSLinkJSON } from '../YahooPlace/SNSLink';

type STConnectedServiceCommonValueRecord = {
  websiteUrlParam: string;
};

type STConnectedServiceValueGBPRecord = STConnectedServiceCommonValueRecord;
type STConnectedServiceValueYahooPlaceRecord = STConnectedServiceCommonValueRecord & {
  mainCategoryId: string | null;
  subCategoryIds: ImmutableList<string>;
  snsLink: YahooPlaceSNSLink;
  payment: YahooPlacePayment;
  business: YahooPlaceBusiness;
};
type STConnectedServiceValueABCRecord = STConnectedServiceCommonValueRecord;

type STConnectedServiceValueGBPData = {
  websiteUrlParam: string;
};
type STConnectedServiceValueYahooPlaceData = {
  websiteUrlParam: string;
  categoryIds: string[];
  sns: YahooPlaceSNSLinkJSON;
  payment: YahooPlacePaymentJSON;
  business: YahooPlaceBusinessJSON;
};
type STConnectedServiceValueABCData = {
  websiteUrlParam: string;
};

export class STConnectedServiceValueGBP extends ImmutableRecord<STConnectedServiceValueGBPRecord>({
  websiteUrlParam: '',
}) {
  static fromJSON(data: STConnectedServiceValueGBPData) {
    return new STConnectedServiceValueGBP(data);
  }

  get updateParams() {
    return {
      websiteUrlParam: this.websiteUrlParam,
    };
  }
}
export class STConnectedServiceValueYahooPlace extends ImmutableRecord<STConnectedServiceValueYahooPlaceRecord>({
  websiteUrlParam: '',
  mainCategoryId: null,
  subCategoryIds: ImmutableList<string>(),
  snsLink: new YahooPlaceSNSLink(),
  payment: new YahooPlacePayment(),
  business: new YahooPlaceBusiness(),
}) {
  static fromJSON(data: STConnectedServiceValueYahooPlaceData) {
    const categoryIds = data.categoryIds ?? [];
    // categoryIdsの1番目はmainCategoryId, それ以降はsubCategoryIds
    const [mainCategoryId, ...subCategoryIds] = categoryIds;
    return new STConnectedServiceValueYahooPlace({
      websiteUrlParam: data.websiteUrlParam,
      mainCategoryId: mainCategoryId || null,
      subCategoryIds: ImmutableList(subCategoryIds),
      snsLink: YahooPlaceSNSLink.fromJSON(data.sns || {}),
      payment: YahooPlacePayment.fromJSON(data.payment || {}),
      business: YahooPlaceBusiness.fromJSON(data.business || {}),
    });
  }

  get updateParams() {
    return {
      websiteUrlParam: this.websiteUrlParam,
      categoryIds: this.categoryIds.toArray(),
      sns: this.snsLink.updateParams(),
      payment: this.payment.updateParams(),
      business: this.business.updateParams(),
    };
  }

  get categoryIds(): ImmutableList<string> {
    // リストの先頭をメインカテゴリ扱いするため省略不可なので未設定でも空文字
    const mainCategory = this.mainCategoryId ?? '';
    // サブカテゴリは未設定の場合は省略、重複は除外する
    const subCategories = this.subCategoryIds
      .filter((x) => !!x && x !== mainCategory)
      .toOrderedSet()
      .toList();
    // メインカテゴリーとサブカテゴリーのどちらも設定されていなければ空のリストを返す
    if (!mainCategory && subCategories.size === 0) {
      return ImmutableList<string>();
    }
    // メインカテゴリ→サブカテゴリの順のリストにして返す
    return subCategories.unshift(mainCategory);
  }

  get hasCategory() {
    // メインカテゴリーかサブカテゴリーのどちらかが設定されていればtrue
    return this.categoryIds.size > 0;
  }
}
export class STConnectedServiceValueABC extends ImmutableRecord<STConnectedServiceValueABCRecord>({
  websiteUrlParam: '',
}) {
  static fromJSON(json: STConnectedServiceValueABCData) {
    return new STConnectedServiceValueABC(json);
  }

  get updateParams() {
    return {
      websiteUrlParam: this.websiteUrlParam,
    };
  }
}

export class STConnectedServices extends ImmutableRecord<{
  gbp: STConnectedServiceValueGBP;
  abc: STConnectedServiceValueABC;
  yahooPlace: STConnectedServiceValueYahooPlace;
}>({
  gbp: new STConnectedServiceValueGBP(),
  abc: new STConnectedServiceValueABC(),
  yahooPlace: new STConnectedServiceValueYahooPlace(),
}) {
  get updateParams() {
    return {
      gbp: this.gbp.updateParams,
      abc: this.abc.updateParams,
      yahooPlace: this.yahooPlace.updateParams,
    };
  }
}
