import { Record as ImmutableRecord } from 'immutable';
import { z } from 'zod';

import { validateKey } from 'helpers/schema';
import { removeEmptyValueParams, toggleOptionalBoolean } from 'helpers/utils';
import { KeysWithSpecificType } from 'types/Common';

export const MAX_PARKING_CAPACITY = 9999;
export const MAX_SEAT_CAPACITY = 999;
export const MAX_OTHER_SERVICE_DESCRIPTION_LENGTH = 500;
export const MAX_INFECTION_CONTROL_REMARKS_LENGTH = 330;

export const smokingRuleTypes = ['0', '1', '2'] as const;

export type SmokingRuleType = (typeof smokingRuleTypes)[number];

export const prohibitedChars = ['<', '>', '’', '"', '&', '¥', '《', '》'];

// YahooPlaceBusinessのスキーマ
export const businessSchema = z.object({
  studentDiscount: z.boolean().optional(),
  seatCapacity: z.number().int().min(1).max(MAX_SEAT_CAPACITY).optional(),
  isPrivateRoomAvailable: z.boolean().optional(),
  hasCompletePrivateRoom: z.boolean().optional(),
  hasSemiPrivateRoom: z.boolean().optional(),
  isEntireReservationAvailable: z.boolean().optional(),
  hasEntireReservation20OrLess: z.boolean().optional(),
  hasEntireReservation20To50: z.boolean().optional(),
  hasEntireReservationOver50: z.boolean().optional(),
  smokingRuleType: z.enum(smokingRuleTypes).optional(),
  isHavingDesignatedSmokingRoom: z.boolean().optional(),
  isHavingDesignatedHeatedTobaccoSmokingRoom: z.boolean().optional(),
  isHavingOutsideSmokingSpace: z.boolean().optional(),
  isSettingSmokingHours: z.boolean().optional(),
  parkingAreaFlag: z.boolean().optional(),
  parkingCapacity: z.number().int().min(1).max(MAX_PARKING_CAPACITY).optional(),
  coinParkingAreaFlag: z.boolean().optional(),
  barrierFreeFlag: z.boolean().optional(),
  childFriendlyFlag: z.boolean().optional(),
  kidsSpaceFlag: z.boolean().optional(),
  petFlag: z.boolean().optional(),
  otherServiceDescription: z.string().max(MAX_OTHER_SERVICE_DESCRIPTION_LENGTH).optional(),
  deliveryServiceFlag: z.boolean().optional(),
  reservationFlag: z.boolean().optional(),
  onlyReservationFlag: z.boolean().optional(),
  sameDayReservationFlag: z.boolean().optional(),
  infectionControlSanitaryMeasuresTakenFlag: z.boolean().optional(),
  infectionControlSetUpHandSanitizerFlag: z.boolean().optional(),
  infectionControlFacilityCleaningFlag: z.boolean().optional(),
  infectionControlVentilationFlag: z.boolean().optional(),
  infectionControlSetUpAirPurifierFlag: z.boolean().optional(),
  infectionControlEmployeesWashingHandAndGargleFlag: z.boolean().optional(),
  infectionControlEmployeesWearingMaskFlag: z.boolean().optional(),
  infectionControlEmployeesHealthCheckFlag: z.boolean().optional(),
  infectionControlDistancingFlag: z.boolean().optional(),
  infectionControlAdmissionLimitFlag: z.boolean().optional(),
  infectionControlRemarks: z.string().max(MAX_INFECTION_CONTROL_REMARKS_LENGTH).optional(),
});

// businessSchemaに値の組み合わせなどのバリデーションを追加したスキーマ
export const refinedBusinessSchema = businessSchema
  .refine(
    ({ infectionControlRemarks }) => {
      if (infectionControlRemarks == null) {
        return true;
      }
      return !prohibitedChars.some((char) => infectionControlRemarks.includes(char));
    },
    {
      message: '以下の文字（<, >, ’, ", &, ¥, 《, 》）は使用できません。使用したい場合は全角で入力してください。',
      path: ['infectionControlRemarks'],
    },
  )
  // 以下の値の組み合わせのバリデーションは、画面上で入力できないように実装しているはずなので、メッセージはテスト用
  // 個室系のバリデーション
  .refine(
    ({ isPrivateRoomAvailable, hasCompletePrivateRoom, hasSemiPrivateRoom }) => {
      switch (isPrivateRoomAvailable) {
        case true:
          // 「個室あり」がtrueのとき、他はすべて値があること
          return hasCompletePrivateRoom != null && hasSemiPrivateRoom != null;
        case false:
          // 「個室あり」がfalseのとき、他はすべてfalseであること
          return hasCompletePrivateRoom == false && hasSemiPrivateRoom == false;
        default:
          // 「個室あり」が値なしのとき、他はすべて値なしであること
          return hasCompletePrivateRoom == null && hasSemiPrivateRoom == null;
      }
    },
    {
      message: '「個室あり」と「完全個室」「半個室」の値の組み合わせが不正です',
      path: ['isPrivateRoomAvailable'],
    },
  )
  .refine(
    ({
      isEntireReservationAvailable,
      hasEntireReservation20OrLess,
      hasEntireReservation20To50,
      hasEntireReservationOver50,
    }) => {
      switch (isEntireReservationAvailable) {
        case true:
          // 「貸切可」がtrueのとき、他はすべて値があること
          return (
            hasEntireReservation20OrLess != null &&
            hasEntireReservation20To50 != null &&
            hasEntireReservationOver50 != null
          );
        case false:
          // 「貸切可」がfalseのとき、他はすべてfalseであること
          return (
            hasEntireReservation20OrLess == false &&
            hasEntireReservation20To50 == false &&
            hasEntireReservationOver50 == false
          );
        default:
          // 「貸切可」が値なしのとき、他はすべて値なしであること
          return (
            hasEntireReservation20OrLess == null &&
            hasEntireReservation20To50 == null &&
            hasEntireReservationOver50 == null
          );
      }
    },
    { message: '「貸切可」と「貸切可能人数」の値の組み合わせが不正です', path: ['isEntireReservationAvailable'] },
  )
  // 喫煙系のバリデーション
  .refine(
    ({
      smokingRuleType,
      isHavingDesignatedSmokingRoom,
      isHavingDesignatedHeatedTobaccoSmokingRoom,
      isHavingOutsideSmokingSpace,
      isSettingSmokingHours,
    }) => {
      switch (smokingRuleType) {
        case '0':
          // 「全面禁煙」のとき、他はすべてfalseであること
          return (
            isHavingDesignatedSmokingRoom == false &&
            isHavingDesignatedHeatedTobaccoSmokingRoom == false &&
            isHavingOutsideSmokingSpace == false &&
            isSettingSmokingHours == false
          );
        case '1':
          // 「全面喫煙可」のとき、他はすべてfalseであること
          return (
            isHavingDesignatedSmokingRoom == false &&
            isHavingDesignatedHeatedTobaccoSmokingRoom == false &&
            isHavingOutsideSmokingSpace == false &&
            isSettingSmokingHours == false
          );
        case '2':
          // 「分煙」のとき、他はすべて値があること
          return (
            isHavingDesignatedSmokingRoom != null &&
            isHavingDesignatedHeatedTobaccoSmokingRoom != null &&
            isHavingOutsideSmokingSpace != null &&
            isSettingSmokingHours != null
          );
        default:
          // 「値なしのとき、他はすべて値なしであること
          return (
            isHavingDesignatedSmokingRoom == null &&
            isHavingDesignatedHeatedTobaccoSmokingRoom == null &&
            isHavingOutsideSmokingSpace == null &&
            isSettingSmokingHours == null
          );
      }
    },
    { message: '「禁煙・喫煙・分煙」と喫煙所情報の値の組み合わせが不正です', path: ['smokingRuleType'] },
  )
  // 駐車場系のバリデーション
  .refine(
    ({ parkingAreaFlag, parkingCapacity }) => {
      switch (parkingAreaFlag) {
        case true:
          // 「駐車場あり」がtrueのとき、他の値は問わない
          return true;
        case false:
          // 「駐車場あり」がfalseのとき、他の値はnullであること
          return parkingCapacity == null;
        default:
          // 「駐車場あり」が値なしのとき、他の値はnullであること
          return parkingCapacity == null;
      }
    },
    {
      message: '「駐車場あり」と「駐車場台数」の値の組み合わせが不正です',
      path: ['parkingAreaFlag'],
    },
  )
  // 予約系のバリデーション
  .refine(
    ({ reservationFlag, onlyReservationFlag, sameDayReservationFlag }) => {
      switch (reservationFlag) {
        case true:
          // 「予約可」がtrueのとき、他はすべて値があること
          return onlyReservationFlag != null && sameDayReservationFlag != null;
        case false:
          // 「予約可」がfalseのとき、他はすべてfalseであること
          return onlyReservationFlag == false && sameDayReservationFlag == false;
        default:
          // 「予約可」が値なしのとき、他はすべて値なしであること
          return onlyReservationFlag == null && sameDayReservationFlag == null;
      }
    },

    {
      message: '「予約可」と「完全予約制」「当日予約可」の値の組み合わせが不正です',
      path: ['reservationFlag'],
    },
  )
  // 衛生対策のバリデーション
  .refine(
    ({
      infectionControlSanitaryMeasuresTakenFlag,
      infectionControlSetUpHandSanitizerFlag,
      infectionControlFacilityCleaningFlag,
      infectionControlVentilationFlag,
      infectionControlSetUpAirPurifierFlag,
      infectionControlEmployeesWashingHandAndGargleFlag,
      infectionControlEmployeesWearingMaskFlag,
      infectionControlEmployeesHealthCheckFlag,
      infectionControlDistancingFlag,
      infectionControlAdmissionLimitFlag,
      infectionControlRemarks,
    }) => {
      switch (infectionControlSanitaryMeasuresTakenFlag) {
        case true:
          // 「衛生対策実施」がtrueのとき、他はすべて値があること
          return (
            infectionControlSetUpHandSanitizerFlag != null &&
            infectionControlFacilityCleaningFlag != null &&
            infectionControlVentilationFlag != null &&
            infectionControlSetUpAirPurifierFlag != null &&
            infectionControlEmployeesWashingHandAndGargleFlag != null &&
            infectionControlEmployeesWearingMaskFlag != null &&
            infectionControlEmployeesHealthCheckFlag != null &&
            infectionControlDistancingFlag != null &&
            infectionControlAdmissionLimitFlag != null &&
            infectionControlRemarks != null
          );
        case false:
          // 「衛生対策実施」がfalseのとき、他はすべてfalseであること
          return (
            infectionControlSetUpHandSanitizerFlag == false &&
            infectionControlFacilityCleaningFlag == false &&
            infectionControlVentilationFlag == false &&
            infectionControlSetUpAirPurifierFlag == false &&
            infectionControlEmployeesWashingHandAndGargleFlag == false &&
            infectionControlEmployeesWearingMaskFlag == false &&
            infectionControlEmployeesHealthCheckFlag == false &&
            infectionControlDistancingFlag == false &&
            infectionControlAdmissionLimitFlag == false &&
            infectionControlRemarks === ''
          );
        default:
          // 「衛生対策実施」が値なしのとき、他はすべて値なしであること
          return (
            infectionControlSetUpHandSanitizerFlag == null &&
            infectionControlFacilityCleaningFlag == null &&
            infectionControlVentilationFlag == null &&
            infectionControlSetUpAirPurifierFlag == null &&
            infectionControlEmployeesWashingHandAndGargleFlag == null &&
            infectionControlEmployeesWearingMaskFlag == null &&
            infectionControlEmployeesHealthCheckFlag == null &&
            infectionControlDistancingFlag == null &&
            infectionControlAdmissionLimitFlag == null &&
            infectionControlRemarks == null
          );
      }
    },
    {
      message: '「衛生対策実施」と衛生対策の詳細の値の組み合わせが不正です',
      path: ['infectionControlSanitaryMeasuresTakenFlag'],
    },
  );

export type YahooPlaceBusinessRecord = z.infer<typeof businessSchema>;
export type YahooPlaceBusinessJSON = YahooPlaceBusinessRecord;
export type YahooPlaceBusinessBooleanKey = KeysWithSpecificType<YahooPlaceBusinessRecord, boolean | undefined>;

export type YahooPlaceBusinessKey = keyof YahooPlaceBusinessRecord;

export class YahooPlaceBusiness extends ImmutableRecord<YahooPlaceBusinessRecord>({
  studentDiscount: undefined,
  seatCapacity: undefined,
  isPrivateRoomAvailable: undefined,
  hasCompletePrivateRoom: undefined,
  hasSemiPrivateRoom: undefined,
  isEntireReservationAvailable: undefined,
  hasEntireReservation20OrLess: undefined,
  hasEntireReservation20To50: undefined,
  hasEntireReservationOver50: undefined,
  smokingRuleType: undefined,
  isHavingDesignatedSmokingRoom: undefined,
  isHavingDesignatedHeatedTobaccoSmokingRoom: undefined,
  isHavingOutsideSmokingSpace: undefined,
  isSettingSmokingHours: undefined,
  parkingAreaFlag: undefined,
  parkingCapacity: undefined,
  coinParkingAreaFlag: undefined,
  barrierFreeFlag: undefined,
  childFriendlyFlag: undefined,
  kidsSpaceFlag: undefined,
  petFlag: undefined,
  otherServiceDescription: undefined,
  deliveryServiceFlag: undefined,
  reservationFlag: undefined,
  onlyReservationFlag: undefined,
  sameDayReservationFlag: undefined,
  infectionControlSanitaryMeasuresTakenFlag: undefined,
  infectionControlSetUpHandSanitizerFlag: undefined,
  infectionControlFacilityCleaningFlag: undefined,
  infectionControlVentilationFlag: undefined,
  infectionControlSetUpAirPurifierFlag: undefined,
  infectionControlEmployeesWashingHandAndGargleFlag: undefined,
  infectionControlEmployeesWearingMaskFlag: undefined,
  infectionControlEmployeesHealthCheckFlag: undefined,
  infectionControlDistancingFlag: undefined,
  infectionControlAdmissionLimitFlag: undefined,
  infectionControlRemarks: undefined,
}) {
  static fromJSON(data: YahooPlaceBusinessJSON) {
    return new YahooPlaceBusiness(data);
  }

  static getSmokingRuleTypeLabel = (smokingRuleType: SmokingRuleType) => {
    switch (smokingRuleType) {
      case '0':
        return '全面禁煙';
      case '1':
        return '全面喫煙可';
      case '2':
        return '分煙';
      default:
        return smokingRuleType satisfies never;
    }
  };

  static getKeyLabel = (key: YahooPlaceBusinessKey) => {
    switch (key) {
      case 'studentDiscount':
        return '学割あり';
      case 'seatCapacity':
        return '座席数';
      case 'isPrivateRoomAvailable':
        return '個室あり';
      case 'hasCompletePrivateRoom':
        return '完全個室あり';
      case 'hasSemiPrivateRoom':
        return '半個室あり';
      case 'isEntireReservationAvailable':
        return '貸切可';
      case 'hasEntireReservation20OrLess':
        return '20人以下可';
      case 'hasEntireReservation20To50':
        return '20〜50人可';
      case 'hasEntireReservationOver50':
        return '50人以上可';
      case 'smokingRuleType':
        return '禁煙・喫煙・分煙';
      case 'isHavingDesignatedSmokingRoom':
        return '喫煙専用室あり';
      case 'isHavingDesignatedHeatedTobaccoSmokingRoom':
        return '加熱式たばこ専用喫煙室あり';
      case 'isHavingOutsideSmokingSpace':
        return '施設外スペースあり';
      case 'isSettingSmokingHours':
        return '時間帯によって分煙';
      case 'parkingAreaFlag':
        return '駐車場あり';
      case 'parkingCapacity':
        return '駐車場台数';
      case 'coinParkingAreaFlag':
        return '近くにコインパーキングあり';
      case 'barrierFreeFlag':
        return 'バリアフリー対応';
      case 'childFriendlyFlag':
        return '子ども同伴可';
      case 'kidsSpaceFlag':
        return 'キッズスペースあり';
      case 'petFlag':
        return 'ペット同伴可';
      case 'otherServiceDescription':
        return 'その他施設情報';
      case 'deliveryServiceFlag':
        return 'デリバリー可';
      case 'reservationFlag':
        return '予約可';
      case 'onlyReservationFlag':
        return '完全予約制';
      case 'sameDayReservationFlag':
        return '当日予約可';
      case 'infectionControlSanitaryMeasuresTakenFlag':
        return '衛生対策実施';
      case 'infectionControlSetUpHandSanitizerFlag':
        return '施設内や入り口に消毒液を設置';
      case 'infectionControlFacilityCleaningFlag':
        return '施設内の清掃、消毒を実施';
      case 'infectionControlVentilationFlag':
        return '施設内の換気を実施';
      case 'infectionControlSetUpAirPurifierFlag':
        return '施設内に空気清浄機を設置';
      case 'infectionControlEmployeesWashingHandAndGargleFlag':
        return '従業員の手洗い、うがいを徹底';
      case 'infectionControlEmployeesWearingMaskFlag':
        return '従業員のマスク着用を義務化';
      case 'infectionControlEmployeesHealthCheckFlag':
        return '従業員の検温、体調報告を義務化';
      case 'infectionControlDistancingFlag':
        return '客席の間隔を空けてお客様を案内';
      case 'infectionControlAdmissionLimitFlag':
        return '来店上限数の設定、入店制限を実施';
      case 'infectionControlRemarks':
        return 'その他衛生対策';
      default:
        return key satisfies never;
    }
  };

  changeValue<T extends keyof YahooPlaceBusinessRecord>(key: T, value: YahooPlaceBusiness[T]) {
    return this.set(key, value);
  }

  changeSmokingRuleType(value: SmokingRuleType | undefined) {
    const result = this.changeValue('smokingRuleType', value);
    switch (value) {
      case '0':
        // 「全面禁煙」のとき、他はすべてfalseであること
        return result.merge({
          isHavingDesignatedSmokingRoom: false,
          isHavingDesignatedHeatedTobaccoSmokingRoom: false,
          isHavingOutsideSmokingSpace: false,
          isSettingSmokingHours: false,
        });
      case '1':
        // 「全面喫煙可」のとき、他はすべてfalseであること
        return result.merge({
          isHavingDesignatedSmokingRoom: false,
          isHavingDesignatedHeatedTobaccoSmokingRoom: false,
          isHavingOutsideSmokingSpace: false,
          isSettingSmokingHours: false,
        });
      case '2':
        // 「分煙」のとき、他はすべて値があること（初期値はfalse）
        return result.merge({
          isHavingDesignatedSmokingRoom: false,
          isHavingDesignatedHeatedTobaccoSmokingRoom: false,
          isHavingOutsideSmokingSpace: false,
          isSettingSmokingHours: false,
        });
      default:
        // 「値なしのとき、他はすべて値なしであること
        return result.merge({
          isHavingDesignatedSmokingRoom: undefined,
          isHavingDesignatedHeatedTobaccoSmokingRoom: undefined,
          isHavingOutsideSmokingSpace: undefined,
          isSettingSmokingHours: undefined,
        });
    }
  }

  /**
   * 指定した真偽値のキーの値をトグルする（undefinedあり）
   * @param key
   */
  toggleOptionalValue(key: YahooPlaceBusinessBooleanKey) {
    const newValue = toggleOptionalBoolean(this.get(key));
    const result = this.changeValue(key, newValue);
    switch (key) {
      case 'reservationFlag':
        // 「予約可」に値があれば「完全予約制」「当日予約可」をfalse、なければundefinedにする
        return result.merge({
          onlyReservationFlag: newValue != null ? false : undefined,
          sameDayReservationFlag: newValue != null ? false : undefined,
        });
      case 'isPrivateRoomAvailable':
        // 「個室あり」に値があれば「完全個室」「半個室」をfalse、なければundefinedにする
        return result.merge({
          hasCompletePrivateRoom: newValue != null ? false : undefined,
          hasSemiPrivateRoom: newValue != null ? false : undefined,
        });
      case 'isEntireReservationAvailable':
        // 「貸切可」に値があれば「貸切可能人数」をすべてfalse、なければundefinedにする
        return result.merge({
          hasEntireReservation20OrLess: newValue != null ? false : undefined,
          hasEntireReservation20To50: newValue != null ? false : undefined,
          hasEntireReservationOver50: newValue != null ? false : undefined,
        });
      case 'parkingAreaFlag':
        // 「駐車場あり」が変更されたら、「駐車場台数」をundefinedにする
        return result.set('parkingCapacity', undefined);
      case 'infectionControlSanitaryMeasuresTakenFlag':
        // 「衛生対策実施」に値があれば、他はすべてfalse、なければundefinedにする
        return result.merge({
          infectionControlSetUpHandSanitizerFlag: newValue != null ? false : undefined,
          infectionControlFacilityCleaningFlag: newValue != null ? false : undefined,
          infectionControlVentilationFlag: newValue != null ? false : undefined,
          infectionControlSetUpAirPurifierFlag: newValue != null ? false : undefined,
          infectionControlEmployeesWashingHandAndGargleFlag: newValue != null ? false : undefined,
          infectionControlEmployeesWearingMaskFlag: newValue != null ? false : undefined,
          infectionControlEmployeesHealthCheckFlag: newValue != null ? false : undefined,
          infectionControlDistancingFlag: newValue != null ? false : undefined,
          infectionControlAdmissionLimitFlag: newValue != null ? false : undefined,
          infectionControlRemarks: newValue != null ? '' : undefined,
        });
      default:
        return result;
    }
  }

  /**
   * 指定した真偽値のキーの値をトグルする（undefinedなし）
   * @param key
   */
  toggleValue(key: YahooPlaceBusinessBooleanKey) {
    const newValue = !this.get(key);
    return this.changeValue(key, newValue);
  }

  /**
   * YahooPlaceBusinessの指定したキーのバリデーション結果を取得
   * @param key
   */
  validate(key: keyof YahooPlaceBusinessRecord) {
    return validateKey(refinedBusinessSchema, this.toJS(), key);
  }

  /**
   * YahooPlaceBusiness全体のバリデーションが成功しているか
   */
  isValid() {
    return refinedBusinessSchema.safeParse(this.toJS()).success;
  }

  updateParams(): YahooPlaceBusinessJSON {
    const params = removeEmptyValueParams(this.toJS());
    // smokingRuleTypeを数値に変換する
    if (params['smokingRuleType'] != null) {
      params['smokingRuleType'] = String(smokingRuleTypes.indexOf(params['smokingRuleType']));
    }
    // 「衛生対策実施」が値ありのとき、「その他衛生対策」を空文字にできないので、値がなければ空文字にする
    if (this.infectionControlSanitaryMeasuresTakenFlag != null && !this.infectionControlRemarks) {
      params['infectionControlRemarks'] = '';
    }
    return params;
  }

  /**
   * YahooPlaceBusinessの値が設定されているか
   */
  get hasValue() {
    // もし子項目のみ値が設定されていても、親項目の値がない場合は設定されていないとみなしたいため、
    // 他の項目の値によらず設定可能な項目のいずれかまたはその他の施設情報が設定されているかで判断する
    return YahooPlaceBusiness.featureKeys.some((key) => this.get(key) != null) || !!this.otherServiceDescription;
  }

  // 他の項目の値によらず設定可能な項目（「その他施設情報」は除く）
  static featureKeys = [
    'studentDiscount',
    'reservationFlag',
    'deliveryServiceFlag',
    'seatCapacity',
    'isPrivateRoomAvailable',
    'isEntireReservationAvailable',
    'smokingRuleType',
    'parkingAreaFlag',
    'coinParkingAreaFlag',
    'barrierFreeFlag',
    'childFriendlyFlag',
    'kidsSpaceFlag',
    'petFlag',
    'infectionControlSanitaryMeasuresTakenFlag',
  ] as const satisfies YahooPlaceBusinessKey[];

  // 「予約可」のオプション
  static reservationOptions = [
    'onlyReservationFlag',
    'sameDayReservationFlag',
  ] as const satisfies YahooPlaceBusinessKey[];

  // 「個室あり」のオプション
  static privateRoomOptions = [
    'hasCompletePrivateRoom',
    'hasSemiPrivateRoom',
  ] as const satisfies YahooPlaceBusinessKey[];

  // 「貸切可」のオプション
  static entireReservationOptions = [
    'hasEntireReservation20OrLess',
    'hasEntireReservation20To50',
    'hasEntireReservationOver50',
  ] as const satisfies YahooPlaceBusinessKey[];

  // 「分煙」のオプション
  static smokingRuleOptions = [
    'isHavingDesignatedSmokingRoom',
    'isHavingDesignatedHeatedTobaccoSmokingRoom',
    'isHavingOutsideSmokingSpace',
    'isSettingSmokingHours',
  ] as const satisfies YahooPlaceBusinessKey[];

  // 「衛生対策実施」のオプション
  static infectionControlOptions = [
    'infectionControlSetUpHandSanitizerFlag',
    'infectionControlFacilityCleaningFlag',
    'infectionControlVentilationFlag',
    'infectionControlSetUpAirPurifierFlag',
    'infectionControlEmployeesWashingHandAndGargleFlag',
    'infectionControlEmployeesWearingMaskFlag',
    'infectionControlEmployeesHealthCheckFlag',
    'infectionControlDistancingFlag',
    'infectionControlAdmissionLimitFlag',
  ] as const satisfies YahooPlaceBusinessKey[];

  /**
   * 指定されたキーの中で有効な値を持つキーのラベルを取得する
   * @param options
   */
  getAvailableOptions = (options: YahooPlaceBusinessKey[]) => {
    return options.filter((key) => this.get(key) == true).map((key) => YahooPlaceBusiness.getKeyLabel(key));
  };

  get seatCapacityFlag() {
    return this.seatCapacity == null ? undefined : this.seatCapacity > 0;
  }
}
