import React from 'react';

import styled from 'styled-components';

import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { SNSItems, getSNSServiceName } from 'models/Domain/SNSLink';
import { YahooPlaceSNSLink } from 'models/Domain/YahooPlace/SNSLink';
import { COLOR } from 'style/color';

export type Props = {
  // 店舗に紐付いている属性情報
  attributes: GmbUrlAttributes;
  // Yahoo!プレイスに紐付いているSNSリンク情報
  yahooPlaceSNSLink: YahooPlaceSNSLink;
  // GBPと連携しているか
  isConnectedGBP: boolean;
  // Yahoo!プレイスと連携しているか
  isConnectedYahooPlace: boolean;
};

export const SNSLinkList: React.FC<Props> = React.memo(
  ({ attributes, yahooPlaceSNSLink, isConnectedGBP, isConnectedYahooPlace }) => {
    const yahooPlaceSNSItems = SNSItems.createByYahooPlaceSNSLink(yahooPlaceSNSLink);
    const gmbSNSItems = SNSItems.createByGmbUrlAttributes(attributes);
    const snsItems = gmbSNSItems.mergeItems(yahooPlaceSNSItems);
    // 複数のサービスと連携されている場合のみSNSアイコンを表示する
    const showSNSIcon = isConnectedGBP && isConnectedYahooPlace;

    return (
      <Wrapper>
        <SNSItemTable>
          <tbody>
            {snsItems.list.map((item) => {
              if (!item.service) return null;
              return (
                <tr key={item.service}>
                  <th>{getSNSServiceName(item.service)}</th>
                  <td style={{ wordBreak: 'break-all' }}>{item.url}</td>
                  {showSNSIcon && (
                    <td>
                      <ServiceIconWrapper>
                        {gmbSNSItems.findItemByService(item.service) && <ServiceIcon.Google />}
                        {yahooPlaceSNSItems.findItemByService(item.service) && <ServiceIcon.Yahoo />}
                      </ServiceIconWrapper>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </SNSItemTable>
        {snsItems.size === 0 && <AttributeUrlValue editable={true}>リンクが設定されていません</AttributeUrlValue>}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const AttributeUrlValue = styled.div<{ editable: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.editable ? `${COLOR.BLACK}` : `${COLOR.GRAY}`)};
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SNSItemTable = styled.table`
  border-collapse: separate;

  td {
    padding: 8px 16px;
  }

  th {
    text-align: left;
    font-weight: bold;
  }
`;
