import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { COLOR } from 'style/color';

export interface Props {
  className?: string;
  value: any;
  ValueIcon?: React.ReactElement;
  options: any[];
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  readOnly?: boolean;
  allowAdditions?: boolean;
  closeOnChange?: boolean;
  clearable?: boolean;
  loading?: boolean;
  error?: string | boolean;
  onChange?: (value: any) => void;
  onSearchChange?: (value: any) => void;
  onAddItem?: (value: any) => void;
  additionLabel?: string;
}

export const PullDown: React.FC<Props> = ({
  className,
  value,
  ValueIcon = null,
  options,
  placeholder,
  disabled = false,
  multiple = false,
  readOnly = false,
  allowAdditions = false,
  clearable = false,
  onChange,
  onSearchChange,
  onAddItem,
  closeOnChange = true,
  error = false,
  loading = false,
  additionLabel = '新規作成: ',
}) => (
  <Wrapper className={className} hasIcon={Boolean(ValueIcon)}>
    <DropdownOverride
      search
      selection
      value={value}
      options={options}
      placeholder={placeholder}
      disabled={disabled || readOnly}
      readOnly={readOnly}
      multiple={multiple}
      allowAdditions={allowAdditions}
      clearable={clearable}
      closeOnChange={closeOnChange}
      error={error}
      noResultsMessage='一致しませんでした'
      additionLabel={additionLabel}
      onChange={(_: any, data: any) => onChange && onChange(data.value)}
      onSearchChange={(_: any, data: any) => onSearchChange && onSearchChange(data.searchQuery)}
      onAddItem={(_: any, data: any) => onAddItem && onAddItem(data.value)}
      loading={loading}
    />
    <IconWrapper>{ValueIcon}</IconWrapper>
  </Wrapper>
);

const IconWrapper = styled.div`
  position: absolute;
  left: 17px;
  top: 15px;
  z-index: 10;
`;

const ReadOnlyCss = css`
  color: ${COLOR.BLACK} !important;
  background-color: ${COLOR.WHITE};
  border: none;
  opacity: 1;
  cursor: default;

  i {
    display: none;
  }
`;

export const DropdownOverride = styled(Dropdown)<{ readOnly?: boolean; error?: string }>`
  &&&& {
    color: ${COLOR.BLACK};
    font-size: 16px;
    font-weight: bold;
    padding: 20px 16px;
    width: 100%;
    min-height: 60px;
    border-radius: 8px;
    border: solid 1px ${COLOR.GRAY};
    background-color: ${COLOR.WHITE};
    outline: none;

    &.multiple {
      > input {
        padding: 0 2px !important;
        margin: 0 !important;
        height: 27.5px;
      }
      > .text {
        margin: 0;
      }
    }

    > input {
      padding: 20px 16px !important;
      border-radius: 8px;
    }

    > i {
      padding-top: 20px !important;
    }

    > .menu {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      > .item {
        padding: 20px 16px !important;
        font-size: 16px;

        i {
          margin-right: 10px;
        }
      }
    }

    &.upward > .menu {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.active {
      border-color: ${COLOR.GREEN};
      &:hover {
        border-color: ${COLOR.GREEN};
      }
      > .menu {
        border-color: ${COLOR.GREEN};
        &:hover {
          border-color: ${COLOR.GREEN};
        }
      }
    }

    &.disabled {
      color: ${COLOR.GRAY};
      background-color: ${COLOR.BACKGROUND};
      border-color: ${COLOR.LIGHT_GRAY};
      border-color: ${COLOR.LIGHT_GRAY};
      opacity: 1;
      cursor: not-allowed;
    }

    ${(props) => props.readOnly && ReadOnlyCss}

    ${(props) => props.error && `border-color: ${COLOR.ERROR}`}
  }
`;

const Wrapper = styled.div<{ hasIcon: boolean }>`
  position: relative;
  ${DropdownOverride} {
    > .text {
      padding-left: ${(props) => props.hasIcon && '33px'};
    }
  }

  > .ui.selection.dropdown > i.dropdown.icon {
    height: 100%;
  }

  > .ui.loading.dropdown > i.icon {
    /* 読込中のアイコンはライブラリ側の!importantで別の高さが設定されているので!importantで上書き */
    height: 100% !important;
  }
`;
