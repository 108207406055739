import React from 'react';

import { List as ImmutableList, Map, Set } from 'immutable';
import { Checkbox, Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import { UpdatesTableAttributeRows } from 'components/molecules/GmbLocationUpdates/TableRow/UpdatesTableAttributeRows';
import { UpdatesTableMoreHoursRows } from 'components/molecules/GmbLocationUpdates/TableRow/UpdatesTableMoreHoursRows';
import { UpdatesTableRow } from 'components/molecules/GmbLocationUpdates/TableRow/UpdatesTableRow';
import { UpdatesTableServiceItemsRows } from 'components/molecules/GmbLocationUpdates/TableRow/UpdatesTableServiceItemsRows';
import { GbpAvailableUrlTypes, createEmptyGbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes, GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { MoreHoursList, MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';
import { ServiceType } from 'models/Domain/GmbLocation/Service';
import { GmbLocationDiff, LocationDiffKey } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdates } from 'models/Domain/GmbLocationUpdates';
import { Stores } from 'models/Domain/Store';
import { CheckedItemsType } from 'modules/gmbLocationUpdates/reducers';
import { SimpleUpdateType, UpdateType } from 'modules/gmbLocationUpdates/types';
import { COLOR } from 'style/color';

// １店舗あたりの差分項目を並べる順番
const diffItemsOrder: LocationDiffKey[] = [
  'locationName',
  'openInfo',
  'categories',
  'storeCode',
  'address',
  'latlng',
  'regularHours',
  'specialHours',
  'moreHours',
  'phoneNumbers',
  'websiteUrl',
  'attributes',
  'profile',
  'serviceItems',
];

export type Props = {
  gmbLocationUpdates: GmbLocationUpdates;
  stores: Stores;
  attributeMetadatas: { [categoryId: string]: GmbAttributeMetadatas };
  availableUrlTypes: { [storeId: number]: GbpAvailableUrlTypes };
  moreHoursTypes: { [categoryId: string]: ImmutableList<MoreHoursType> };
  serviceTypes: { [categoryId: string]: ImmutableList<ServiceType> };
  checkedItems: CheckedItemsType;
  updatedItems: Map<GmbLocationDiff, UpdateType>;
  filterItems: Set<GmbLocationDiff>;
  categories: GmbCategories;
  isCheckedAllItems: boolean;
  onAdaptationStorecast: (target: GmbLocationDiff) => void;
  onAdaptationGmb: (target: GmbLocationDiff) => void;
  onAdaptationCustomAttributes: (
    target: GmbLocationDiff,
    attributes: GmbAttributes,
    urlAttributes: GmbUrlAttributes,
  ) => void;
  onAdaptationCustomMoreHours: (target: GmbLocationDiff, moreHours: MoreHoursList) => void;
  onChangeCheckBox: (diff: GmbLocationDiff) => void;
  onChangeAllCheckBox: () => void;
  onResetItem: (target: GmbLocationDiff) => void;
};
export const UpdateTable: React.FC<Props> = ({
  gmbLocationUpdates,
  stores,
  moreHoursTypes,
  serviceTypes,
  attributeMetadatas,
  availableUrlTypes,
  categories,
  checkedItems,
  updatedItems,
  filterItems,
  isCheckedAllItems,
  onAdaptationStorecast,
  onAdaptationGmb,
  onAdaptationCustomAttributes,
  onAdaptationCustomMoreHours,
  onChangeCheckBox,
  onChangeAllCheckBox,
  onResetItem,
}) => {
  const isEmpty = gmbLocationUpdates.isEmpty();

  return (
    <StyledTable celled structured>
      <Table.Header>
        <Table.Row>
          <StyledFirstTableHeaderCell minwidth={200}>店舗名</StyledFirstTableHeaderCell>
          <StyledTableHeaderCell minwidth={210}>
            <CheckLabel>
              <StyledCheckbox checked={isCheckedAllItems} onChange={() => onChangeAllCheckBox()} />
              項目
            </CheckLabel>
          </StyledTableHeaderCell>
          <StyledTableHeaderCell minwidth={154}>
            種別
            <ContextHelp
              header={'種別'}
              content={
                <>
                  【Googleからの提案】
                  <br />
                  ウェブサイトの内容やユーザーからの情報をもとにGoogleから情報の更新を提案されている項目
                  <br />
                  【GBPとの差分】
                  <br />
                  GBPに登録されている情報とSTORECASTに保存されている情報に差分がある項目
                </>
              }
            />
          </StyledTableHeaderCell>
          <StyledTableHeaderCell minwidth={240}>STORECASTの情報</StyledTableHeaderCell>
          <StyledTableHeaderCell minwidth={240}>Googleの情報</StyledTableHeaderCell>
          <StyledTableHeaderCell minwidth={240}>保持するデータを選択</StyledTableHeaderCell>
        </Table.Row>
      </Table.Header>

      {!isEmpty ? (
        <Table.Body>
          {gmbLocationUpdates.list.map((gmbLocationUpdate, idx) => {
            const store = stores.findStore(gmbLocationUpdate.store_id);
            if (!store) {
              return <React.Fragment key={gmbLocationUpdate.store_id} />;
            }
            const primaryCategory = store.location.primaryCategory;
            const diffs = gmbLocationUpdate.diffs
              .filter((diff) => filterItems.has(diff))
              .sortBy((diff) => diffItemsOrder.indexOf(diff.key));
            return (
              <React.Fragment key={idx}>
                {diffs.map((diff, idx2) => {
                  const identifier = `${diff.store_id}-${diff.key}`;
                  const isFirstRowInStore = idx2 === 0;
                  const checked = checkedItems.has(diff);

                  switch (diff.key) {
                    case 'moreHours': {
                      const updateStatus = updatedItems.get(diff) as UpdateType | undefined;
                      const categoryMoreHoursTypes = moreHoursTypes[primaryCategory.categoryId];
                      if (!categoryMoreHoursTypes) {
                        return <React.Fragment key={identifier} />;
                      }
                      return (
                        <UpdatesTableMoreHoursRows
                          key={identifier}
                          locationKey={diff.key}
                          isFirstRowInStore={isFirstRowInStore}
                          checked={checked}
                          store={store}
                          updateStatus={updateStatus}
                          diff={diff}
                          gmbLocationUpdate={gmbLocationUpdate}
                          moreHoursTypes={categoryMoreHoursTypes}
                          onAdaptationStorecast={onAdaptationStorecast}
                          onAdaptationGmb={onAdaptationGmb}
                          onAdaptationCustomMoreHours={onAdaptationCustomMoreHours}
                          onChangeCheckBox={onChangeCheckBox}
                          onResetItem={onResetItem}
                        />
                      );
                    }
                    case 'attributes': {
                      const updateStatus = updatedItems.get(diff) as UpdateType | undefined;
                      const categoryAttributeMetadatas = attributeMetadatas[store.location.primaryCategory.categoryId];
                      if (!categoryAttributeMetadatas) {
                        return <React.Fragment key={identifier} />;
                      }
                      // 属性データの行(複数)
                      return (
                        <UpdatesTableAttributeRows
                          key={identifier}
                          locationKey={diff.key}
                          isFirstRowInStore={isFirstRowInStore}
                          checked={checked}
                          store={store}
                          updateStatus={updateStatus}
                          diff={diff}
                          gmbLocationUpdate={gmbLocationUpdate}
                          gmbCategoryList={categories}
                          attributeMetadatas={attributeMetadatas[store.location.primaryCategory.categoryId]}
                          availableUrlTypes={availableUrlTypes[store.id] ?? createEmptyGbpAvailableUrlTypes(store.id)}
                          onAdaptationStorecast={onAdaptationStorecast}
                          onAdaptationGmb={onAdaptationGmb}
                          onAdaptationCustomAttributes={onAdaptationCustomAttributes}
                          onChangeCheckBox={onChangeCheckBox}
                          onResetItem={onResetItem}
                        />
                      );
                    }
                    case 'serviceItems': {
                      const updateStatus = updatedItems.get(diff) as UpdateType | undefined;
                      const categoryAttributeMetadatas = attributeMetadatas[store.location.primaryCategory.categoryId];
                      if (!categoryAttributeMetadatas) {
                        return <React.Fragment key={identifier} />;
                      }
                      // 属性データの行(複数)
                      return (
                        <UpdatesTableServiceItemsRows
                          key={identifier}
                          locationKey={diff.key}
                          isFirstRowInStore={isFirstRowInStore}
                          checked={checked}
                          store={store}
                          updateStatus={updateStatus}
                          diff={diff}
                          gmbLocationUpdate={gmbLocationUpdate}
                          gmbCategoryList={categories}
                          serviceTypes={serviceTypes}
                        />
                      );
                    }
                    default: {
                      const updateStatus = updatedItems.get(diff) as SimpleUpdateType | undefined;
                      // 属性データの以外の場合の行
                      return (
                        <UpdatesTableRow
                          key={identifier}
                          locationKey={diff.key}
                          isFirstRowInStore={isFirstRowInStore}
                          checked={checked}
                          store={store}
                          updateStatus={updateStatus}
                          diff={diff}
                          gmbLocationUpdate={gmbLocationUpdate}
                          gmbCategoryList={categories}
                          onAdaptationStorecast={onAdaptationStorecast}
                          onAdaptationGmb={onAdaptationGmb}
                          onChangeCheckBox={onChangeCheckBox}
                          onResetItem={onResetItem}
                        />
                      );
                    }
                  }
                })}
              </React.Fragment>
            );
          })}
        </Table.Body>
      ) : (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={6}>
              <NoticeContainer>
                <NoticeTitle>Googleビジネスプロフィールとの差分はありません</NoticeTitle>
                <NoticeDescription>
                  Googleビジネスプロフィールとの差分は、毎朝チェックされます。
                  <br />
                  差分が存在する場合、本画面においてSTORECASTとGoogleビジネスプロフィールのどちらの情報を適用するかを選択することができます。
                </NoticeDescription>
              </NoticeContainer>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &&& {
    width: 100%;
    border-collapse: separate;
    border: 1px solid #e3e4e5;
    border: none;

    /* セル別に個別定義&半透明にするとrowSpanの絡みで一部表示がおかしくなる。一元管理でimportant上書き & 不透明化。 */
    th,
    td {
      border: none !important;
      border-bottom: 1px solid #e3e4e5 !important;
      border-right: 1px solid #e3e4e5 !important;
    }

    th {
      border-top: 1px solid #e3e4e5 !important;
      border-bottom: none !important;
    }
  }
`;

const StyledTableHeaderCell = styled(Table.HeaderCell)<{ minwidth?: number }>`
  &&& {
    white-space: nowrap;
    font-weight: normal;
    background: #f9fafb;
    padding: 16px;
    text-align: left;

    ${({ minwidth }) =>
      minwidth &&
      css`
        min-width: ${minwidth}px;
      `}

    position: sticky;
    top: 0;
    /* チェックボックスがヘッダーの上に表示されるのを防ぐ */
    z-index: 2;
    /* ヘッダーとセルの間の線 (border がうまく描画されないため box-shadow を使用) */
    box-shadow: 0 3px 0px #e3e4e5;
  }
`;

const StyledFirstTableHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    position: sticky;
    left: 0;
    z-index: 3;
  }
`;

const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 25px;
`;

const NoticeContainer = styled.div`
  color: ${COLOR.BLACK};
  text-align: center;
  line-height: 1.5;
  margin: 42px;
  opacity: 0.6;
`;
const NoticeTitle = styled.span`
  display: block;
  margin-bottom: 16px;
`;
const NoticeDescription = styled.span`
  font-size: 0.8em;
`;
