import React, { useCallback, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import {
  DataKey,
  MemoIconData,
  getComparisonDataKey as _getComparisonDataKey,
  getTargetDataKey as _getTargetDataKey,
  isComparisonDataKey as _isComparisonDataKey,
  getDateLabelWithWeekOfDay,
  getRateText,
  getValueText,
} from 'helpers/graph';
import { InteractionType, StatsType, gbpPerformanceStatsKeyMapping } from 'models/Domain/GbpPerformance/GbpPerformance';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';

// 非活性のグラフの透明度
const INACTIVE_TRANSPARENCY = 0.8 as const;
// 最大のドット数
const MAX_DOTS = 50 as const;

// グラフに使われるStatsTypeのリスト
const overviewGraphStatsTypes = [
  'businessImpressions',
  'interactions',
  'imageCount',
  'promotionCount',
  'periodReviews',
] satisfies StatsType[];

export type TargetDataKey = (typeof overviewGraphStatsTypes)[number];
export type GraphDataKey = DataKey<TargetDataKey>;

export type OverviewGraphDataItem = Record<'date' | 'comparisonDate', string> &
  Record<GraphDataKey, number | null | undefined>;

// 共通処理に型を指定する
const isComparisonDataKey = _isComparisonDataKey<TargetDataKey>;
const getTargetDataKey = _getTargetDataKey<TargetDataKey>;
const getComparisonDataKey = _getComparisonDataKey<TargetDataKey>;

const isDataKey = (statsType: StatsType): statsType is TargetDataKey => {
  return (overviewGraphStatsTypes as StatsType[]).includes(statsType);
};

const overviewColors = UNIVERSAL_COLORS.toArray();

/**
 * dataKeyから色を取得する
 * @param dataKey
 */
const getStrokeColor = (dataKey: GraphDataKey): string => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'businessImpressions':
      return overviewColors[0];
    case 'interactions':
      return overviewColors[1];
    case 'imageCount':
      return overviewColors[2];
    case 'promotionCount':
      return overviewColors[3];
    case 'periodReviews':
      return overviewColors[4];
    default:
      throw new Error(`"${dataKey}" is unexpected dataKey`);
  }
};

/**
 * dateKeyの日本語表記を取得する
 * @param dataKey
 * @param interactionType
 */
const getDataKeyLabel = (dataKey: GraphDataKey, interactionType: InteractionType) => {
  const isComparison = isComparisonDataKey(dataKey);
  let label: string = gbpPerformanceStatsKeyMapping[getTargetDataKey(dataKey)];
  if (getTargetDataKey(dataKey) == 'interactions') {
    label = `インタラクション${interactionType === 'rate' ? '率' : '数'}`;
  }
  return isComparison ? `${label}（比較）` : label;
};

type OverviewGraphProps = {
  graphData: OverviewGraphDataItem[];
  interactionType: InteractionType;
  showComparisonGraph: boolean;
  height: number;
  displayStats: StatsType[];
  onChangeActiveStats?: (statsType: StatsType) => void;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
  showInactiveLegend: boolean;
  leftGraphSettings?: GraphSettings;
  rightGraphSettings?: GraphSettings;
};

export const OverviewGraph = React.memo<OverviewGraphProps>(
  ({
    graphData: composedGraphData,
    interactionType,
    showComparisonGraph,
    displayStats,
    height,
    memoData,
    onClickMemo,
    onChangeActiveStats,
    showInactiveLegend,
    leftGraphSettings = new GraphSettings(),
    rightGraphSettings = new GraphSettings(),
  }) => {
    // ホバーしたときに強調表示するデータのキー
    const [activeDataKey, setActiveDataKey] = useState<TargetDataKey | null>(null);

    // ドットを表示するのはデータが50個まで
    const showDots = composedGraphData.length <= MAX_DOTS;

    const getYAxisId = useCallback(
      (dataKey: GraphDataKey) => {
        const index = displayStats.indexOf(getTargetDataKey(dataKey));
        return index === 0 ? 'left' : index === 1 ? 'right' : 'hide';
      },
      [displayStats],
    );

    // 比較期間なしの場合に表示するツールチップ
    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <ChartTooltip.Item key={dataKey}>
                  <ChartTooltip.Label color={itemColor}>
                    <ActiveCircle color={getStrokeColor(dataKey)} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>
                    {getTargetDataKey(dataKey) === 'interactions' && interactionType === 'rate'
                      ? getRateText(item.value)
                      : getValueText(item.value)}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [interactionType],
    );

    // 比較期間ありの場合に表示するツールチップ
    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];

        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル

        return (
          <ChartTooltip>
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const targetDataKey = getTargetDataKey(dataKey);
              const isComparison = isComparisonDataKey(dataKey);
              const title = getDataKeyLabel(targetDataKey, interactionType);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const date = item.payload.date;
              const comparisonDate = item.payload.comparisonDate;
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && (
                    <ChartTooltip.Title color={itemColor}>
                      <ActiveCircle color={itemColor} />
                      {title}
                    </ChartTooltip.Title>
                  )}
                  <ChartTooltip.Item>
                    <ChartTooltip.Label color={textColor}>
                      {getDateLabelWithWeekOfDay(isComparison ? comparisonDate : date)}
                    </ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>
                      {getTargetDataKey(dataKey) === 'interactions' && interactionType === 'rate'
                        ? getRateText(item.value)
                        : getValueText(item.value)}
                    </ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [interactionType],
    );

    const handleOnMouseEnter = useCallback(
      (key: TargetDataKey) => () => {
        setActiveDataKey(key);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveDataKey(null);
    }, []);

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    return (
      <ResponsiveContainer height={height}>
        <ComposedChart
          data={composedGraphData}
          margin={{ top: 64, right: 32, left: 32, bottom: 16 }}
          onMouseLeave={() => setActiveDataKey(null)}
        >
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            content={(props: any) => renderLegend({ ...props, showInactiveLegend })}
            onClick={(o: any) => {
              if (!onChangeActiveStats) {
                return;
              }
              // 最大選択数は2なので、2つ選択されている状態で新しい項目をクリックしても、何もしない
              if (displayStats.length >= 2 && !displayStats.includes(getTargetDataKey(o.dataKey))) {
                window.alert('表示できるグラフは2つまでです。他の項目を選択解除してください');
                return;
              }
              onChangeActiveStats(getTargetDataKey(o.dataKey));
            }}
          />
          <XAxis dataKey={'date'} tickMargin={16} interval={'equidistantPreserveStart'} />
          <YAxis
            yAxisId={'left'}
            orientation={'left'}
            label={{
              value: displayStats[0] ? getDataKeyLabel(displayStats[0] as GraphDataKey, interactionType) : '',
              position: 'top',
              offset: 24,
            }}
            hide={displayStats.length < 1}
            tickFormatter={(value) =>
              displayStats[0] === 'interactions' && interactionType === 'rate'
                ? getRateText(value)
                : getValueText(value)
            }
            allowDecimals={displayStats[0] === 'interactions' && interactionType === 'rate'}
            domain={
              displayStats[0] === 'interactions' && interactionType === 'rate'
                ? leftGraphSettings.rateDomain
                : leftGraphSettings.domain
            }
            allowDataOverflow={true}
          />
          <YAxis
            yAxisId={'right'}
            orientation={'right'}
            label={{
              value: displayStats[1] ? getDataKeyLabel(displayStats[1] as GraphDataKey, interactionType) : '',
              position: 'top',
              offset: 24,
            }}
            hide={displayStats.length < 2}
            tickFormatter={(value) =>
              displayStats[1] === 'interactions' && interactionType === 'rate'
                ? getRateText(value)
                : getValueText(value)
            }
            allowDecimals={displayStats[1] === 'interactions' && interactionType === 'rate'}
            domain={
              displayStats[1] === 'interactions' && interactionType === 'rate'
                ? rightGraphSettings.rateDomain
                : rightGraphSettings.domain
            }
            allowDataOverflow={true}
          />
          {/* 非表示用のダミーY軸 */}
          <YAxis yAxisId={'hide'} hide={true} />
          {/* メモアイコン用のダミーY軸 */}
          <YAxis yAxisId={'memo'} hide={true} />
          <CartesianGrid vertical={false} />
          {overviewGraphStatsTypes.map((dataKey) => {
            // グラフに使えるデータであることを確認する
            if (!isDataKey(dataKey)) {
              return null;
            }
            const targetDataKey = getTargetDataKey(dataKey);
            const comparisonDataKey = getComparisonDataKey(dataKey);

            const isActive = activeDataKey == null || activeDataKey === targetDataKey;

            const color = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(targetDataKey));

            // 表示できる項目はdisplayStatsのうち、はじめの2つまで
            const hide = !displayStats.slice(0, 2).includes(targetDataKey);

            return (
              <React.Fragment key={dataKey}>
                <Line
                  dataKey={targetDataKey}
                  stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(targetDataKey))}
                  strokeWidth={2}
                  yAxisId={getYAxisId(targetDataKey)}
                  isAnimationActive={false}
                  name={getDataKeyLabel(targetDataKey, interactionType)}
                  onMouseEnter={handleOnMouseEnter(targetDataKey)}
                  onMouseLeave={handleOnMouseLeave}
                  dot={showDots ? { fill: color, r: 2, clipDot: false } : false}
                  activeDot={{
                    onMouseEnter: handleOnMouseEnter(targetDataKey),
                    onMouseLeave: handleOnMouseLeave,
                    cursor: 'pointer',
                  }}
                  cursor={'pointer'}
                  hide={hide}
                />
                {showComparisonGraph && (
                  <Line
                    dataKey={comparisonDataKey}
                    stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(comparisonDataKey))}
                    strokeWidth={2}
                    strokeDasharray={'5 5'}
                    yAxisId={getYAxisId(comparisonDataKey)}
                    isAnimationActive={false}
                    name={getDataKeyLabel(comparisonDataKey, interactionType)}
                    onMouseEnter={handleOnMouseEnter(targetDataKey)}
                    onMouseLeave={handleOnMouseLeave}
                    dot={showDots ? { fill: color, r: 2, clipDot: false } : false}
                    activeDot={{
                      onMouseEnter: handleOnMouseEnter(targetDataKey),
                      onMouseLeave: handleOnMouseLeave,
                      cursor: 'pointer',
                    }}
                    cursor={'pointer'}
                    hide={hide}
                  />
                )}
              </React.Fragment>
            );
          })}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                yAxisId={'memo'}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}

          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={showComparisonGraph ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
);
