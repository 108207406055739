import { toast } from 'react-semantic-toasts';
import { put, takeLatest } from 'redux-saga/effects';

import { StoreConnectYahooPlaceApi, StoreDisconnectYahooPlaceApi, StoreImportYahooPlaceApi } from 'ApiClient/StoreApi';
import { YahooPlaceAccountApi, YahooPlaceApi } from 'ApiClient/YahooPlaceApi';
import { AppActions } from 'modules/app/actions';
import { StoreActions } from 'modules/store/actions';
import { StoreDetailActions } from 'modules/storeDetail/actions';
import { JSObject } from 'types/Common';

import { YahooPlaceActions } from './actions';

export default function* saga() {
  yield takeLatest(YahooPlaceActions.getAccounts, getYahooPlaceAccounts);
  yield takeLatest(YahooPlaceActions.registerYahooPlaceAccount, registerYahooPlaceAccount);
  yield takeLatest(YahooPlaceActions.deleteAccount, deleteYahooPlaceAccount);
  yield takeLatest(YahooPlaceActions.getPlaces, getPlaces);
  yield takeLatest(YahooPlaceActions.connect, connect);
  yield takeLatest(YahooPlaceActions.disconnect, disconnect);
  yield takeLatest(YahooPlaceActions.import, importYahoo);
}

function* getYahooPlaceAccounts() {
  /*
   * YahooPlaceAccountApi.get()でYahooPlaceのアカウント情報を取得
   */
  yield put(AppActions.setLoading(true));
  const response: YieldReturn<typeof YahooPlaceAccountApi.get> = yield YahooPlaceAccountApi.get();

  if (response.isSuccess) {
    yield put(YahooPlaceActions.setAccounts(response.data));
  } else {
    toast({
      type: 'error',
      title: '取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* getPlaces() {
  /*
   * YahooPlaceAPI.get()でYahooPlaceのアカウント情報を取得
   */
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof YahooPlaceApi.get> = yield YahooPlaceApi.get();
  if (response.isSuccess) {
    yield put(YahooPlaceActions.setPlaces(response.data));
  } else {
    toast({
      type: 'error',
      title: 'Yahoo!プレイス情報の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* registerYahooPlaceAccount(action: ReturnType<typeof YahooPlaceActions.registerYahooPlaceAccount>) {
  /*
   * YahooPlaceAccountApi.post()でYahooPlaceのアカウント情報を登録
   */
  yield put(AppActions.setLoading(true));
  const { account_id, name, redirect_uri, state, code } = action.payload;

  const registerYahooPlaceAccountPayload = {
    account_id: account_id,
    account_name: name,
    redirect_uri: redirect_uri,
    state: state,
    code: code,
  };
  // nonceとstateの値を取得
  const response: JSObject = yield YahooPlaceAccountApi.post(registerYahooPlaceAccountPayload);

  if (response.isSuccess) {
    yield put(YahooPlaceActions.clearYahooPlaceAuth());
    toast({
      type: 'success',
      title: 'Yahoo! プレイス アカウントを登録しました',
    });
  } else {
    toast({
      type: 'error',
      title: '登録に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(YahooPlaceActions.getAccounts());
  yield put(AppActions.setLoading(false));
}

function* deleteYahooPlaceAccount(action: ReturnType<typeof YahooPlaceActions.deleteAccount>) {
  /*
   * YahooPlaceAccountApi.delete()でYahooPlaceのアカウント情報を削除
   */
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof YahooPlaceAccountApi.delete> = yield YahooPlaceAccountApi.delete(action.payload);

  if (response.isSuccess) {
    yield put(YahooPlaceActions.getAccounts());
    toast({
      type: 'success',
      title: 'Yahoo! プレイス アカウントを削除しました',
    });
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

/** 店舗をYahoo!プレイスと連携 */
function* connect(action: ReturnType<typeof YahooPlaceActions.connect>) {
  yield put(AppActions.setLoading(true));

  const { storeId, accountId, placeSeq } = action.payload;
  const params = { yahoo_account_id: accountId, place_seq: placeSeq };

  const response: YieldReturn<typeof StoreConnectYahooPlaceApi.post> = yield StoreConnectYahooPlaceApi.post(
    storeId,
    params,
  );

  if (response.isSuccess) {
    yield put(StoreActions.getStores());
    toast({
      type: 'success',
      title: '店舗のYahoo! プレイス連携が完了しました',
    });
    // FIXME 店舗個別ページ向けのデータ取得がここで呼ばれているのはおかしい
    yield put(StoreDetailActions.getStore(storeId));
  } else {
    toast({
      type: 'error',
      title: '店舗のYahoo! プレイス連携が失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/** Yahoo!プレイスと連携している店舗の連携を解除 */
function* disconnect(action: ReturnType<typeof YahooPlaceActions.disconnect>) {
  yield put(AppActions.setLoading(true));
  const storeId = action.payload;

  const response: YieldReturn<typeof StoreDisconnectYahooPlaceApi.post> =
    yield StoreDisconnectYahooPlaceApi.post(storeId);

  if (response.isSuccess) {
    // 動的に連携ステータスを更新するため、店舗情報を再取得
    yield put(StoreDetailActions.getStore(storeId));
    yield put(StoreActions.getStores());
    toast({
      type: 'success',
      title: '店舗のYahoo! プレイス連携を解除しました',
    });
  } else {
    toast({
      type: 'error',
      title: '店舗のYahoo! プレイス連携解除が失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/** 店舗にYahoo!プレイスの情報を取り込む */
function* importYahoo(action: ReturnType<typeof YahooPlaceActions.import>) {
  yield put(AppActions.setLoading(true));

  const { storeId, accountId, placeSeq } = action.payload;
  const params = { account_id: accountId, place_seq: placeSeq, store_id: storeId };

  const response: YieldReturn<typeof StoreImportYahooPlaceApi.post> = yield StoreImportYahooPlaceApi.post(
    storeId,
    params,
  );

  if (response.isSuccess) {
    // 後続の連携処理が完了した後に店舗データの再取得とトーストの表示をするのでここは何もしない
  } else {
    toast({
      type: 'error',
      title: '店舗のYahoo! プレイス情報の取り込みに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}
