import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { Icon } from 'components/atoms/Icon';
import { Redirect } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { SegmentUpload } from 'components/molecules/FileUploader';
import {
  StepManual,
  StepManualGuideLink,
  StepManualHeader,
  StepManualMain,
  StepManualTitle,
} from 'components/organisms/StepManual';
import { InventoryCsvDownloadModal } from 'components/pageComponents/InventoryImport/InventoryCsvDownloadModal';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { AppActions } from 'modules/app/actions';
import { InventoriesImportActions } from 'modules/inventoriesImport/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

// 在庫情報をまとめて更新のヘルプURL
const GUIDE_URL = 'https://pathee.notion.site/07abaff59ae44292a4c24767a478636c';

type Props = RouteComponentProps<{ storeId: string }>;

/**
 * 在庫情報をCSVでまとめて更新
 */
export const InventoryImport: React.FC<Props> = ({
  match: {
    params: { storeId },
  },
}) => {
  const dispatch = useDispatch();

  const {
    checkInventoryCsvDownloadStatus,
    checkCsvImportStatus,
    clearCsvDownload,
    clearStatus,
    downloadInventoriesCsv,
    importInventories,
    initializeInventoriesImportPage,
    setInventoryCsvDownloadFilter,
  } = useMemo(() => bindActionCreators(InventoriesImportActions, dispatch), [dispatch]);
  const { setLoading } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);

  const {
    downloadExecutionArn,
    inventoryCsvDownloadFilter,
    resultMessage,
    isValidateError,
    validateError,
    isImportError,
    isProcessing,
    isAcceptedImport,
    totalSucceed,
    totalFailed,
  } = useSelector((state: State) => state.inventoriesImport, shallowEqual);
  const { stores } = useSelector((state: State) => state.store, shallowEqual);
  const { storeLists } = useSelector((state: State) => state.storeList, shallowEqual);
  const { currentUser } = useSelector((state: State) => state.app, shallowEqual);

  // CSVダウンロードの条件を設定するモーダルの開閉状態
  const [isOpenCsvDownloadModal, setIsOpenCsvDownloadModal] = useState<boolean>(false);

  // CSVをダウンロードするボタンが押された
  const onClickCsvDownload = useCallback(() => {
    setIsOpenCsvDownloadModal(true);
  }, [setIsOpenCsvDownloadModal]);

  // CSVダウンロードの条件を設定するモーダルでダウンロードボタンが押された
  const onStartCsvDownload = useCallback(() => {
    downloadInventoriesCsv();
    setIsOpenCsvDownloadModal(false);
  }, [downloadInventoriesCsv, setIsOpenCsvDownloadModal]);

  // ユーザがアップロードする在庫情報のファイル
  const [file, setFile] = useState<File>();

  // CSVファイルがバリデーション済みかつバリデーションOKかどうか
  const hasVerifiedCsv = useMemo(() => isValidateError === false, [isValidateError]);

  // ファイルの選択が解除された
  const onDelete = useCallback(() => {
    setFile(undefined);
    clearStatus();
  }, [clearStatus]);

  // バリデーションを実行（アップロード対象のファイルが選択されたとき）
  const onValidate = useCallback(
    (targetFile: File) => {
      setFile(targetFile);
      importInventories({ file: targetFile, validateOnly: true });
    },
    [importInventories],
  );

  // 「在庫情報を更新」ボタンが押された
  const onImportInventories = useCallback(() => {
    if (!file) {
      return;
    }
    importInventories({ file, validateOnly: false });
  }, [file, importInventories]);

  // ページ表示時、条件が揃ったらCSVダウンロードフィルターを初期化する
  useEffect(() => {
    // FIXME 他に良い判断方法がないか
    if (currentUser.id !== 0 && stores.initialized && storeLists.initialized) {
      initializeInventoriesImportPage(storeId);
    }
  }, [currentUser, initializeInventoriesImportPage, storeId, stores, storeLists]);

  // CSVダウンロード中の進捗取得処理
  useEffect(() => {
    // 3秒おきに進捗を取得する
    if (downloadExecutionArn) {
      setLoading(true);
      const intervalId = window.setInterval(() => checkInventoryCsvDownloadStatus(), 3000);

      // 戻るボタンなどで遷移した場合にタイマーを停止
      return () => {
        window.clearInterval(intervalId);
        // 再度ダウンロードした場合に以前のExecutionArnが参照されないように削除
        clearCsvDownload();
        setLoading(false);
      };
    }
  }, [downloadExecutionArn, checkInventoryCsvDownloadStatus, clearCsvDownload, setLoading]);

  // CSVインポート中の進捗取得処理
  useEffect(() => {
    if (isAcceptedImport) {
      const intervalId = setInterval(() => {
        checkCsvImportStatus({ validateOnly: !hasVerifiedCsv });
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [checkCsvImportStatus, isAcceptedImport, hasVerifiedCsv]);

  // URLパスで店舗IDが指定された場合、店舗の操作権限がない場合は店頭在庫店舗一覧へリダイレクト
  if (storeId && !stores.filterByUserRole(currentUser).findStore(storeId)) {
    return <Redirect to={Path.omo.inventoryStoreList} />;
  }

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('店頭在庫(CSVでまとめて更新)')} />
      <StickyHeader>
        <Title>CSVでまとめて更新</Title>
      </StickyHeader>
      <WideBody>
        {/* ステップマニュアル */}
        <StyledStepManual>
          <StepManualTitle>在庫情報をまとめて更新するには</StepManualTitle>
          <StepManualHeader step={1} title='テンプレートのダウンロード' />
          <StepManualMain>
            <DownloadLink onClick={onClickCsvDownload}>こちら</DownloadLink>
            からCSVをダウンロード
          </StepManualMain>
          <StepManualHeader step={2} title='CSVを編集' />
          <StepManualMain>CSVに記載されている対象の在庫情報を編集</StepManualMain>
          <StepManualHeader step={3} title='在庫情報を更新' />
          <StepManualMain>
            編集したCSVをアップロード。エラー情報が表示された場合、CSVを再度編集してアップロード
          </StepManualMain>
          <StepManualGuideLink>
            詳しくは <ExternalLink href={GUIDE_URL}>STORECAST利用ガイド</ExternalLink> をご覧ください
          </StepManualGuideLink>
        </StyledStepManual>

        {/* ファイルアップロード部分 */}
        {file ? (
          <>
            <MessageTitle>対象ファイル</MessageTitle>
            <FileText>
              <FileName>{file.name}</FileName>
              {!isProcessing && <CancelIcon onClick={onDelete} />}
            </FileText>
          </>
        ) : (
          <StyledSegmentUpload
            multiple={false}
            uploadType={'file'}
            accept={['text/csv', 'application/octet-stream', 'application/vnd.ms-excel']}
            iconName='paperclip'
            buttonText='CSVを追加'
            onDropAccepted={(files) => onValidate(files[0])}
            onDropRejected={(files) => {
              console.error(files);
            }}
          />
        )}

        {/* CSVファイルの在庫情報を反映中 */}
        {isProcessing && hasVerifiedCsv && (
          <MessageWrapper>
            <ProcessingTitle>
              <Loader active={true} size='small' inline={true} />
              <ProcessingTitleLabel>在庫情報の更新を実行中...</ProcessingTitleLabel>
            </ProcessingTitle>

            {isAcceptedImport && (
              <Message>
                CSVファイルでの在庫情報更新は正常に受け付けられました。
                <br />
                対象件数が多い場合、処理完了まで時間がかかる場合があります。
              </Message>
            )}
          </MessageWrapper>
        )}

        {/* CSVファイルのバリデーション中 */}
        {isProcessing && !hasVerifiedCsv && (
          <MessageWrapper>
            <ProcessingTitle>
              <Loader active={true} size='small' inline={true} />
              <ProcessingTitleLabel>CSVファイルを検査中...</ProcessingTitleLabel>
            </ProcessingTitle>
          </MessageWrapper>
        )}

        {/* CSVファイルのバリデーション結果 */}
        {(isValidateError || isValidateError === false) && (
          <ValidationCountWrapper>
            <ValidationCountTitle>検証結果</ValidationCountTitle>
            <ValidationCount>成功した件数：{totalSucceed}</ValidationCount>
            <ValidationCount>失敗した件数：{totalFailed}</ValidationCount>
          </ValidationCountWrapper>
        )}

        {/* CSVファイルのバリデーションエラー */}
        {isValidateError && validateError && (
          <MessageWrapper>
            <ErrorTitle>{resultMessage}</ErrorTitle>
            <Message>
              CSVファイルの検査の結果、内容の不備が
              {validateError.errors.length > 0 && `${validateError.errors.length}件`}
              見つかりました。
              <br />
              CSVファイルを修正後、再度アップロードしてください。
              <br />
              詳しくは <ExternalLink href={GUIDE_URL}>こちら</ExternalLink> の利用ガイドをご覧ください。
            </Message>
            {validateError.errors.length > 0 ? (
              // errorsが空でない場合は、行単位でエラーがある
              <>
                <MessageTitle>修正が必要な項目</MessageTitle>
                {validateError.errors.map((ve, idx) =>
                  ve.messages.map((message, idx2) => (
                    <Error key={`${idx}_${idx2}`}>・{`行 ${ve.row}: ${message}`}</Error>
                  )),
                )}
              </>
            ) : (
              // errorsが空の場合は、CSV形式、ヘッダーカラムなどにエラーがある
              <Error>・{validateError.message}</Error>
            )}
          </MessageWrapper>
        )}

        {/* CSVファイルのバリデーションに成功した */}
        {isValidateError === false && (
          <MessageWrapper>
            <MessageTitle>CSVファイルを使って在庫情報を更新できます</MessageTitle>
            <Message>
              CSVファイルの検査の結果、特に問題は見つかりませんでした。
              <br />
              「在庫情報を更新」ボタンをクリックして、在庫情報の更新を開始してください。
            </Message>
          </MessageWrapper>
        )}

        {/* CSVファイルの反映に失敗した */}
        {isImportError && (
          <MessageWrapper>
            <ErrorTitle>在庫情報の更新に失敗しました</ErrorTitle>
            <Message>繰り返し試しても反映されない場合、画面左下の「サポート」からお問い合わせください。</Message>
          </MessageWrapper>
        )}

        {/* 在庫情報を更新 */}
        <ButtonsWrapper>
          <StyledButton onClick={onImportInventories} priority='high' disabled={!file || isValidateError !== false}>
            在庫情報を更新
          </StyledButton>
        </ButtonsWrapper>
      </WideBody>

      {/* CSVダウンロードモーダル */}
      <InventoryCsvDownloadModal
        open={isOpenCsvDownloadModal}
        filter={inventoryCsvDownloadFilter}
        setFilter={setInventoryCsvDownloadFilter}
        onClose={() => setIsOpenCsvDownloadModal(false)}
        onStartCsvDownload={onStartCsvDownload}
      />
    </MainWrapper>
  );
};

/**
 * ステップマニュアル
 */
const StyledStepManual = styled(StepManual)`
  margin-bottom: 16px;
`;

const DownloadLink = styled.span`
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

/**
 * ファイルアップロード部分
 */
const MessageTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const FileText = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  border: 2px solid ${COLOR.GRAY};
`;

const FileName = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CancelIcon = styled(Icon).attrs({ type: 'cancel' })`
  margin-left: auto;
  cursor: pointer;
`;

const StyledSegmentUpload = styled(SegmentUpload)`
  width: 100%;
`;

/**
 * ファイル選択後のメッセージ部分
 */
const MessageWrapper = styled.div`
  margin-top: 16px;
`;

const ProcessingTitle = styled(MessageTitle)`
  display: flex;
  align-items: center;
`;

const ProcessingTitleLabel = styled.div`
  margin-left: 8px;
  font-weight: bold;
`;

const Message = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
`;

/**
 * バリデーション結果部分
 */
const ValidationCountWrapper = styled.div``;

const ValidationCount = styled.div``;

const ValidationCountTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ErrorTitle = styled(MessageTitle)`
  color: ${COLOR.ERROR};
`;

const Error = styled.div`
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 22px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  height: 54px;
  &&& {
    width: 100%;
  }
`;
