import React from 'react';

import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { Icon } from 'components/atoms/Icon';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { COLOR } from 'style/color';

type Props = {
  labelName?: string;
  showGoogleIcon?: boolean;
  showYahooIcon?: boolean;
  showAppleIcon?: boolean;
  showEditButton?: boolean;
  onClick?: () => void;
  isRecommend?: boolean;
  children: React.ReactNode;
};

export const StoreItemCard: React.FC<Props> = ({
  labelName,
  showGoogleIcon = false,
  showYahooIcon = false,
  showAppleIcon = false,
  showEditButton = false,
  onClick,
  isRecommend = false,
  children,
}) => {
  return (
    <CustomCard
      onClick={() => {
        if (showEditButton && onClick) {
          onClick();
        }
      }}
    >
      <HeaderContent isActive={showEditButton}>
        {!!labelName && (
          <ItemLabel>
            {labelName} {isRecommend && <Recommend>【推奨】</Recommend>}
          </ItemLabel>
        )}
        <AvailableServicesWrapper>
          {showGoogleIcon && (
            <AvailableServiceIconWrapper>
              <ServiceIcon.Google />
            </AvailableServiceIconWrapper>
          )}
          {showYahooIcon && (
            <AvailableServiceIconWrapper>
              <ServiceIcon.Yahoo />
            </AvailableServiceIconWrapper>
          )}
          {showAppleIcon && (
            <AvailableServiceIconWrapper>
              <ServiceIcon.Apple />
            </AvailableServiceIconWrapper>
          )}
        </AvailableServicesWrapper>
      </HeaderContent>
      <MainContent isActive={showEditButton}>
        <ItemContent>{children}</ItemContent>
        {showEditButton && <EditButton type='pencil' />}
      </MainContent>
    </CustomCard>
  );
};

const CustomCard = styled(Card)`
  margin-top: 2px;
`;

const HeaderContent = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'auto')};
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-bottom: 12px;
`;

const MainContent = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'auto')};
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const ItemLabel = styled.div`
  color: ${COLOR.BLACK};
  font-size: 16px;
  font-weight: bold;
`;

const ItemContent = styled.div`
  font-size: 14px;
  width: calc(100% - 24px);
`;

const AvailableServicesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const AvailableServiceIconWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

const EditButton = styled(Icon)`
  &&& {
    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: 2px;
  }
`;

const Recommend = styled.span`
  color: orange;
  font-size: 12px;
`;
