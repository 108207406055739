import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { SelectOption } from 'types/Common';

import { YahooPlaceCategoriesAPIResponse, YahooPlaceCategoriesApiResponseItem } from '../../../ApiClient/YahooPlaceApi';

export class YahooPlaceCategory extends ImmutableRecord<{
  categoryId: string;
  displayName: string;
}>({
  categoryId: '',
  displayName: '',
}) {
  static fromJSON(data: YahooPlaceCategoriesApiResponseItem) {
    return new YahooPlaceCategory({
      categoryId: data.category_id,
      displayName: data.display_name,
    });
  }
}

export class YahooPlaceCategories extends ImmutableRecord<{
  list: ImmutableList<YahooPlaceCategory>;
}>({
  list: ImmutableList<YahooPlaceCategory>(),
}) {
  static fromJSON(data: YahooPlaceCategoriesAPIResponse) {
    return new YahooPlaceCategories({
      list: ImmutableList(data.items.map(YahooPlaceCategory.fromJSON)),
    });
  }

  /**
   * カテゴリIDからカテゴリを取得する
   * @param categoryId
   */
  findByCategoryId(categoryId: string) {
    return this.list.find((category) => category.categoryId === categoryId);
  }

  /**
   * カテゴリIDから表示名を取得する
   * @param categoryId
   */
  getDisplayNameByCategoryId(categoryId: string) {
    return this.findByCategoryId(categoryId)?.displayName || '';
  }

  generateCategoryOption(): SelectOption<string>[] {
    return this.list
      .map((target) => ({
        text: target.displayName,
        value: target.categoryId,
      }))
      .toArray();
  }
}
