import { Record } from 'immutable';

import { JSObject } from 'types/Common';

interface LocationStateRecord {
  canUseLocalPost: boolean;
  canHaveFoodMenus: boolean;
  gbpLocationIsSuspended: boolean;
  gbpLocationIsDuplicate: boolean;
  gbpLocationIsVerified: boolean;
  gbpLocationIsExists: boolean;
  gbpAddressPatchFailed: boolean;
  canModifyUrlAppointment: boolean;
  canModifyUrlReservations: boolean;
}

/**
 * Googleビジネスプロフィールのロケーション情報の状態を保持
 * フロントから値を変更することはない
 */
export class LocationState extends Record<LocationStateRecord>({
  canUseLocalPost: true,
  canHaveFoodMenus: false,
  gbpLocationIsSuspended: false,
  gbpLocationIsDuplicate: false,
  gbpLocationIsVerified: true,
  gbpLocationIsExists: true,
  gbpAddressPatchFailed: false,
  canModifyUrlAppointment: true,
  canModifyUrlReservations: true,
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    params.canUseLocalPost = params.can_use_local_post ?? true;
    params.canHaveFoodMenus = params.can_have_food_menus ?? false;
    params.gbpLocationIsSuspended = params.gmb_location_is_suspended ?? false;
    params.gbpLocationIsDuplicate = params.gmb_location_is_duplicate ?? false;
    params.gbpLocationIsVerified = params.gmb_location_is_verified ?? true;
    params.gbpLocationIsExists = params.gmb_location_is_exists ?? true;
    params.canModifyUrlAppointment = params.can_modify_url_appointment ?? true;
    params.canModifyUrlReservations = params.can_modify_url_reservations ?? true;
    return new LocationState(params);
  }

  get isGmbError() {
    return (
      !this.canUseLocalPost ||
      this.gbpLocationIsSuspended ||
      this.gbpLocationIsDuplicate ||
      !this.gbpLocationIsVerified ||
      !this.gbpLocationIsExists
    );
  }

  get canApplyAddressToGmb() {
    return !this.gbpAddressPatchFailed;
  }

  get errorLabels() {
    const result = [];
    if (this.gbpLocationIsSuspended) {
      result.push('停止中である');
    }
    if (this.gbpLocationIsDuplicate) {
      result.push('重複している');
    }
    if (!this.gbpLocationIsVerified) {
      result.push('オーナー確認が未完了である');
    }
    if (!this.gbpLocationIsExists) {
      result.push('見つからない');
    }
    return result;
  }
}
